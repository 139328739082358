<template>
  <div class="msg-wrap">
    <div class="item">
      <img style="width:19px" src="../../assets/img/6.png" alt="">
      <span style="marginLeft:6px">彭家坞村</span>
    </div>
    <div>{{ currentTime }}</div>
    <div>
      <span style="marginRight:6px">{{ weather ? weather.weather : '' }}</span>
      <span>{{ weather ? weather.temperature+'℃' : '' }}</span></div>
  </div>
</template>

<script>
import {getWeather} from '@/request/api'
export default {
	data(){
		return {
			currentTime:null,
			weather:null
		}
	},
	created(){
		getWeather().then(res =>{
			this.weather = res.lives[0] ? res.lives[0] : null
		})
		this.timerSetInterval()
	},
	methods:{
		getTime(){
			this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm')
		},
		timerSetInterval(){
			this.getTime()
			setInterval(() => {
				this.getTime()
			}, 30000)
		}
	}
}
</script>

<style lang="less" scoped>
.msg-wrap{
  position: absolute;
  width: calc((100vw - @titleTextWidth)/2);
  right: 16px;
  top: 12px;
  font-size: 16px;
  text-shadow: none;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: flex-end;
	div{
		margin-right: 16px;
	}
}
.item{
  display: flex;
  align-items: center;
}
</style>>