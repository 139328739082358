<template>
  <div class="j-title">
    <div>
      <span class="title-bar" :style="{borderColor:ops.color1 || color1,boxShadow: `0 0 2px 3px ${ops.color2 || color2}`}"></span>
      {{ ops.title }} 
      <span :style="{color:ops.color1 || color1,fontSize:'30px',paddingLeft:'4px'}" v-if="ops.num">{{ ops.num }}</span>
    </div>
    <span v-if="ops.unit" :style="{color:ops.unitColor,fontSize:'16px',paddingRight:'20px',fontWeight:'normal'}">{{ ops.unit }}</span>
  </div>
</template>

<script>
export default {
	props:{
		ops:{
			type:Object,
			default:()=>{
			}
		}
	},
	data(){
		return{
			color1:'rgba(5, 255, 242, 1)',
			color2:'rgba(5, 255, 242, 0.3)'
		}
	}
}
</script>

<style lang="less" scoped>
.j-title{
  height: 48px;
  line-height: 48px;
  padding-left: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title-bar{
    width: 8px;
    height: 8px;
    display: inline-block;
    border: 2px solid;
    border-radius: 50%;
    margin-right: 8px;
  }
}
</style>