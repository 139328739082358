<template>
  <div class="loading">
    <div class="loading-content">
      <div class="desc">{{text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      text: "加载中...",
    };
  },
  methods: {
    setTitle(text) {
      this.text = text;
    },
  },
};
</script>
<style lang='less' scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  .loading-content {
    text-align: center;
    color: #fff;
    .desc {
      line-height: 20px;
    }
  }
}
.loading-relative {
  position: relative;
}
</style>