<template>
  <div>
    <div :class="'page-bg page-bg-'+activeTab">
      <template v-if="activeTab === 1">
        <bg1/>
      </template>
    </div>
  </div>
</template>

<script>
import bg1 from './components/bg1.vue'
export default {
	components:{
		bg1
	},
	computed:{
		activeTab(){
			return this.$store.state.pageNum
		},
	},
	watch:{
	
	},
	data(){
		return {
		
		}
	},
	methods:{
	}
}
</script>

<style lang="less" scoped>

.posBlock{
	position: fixed;
	z-index: 12;
	transition: all .5s ease-in-out ;
}
.page-bg{
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.page-bg-1{
  background-color: #031b22;
  background-image: url('../../assets/img/bg7.png');
}
.page-bg-2{
  background-image: url('../../assets/img/bg2.png');
}
.page-bg-3{
  background-image: url('../../assets/img/bg3.png');
}
.page-bg-4{
  background-color: #031b22;
  background-image: url('../../assets/img/bg7.png');
}
.page-bg-5{
  background-color: #031b22;
  background-image: url('../../assets/img/bg5.png');
}
.page-bg-1{
	img{
		width: 100px;
		position: fixed;
		// z-index: 10;
		cursor: pointer;
		animation: ease-in breathe 2000ms infinite alternate;
		// box-shadow:0 0px 10px rgba(255,255,255,1);
	}
	@keyframes breathe {
		0%{
			opacity: .3;
			// box-shadow:0 0px 2px rgba(255,255,255,0.1);
		}
		100%{
			opacity: 1;
			// box-shadow:0 0px 10px rgba(255,255,255,1);
		}
	}
}
</style>