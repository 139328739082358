<template>
  <div class="page-wrap">
    <pageBg />
    <div :class="[{ 'active animate__fadeInRight' : activeTab === 1,'animate__fadeOutLeft': activeTab !== 1},'page page-1 animate__animated']">
      <div class="title">华坛山镇彭家坞村数字乡村
        <msg />
      </div>
      <page1 v-if="activeTab === 1" />
    </div>
    <div :class="[{ 'active animate__fadeInRight' : activeTab === 2,'animate__fadeOutLeft': activeTab !== 2},'page page-2 animate__animated']">
      <div class="title">华坛山镇彭家坞村数字乡村
        <msg />
      </div>
      <page2 v-if="activeTab === 2" />
    </div>
    <div :class="[{ 'active animate__fadeInRight' : activeTab === 3,'animate__fadeOutLeft': activeTab !== 3},'page page-3 animate__animated']">
      <div class="title">华坛山镇彭家坞村数字乡村
        <msg />
      </div>
      <page3 v-if="activeTab === 3" />
    </div>
    <div :class="[{ 'active animate__fadeInRight' : activeTab === 4,'animate__fadeOutLeft': activeTab !== 4},'page page-4 animate__animated']">
      <div class="title">华坛山镇彭家坞村数字乡村
        <msg />
      </div>
      <page4 v-if="activeTab === 4" />
    </div>
    <div :class="[{ 'active animate__fadeInRight' : activeTab === 5,'animate__fadeOutLeft': activeTab !== 5},'page page-5 animate__animated']">
      <div class="title">华坛山镇彭家坞村数字乡村
        <msg />
      </div>
      <page5 v-if="activeTab === 5" />
    </div>
    <div class="tab">
      <div :class="[{ 'active' : activeTab === item.id},'item']"
           @click="onChange(item)"
           v-for="item in tabList"
           :key="item.name">
        <div class="item-inner">
          <img v-if="activeTab !== item.id"
               :src="require(`../../assets/img/tab${item.id}.png`)"
               alt="">
          <img v-else
               :src="require(`../../assets/img/tab${item.id}2.png`)"
               alt="">
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import page1 from "./components/page1";
import page2 from "./components/page2/page2.vue";
import page3 from "./components/page3/page3.vue";
import page4 from "./components/page4/page4.vue";
import page5 from "./components/page5/page5.vue";
import msg from "@/components/msg";
import pageBg from "@/components/bg";
export default {
  name: "",
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    msg,
    pageBg,
  },
  data() {
    return {
      tabList: [
        { name: "乡村资源要素", id: 1, tab1: "../../assets/img/tab1.png" },
        { name: "乡村治理", id: 2 },
        { name: "乡村经营", id: 3 },
        { name: "乡村服务", id: 4 },
        { name: "数字孪生乡村", id: 5 },
      ],
      // activeTab:Number(this.$route.params.id)
    };
  },
  created() {
    this.$store.dispatch("getJsonData");
    this.$store.commit("setPageNum", Number(this.$route.params.id));
  },
  computed: {
    activeTab() {
      return this.$store.state.pageNum;
    },
  },
  methods: {
    onChange(item) {
      this.$store.commit("setPageNum", item.id);
      // this.activeTab = item.id
    },
    // setHtmlFontSize () {
    // 	// 1366是设计稿的宽度，当大于1366时采用1366宽度，比例也是除以13.66
    // 	this.deviceWidth = document.documentElement.clientWidth > 1920 ? 1920 : document.documentElement.clientWidth
    // 	document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + this.deviceWidth / 19.20 + 'px !important'
    // }
  },
};
</script>
<style lang='less' scoped>
@import "./index.less";
</style>