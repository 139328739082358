<template>
  <div>
    <template v-if="activeTab === 1">
      <img v-for="(img,index) in imgList"
           :style="{top:img.top,left:img.left,zIndex:zIndex}"
           :key="index"
           @click="img.onClick(img,index)"
           :src="require('../../../assets/img/icon/'+img.url)"
           alt="">
    </template>
    <transition name="plus-icon">
      <gb v-if="posType === 1"
          ref="gb"
          :ops="ops" />
    </transition>
    <transition name="plus-icon">
      <div class="posBlock"
           v-if="posType === 3"
           :style="{width:ops.width+'px',height:'auto',top:ops.top,left:ops.left}">
        <shuizhi ref="shuizhi" />
      </div>
    </transition>
  </div>
</template>

<script>
import shuizhi from "@/components/shuizhi";
import gb from "@/components/gb";
// import AMap from 'AMap'
// import {startRealPlay,stopPlay} from '@/utils/jiankong.js'
export default {
  components: {
    shuizhi,
    gb,
  },
  computed: {
    activeTab() {
      return this.$store.state.pageNum;
    },
    centerShow() {
      return this.$store.state.centerShow;
    },
    oSel() {
      return JSON.parse(JSON.stringify(this.$store.state.jiankong));
    },
  },
  watch: {
    centerShow: {
      handler(val) {
        if (val) {
          this.zIndex = -1;
          this.hideBlock();
        } else {
          this.zIndex = 10;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      zIndex: 10,
      posType: 0,
      ops: {
        width: 0,
        height: 0,
      },
      imgList: [
        {
          left: "30%",
          top: "55%",
          url: "1.png",
          onClick: this.openjiankong,
          type: 2,
          id: 46,
        },
        {
          left: "40%",
          top: "55%",
          url: "1.png",
          onClick: this.openjiankong,
          type: 2,
          id: 5,
        },
        {
          left: "60%",
          top: "54%",
          url: "1.png",
          onClick: this.openjiankong,
          type: 2,
          id: 33,
        },
        {
          left: "69%",
          top: "47%",
          url: "1.png",
          onClick: this.openjiankong,
          type: 2,
          id: 34,
        },
        {
          left: "64%",
          top: "58%",
          url: "2.png",
          onClick: this.showShuizhi,
          type: 3,
          bg: "rgba(0, 255, 204, .5)",
          id: 3,
        },
        {
          left: "38%",
          top: "28%",
          url: "3.png",
          onClick: this.showGb,
          type: 1, //广播
          bg: "rgba(0, 255, 204, .5)",
          id: 1,
        },
      ],
    };
  },
  beforeDestroy() {
    this.hideBlock();
  },
  methods: {
    showGb(item) {
      //广播
      this.closeJk();
      this.posType = this.posType === item.type ? 0 : item.type;
      this.ops = {
        left: `calc(${item.left} + 50px)`,
        top: `calc(${item.top} - 100px)`,
        width: this.posType === 1 ? 500 : 0,
        bg: item.bg,
        id: item.id,
      };
    },
    hideBlock() {
      this.posType = 0;
      this.closeJk();
    },
    closeJk() {
      this.oSel.forEach((item) => {
        item.onPlay = false;
      });
      this.$jkDplayer.onPause();
    },
    openjiankong(item) {
      this.posType = item.type;
      let choosIndex = this.oSel.findIndex((val) => val.id === item.id);
      if (!this.oSel[choosIndex].onPlay) {
        const ops = {
          left: `calc(${item.left} - 150px)`,
          top: `calc(${item.top} - 200px)`,
          width: 380,
          height: 190,
          bg: item.bg,
          id: item.id,
        };
        this.oSel.forEach((val) => {
          if (val.id === item.id) {
            item = Object.assign({}, val, item);
            val.onPlay = true;
          } else {
            val.onPlay = false;
          }
        });
        this.$store.state.jkOps = ops;
        this.$jkDplayer.onPlay(item.vurl);
      } else {
        this.oSel[choosIndex].onPlay = false;
        this.$jkDplayer.onPause();
      }
    },
    showShuizhi(item) {
      this.closeJk();
      this.posType = this.posType === item.type ? 0 : item.type;
      this.ops = {
        left: `calc(${item.left} - 500px)`,
        top: `calc(${item.top} - 250px)`,
        width: this.posType === 3 ? 500 : 0,
        bg: item.bg,
        id: item.id,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.posBlock {
  position: fixed;
  z-index: 12;
  transition: all 0.5s ease-in-out;
}
.page-bg {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.page-bg-1 {
  img {
    width: 100px;
    position: fixed;
    // z-index: 10;
    cursor: pointer;
    animation: ease-in breathe 2000ms infinite alternate;
    // box-shadow:0 0px 10px rgba(255,255,255,1);
  }
  @keyframes breathe {
    0% {
      opacity: 0.3;
      // box-shadow:0 0px 2px rgba(255,255,255,0.1);
    }
    100% {
      opacity: 1;
      // box-shadow:0 0px 10px rgba(255,255,255,1);
    }
  }
}
</style>