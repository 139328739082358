<!--
 * !Description: 
 * !Autor: joy
-->
<template>
  <div class="centerShow1">
    <img class="clsoe"
         src="../../../../../assets/img/close.png"
         alt=""
         @click="onClose">
    <div class="title">
      疫情中高风险地区提示
    </div>
    <div class="itemTitle">
      <div v-for="(item,index) in fileList"
           @click="onItem(index)"
           :key="item.name"
           class="item-name"
           :class="{'active': active === index}">{{item.name}}</div>
    </div>
    <div class="content">
      <iframe width="100%"
              height="100%"
              frameborder="0"
              scrolling="auto"
              ref="bdIframe"
              :src="'https://view.officeapps.live.com/op/embed.aspx?wdHideHeaders=True&wdHideGridlines=True&src='+encodeURIComponent('https://village.wingnew.com/huatanshan'+url)" />
    </div>
  </div>
</template>

<script>
// import incomeM from "./caiwu.js";
// console.log(incomeM);
export default {
  props: {
    show: Boolean,
  },
  components: {},
  computed: {
    fileList() {
      return this.$store.state.fengxianFile;
    },
    url() {
      return this.fileList[this.active] ? this.fileList[this.active].url : "";
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    // console.log(this.$refs.bdIframe);
    // this.$refs.bdIframe.forEach((item, index) => {
    //   document.getElementById("iframe_edit_commonStored" + index).onload =
    //     function (e) {
    //       console.log(
    //         "🚀 ~ file: fengxian.vue ~ line 51 ~ this.$refs.bdIframe.forEach ~ e",
    //         document.getElementById("iframe_edit_commonStored" + index)
    //           .clientHeight
    //       );
    //       // cover.hide();
    //     };
    // });
    // document.getElementById('iframe_edit_commonStored').onload=function(){
    //   cover.hide()
    // }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    iFrameHeight(e) {
      console.log(e);
    },
    onItem(i) {
      this.active = i;
    },
  },
};
</script>

<style lang="less" scoped>
.itemTitle {
  display: flex;
  padding: 16px;
  margin-top: 10px;
  .item-name {
    cursor: pointer;
    margin-right: 6px;
    &.active {
      color: #61fffb;
    }
  }
}
.centerShow1 {
  background-image: url("../../../../../assets/img/page4-bg-center1.png");
  width: 80%;
  flex-direction: column;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  height: calc(80vh - 160px);
  position: relative;
  display: flex;
  font-size: 14px;
}
.centerShow1 .clsoe {
  position: absolute;
  width: 40px;
  right: 0;
  top: -30px;
  cursor: pointer;
}
.centerShow1 .content {
  text-align: center;
  padding: 0 16px 16px 16px;
  flex: 1;
  height: 0;
  overflow: hidden;
}
.centerShow1 .title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-top: 1.45%;
  margin-left: 10px;
}
</style>
