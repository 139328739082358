<template>
  <div class="center-block">
    <img class="clsoe" src="../../assets/img/close.png" alt="" @click="centerShow=false">
    <div class="center-title" style="margin-top: 1.8%;">监控</div>
    <div class="center-item-wrap">
      <VueVideo :centerShow="centerShow"/>
    </div>
  </div>
</template>

<script>
// import {stopPlay} from '@/utils/jiankong.js'
import VueVideo from '@/components/video2'
export default {
	props:{
		show:Boolean
	},
	components:{
		VueVideo,
	},
	computed:{
		centerShow:{
			get(){
				return this.show
			},
			set(newv){
				this.$emit('update:show',newv)
			}
		}
	},
	beforeDestroy(){
		this.$jkDplayer.onPause()
	}
}
</script>

<style lang="less" scoped>
.center-block{
  background-image: url('../../assets/img/page1-center1.png');
  width:80%;
  display: flex;
  flex-direction: column;
  .center-item-wrap{
    overflow: hidden;
  }
  .item{
    width: 33%;
    position: relative;
    .video-name{
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-45deg, transparent 15px, rgba(0, 0, 0, .4) 0);
      width: 60%;
      line-height: 1.5;
      padding-left: 8px;
      z-index: 10;
    }
    &::after{
      content:'';
      display: block;
      margin-bottom: 70%;
    }
    .video-wrap{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
</style>