<template>
  <div id="app">
    <router-view />
    <!-- <MyJk/> -->
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch('getcamerasList').then(() => {
      this.$quanjuBj.init()
    })
  },
  methods: {},
  beforeDestroy() {}
}
</script>
<style lang="less">
#app {
  font-family: FZZhengHeiS-R-GB, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 0.14rem;
}
#jkDiv {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 12;
}
</style>
