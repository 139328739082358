<template>
  <div class="video-wrap"
       id="jiankong2">
    <div class="item-wrap">
      <swiper ref="slider"
              :options="swiperOptions">
        <swiper-slide class="slider-item"
                      v-for="a in Math.ceil( oSel.length / cur)"
                      :key="a">
          <template v-for="(item,index) in oSel">
            <div :ref="'journalUpward'+index"
                 class="table-row-slide-video"
                 :style="`background-image:url(${baseUrl}${item.image})`"
                 @click.stop="onVideoPlay(item,index)"
                 v-if="index < a * cur && index >= (a-1)*cur"
                 :key="index">
              <div v-show="!item.onPlay">
                <div v-for="(row,key,rindex) in item"
                     :key="rindex"
                     class="item-cell">
                  <div class="video-name">{{ item.name }}</div>
                </div>
                <!-- <img v-if="item.image" :src="baseUrl+item.image" alt=""> -->
                <img src="../../assets/img/jiankong/1.png">
              </div>
            </div>
          </template>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev"></div>
      <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
      <div class="swiper-button-next"></div>
      <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
    </div>
  </div>
</template>
 
<script>
// import {startRealPlay,stopPlay} from '@/utils/jiankong.js'
export default {
  name: "Videojs",
  props: {
    centerShow: Boolean,
  },
  watch: {
    centerShow(val) {
      if (!val) {
        this.slideChange();
      }
    },
  },
  computed: {
    activeTab() {
      return this.$store.state.pageNum;
    },
    oSel() {
      return JSON.parse(JSON.stringify(this.$store.state.jiankong));
    },
  },
  data() {
    return {
      cur: 4,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      swiperOptions: {
        initialSlide: 0,
        autoplay: false, //可选选项，自动滑动
        loop: true,
        delay: 5000,
        observeParents: true,
        observer: true,
        observeSlideChildren: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChange: this.slideChange,
        },
      },
      oPlugin: {
        width: 836,
        height: 540,
        top: 0,
        left: 0,
      },
      onPlay: false,
    };
  },
  methods: {
    onVideoPlay(item, index) {
      const domRect =
        this.$refs["journalUpward" + index][0].getBoundingClientRect();
      this.oPlugin = {
        width: domRect.width,
        height: domRect.height,
        top: domRect.top + "px",
        left: domRect.left + "px",
        right: domRect.right + "px",
        button: domRect.bottom + "px",
      };
      this.$store.state.jkOps = this.oPlugin;
      this.oSel.forEach((val, i) => {
        if (i === index) {
          val.onPlay = true;
        } else {
          val.onPlay = false;
        }
      });
      this.$jkDplayer.onPlay(item.vurl);
    },
    slideChange() {
      this.oSel.forEach((ele) => {
        ele.onPlay = false;
      });
      this.$jkDplayer.onPause();
    },
  },
};
</script>
 
<style lang='less' scoped>
.video-wrap {
  flex: 1;
  width: 0;
  padding: 20px 30px;
  height: 420px;
}
.item-wrap {
  width: 100%;
  height: 100%;
}
.slider-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.table-row-slide-video {
  width: calc(50% - 10px);
  flex-shrink: 0;
  height: 200px;
  background-image: url("../../assets/img/jiankong/3.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 5px;
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  }
}
.item-cell {
  position: relative;
}
.video-name {
  position: absolute;
  font-size: 16px;
  top: 0;
  left: 0;
  background: linear-gradient(-45deg, transparent 15px, rgba(0, 0, 0, 0.4) 0);
  width: auto;
  line-height: 1.5;
  padding-left: 8px;
  z-index: 10;
  text-align: left;
  padding: 0 20px 0 10px;
}
.swiper-button-next,
.swiper-button-prev {
  color: rgba(255, 255, 255, 0.6);
}
.yulanWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.close {
  cursor: pointer;
}
.plugin2 {
  position: fixed;
  z-index: 20;
}
.tool {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  img {
    height: 20px;
    cursor: pointer;
  }
}
</style>