<template>
  <div class="center-block">
    <img class="clsoe"
         src="../../../../../assets/img/close.png"
         alt=""
         @click="onClose">
    <div class="center-title"
         style="margin-top: 1.8%">
      耕地承包情况
    </div>
    <div class="center-item-wrap"
         style="
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: start;
      ">
      <div class="table-title-wrap">
        <div class="table-title-item"
             :style="{ flex: item.flex || 1 }"
             v-for="(item, index) in blockColumn"
             :key="index">
          {{ item.name }}
        </div>
      </div>
      <!-- :option="{autoplay:false}" -->
      <swiperTable :block-data="blockData"
                   :block-column="blockColumn"
                   :total="total"
                   :cur="cur"
                   @slideChange="slideChange" />
    </div>
  </div>
</template>

<script>
// import { getAlarmInfo } from "@/request/api";
import swiperTable from "@/components/swiperTable";
import gdata from "./gengdi.js";
// console.log(gdata);
export default {
  props: {
    show: Boolean,
  },
  components: {
    swiperTable,
  },
  computed: {
    centerShow: {
      get() {
        return this.show;
      },
      set(newv) {
        this.$emit("update:show", newv);
      },
    },
  },
  filters: {
    filterType(val) {
      const obj = {
        0: "瞬时",
        1: "开始",
        2: "停止",
        3: "事件脉冲",
        4: "事件联动结果更新",
      };
      return obj[val];
    },
  },
  data() {
    return {
      cur: 10,
      page: 1,
      total: 0,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      blockColumn: [
        { name: "户主姓名", key: "name", flex: 2 },
        { name: "已确权颁证到户的耕地面积(亩)", key: "mail1", flex: 2 },
        { name: "已确权但未颁证到户的耕地面积(亩)", key: "mail2", flex: 2 },
        // { name: "开始时间", key: "startTime", flex: 2 },
        // { name: "结束时间", key: "endTime", flex: 2 },
      ],
      blockData: [],
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    slideChange(e) {
      if (this.page * this.cur > this.blockData.length) return;
      // console.log(
      //   "🚀 ~ file: bjCenter.vue ~ line 71 ~ slideChange ~ this.page*this.cur",
      //   this.page * this.cur
      // );
      // console.log(
      //   "🚀 ~ file: bjCenter.vue ~ line 71 ~ slideChange ~ this.blockData.length",
      //   this.blockData.length
      // );
      this.page = e + 1;
      this.onLoad();
    },
    onLoad() {
      this.blockData = gdata;
      this.total = gdata.length;
      // getAlarmInfo({
      //   page: this.page,
      //   rows: this.cur,
      // }).then((res) => {
      //   this.total = res.data.data.total;
      //   const list = res.data.data.list;
      //   list.map((item) => {
      //     item.eventType = "紧急报警";
      //     item.startTime = item.startTime
      //       ? this.$moment(item.startTime).format("YYYY-MM-DD HH:mm:ss")
      //       : "";
      //     item.endTime = item.endTime
      //       ? this.$moment(item.endTime).format("YYYY-MM-DD HH:mm:ss")
      //       : "";
      //     item.status = {
      //       0: "瞬时",
      //       1: "开始",
      //       2: "停止",
      //       3: "事件脉冲",
      //       4: "事件联动结果更新",
      //     }[item.status];
      //   });
      //   this.blockData = this.blockData.concat(list);
      // });
    },
    onClose() {
      this.$emit("close");
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
.slide-inner {
  & > div {
    margin-right: 8px;
  }
}
.center-block {
  background-image: url("../../../../../assets/img/page1-center1.png");
  width: 80%;
  display: flex;
  flex-direction: column;
  .center-item-wrap {
    overflow: hidden;
  }
  .item {
    width: 33%;
    position: relative;
    .video-name {
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        -45deg,
        transparent 15px,
        rgba(0, 0, 0, 0.4) 0
      );
      width: 60%;
      line-height: 1.5;
      padding-left: 8px;
      z-index: 10;
    }
    &::after {
      content: "";
      display: block;
      margin-bottom: 70%;
    }
    .video-wrap {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
.table-title-wrap {
  background-image: url("../../../../../assets/img/page2center3.png");
  background-size: 100% 100%;
  height: auto;
  line-height: 28px;

  .table-title-item,
  .item-cell {
    padding: 0 6px;
  }
}
</style>
