<template>
  <transition name="fade">
    <div style="font-size: 18px" class="wrap" v-if="bj">
      <div>事件源名称: {{ bj.srcName }}</div>
      <div>事件类型: {{ bj.eventType }}</div>
      <div>事件状态: {{ bj.status | filterType }}</div>
      <div>
        开始时间:
        {{
          bj.startTime
            ? $moment(bj.startTime).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : ''
        }}
      </div>
      <div>
        结束时间:
        {{
          bj.endTime
            ? $moment(bj.endTime).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : ''
        }}
      </div>
      <div id="dplayerBjz" />
    </div>
  </transition>
</template>

<script>
import DPlayer from 'dplayer'
const Hls = require('hls.js')
import { getAlarmPopUps } from '@/request/api'
export default {
  data() {
    return {
      bj: null,
      baseUrl: process.env.VUE_APP_API_BASE_URL
      // bj: {
      //   srcName: "xxx",
      // },
    }
  },
  filters: {
    filterType(val) {
      const obj = {
        0: '瞬时',
        1: '开始',
        2: '停止',
        3: '事件脉冲',
        4: '事件联动结果更新'
      }
      return obj[val]
    }
  },
  methods: {
    initPlay() {
      new DPlayer({
        container: document.getElementById('dplayerBjz'),
        live: true,
        autoplay: true,
        lang: 'zh-cn',
        theme: '#FADFA3',
        preload: 'auto',
        volume: 0,
        video: {
          url: this.$store.state.jkz.vurl,
          pic: this.baseUrl + this.$store.state.jkz.image,
          type: 'customHls',
          customType: {
            customHls: (video) => {
              if (this.hls) {
                this.hls.destroy()
              }
              this.hls = new Hls()
              this.hls.loadSource(video.src)
              this.hls.attachMedia(video)
            }
          }
        }
      })
    },
    init() {
      this.getData()
      setInterval(() => {
        this.getData()
      }, 30000)
    },
    getData() {
      getAlarmPopUps().then((res) => {
        // console.log('🚀 ~ file: index.vue ~ line 28 ~ getAlarmPopUps ~ res', res)
        this.bj = res.data
        if (this.bj) {
          this.$nextTick(() => {
            this.initPlay()
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
#dplayerBjz {
  width: 100%;
  height: 500px;
  margin-top: 20px;
}
.wrap {
  position: fixed;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  z-index: 30;
  background: linear-gradient(
    rgba(24, 140, 120, 1),
    rgba(24, 140, 120, 0.6)
  );
  border: 1px solid #00ffcc;
  padding: 10px 16px 10px 25px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  & > div {
    margin-right: 16px;
  }
}
</style>
