<template>
  <div class="page-content page-content-1">
    <div class="left">
      <div class="left-title">实有人口统计</div>
      <div class="block-1">
        <div class="item-block-1">
          <item :ops="ops1"></item>
        </div>
        <div class="chart">
          <div id="myChart1" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </div>
      <div class="block-2">
        <div class="item-block-1"><item :ops="ops2"></item></div>
        <div class="chart">
          <div id="myChart2" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="center">
        <template v-if="centerShow">
          <!-- {{ showName }} -->
          <!-- <div v-if="showName === '监控'">222</div> -->
          <jkCenter :show.sync="centerShow" v-if="showName === '监控'" />
          <!-- <div v-if="showName === '报警'">111</div> -->
          <bjCenter :show.sync="centerShow" v-if="showName === '报警柱'" />
        </template>
      </div>
    </transition>
    <div class="right">
      <div class="block-3">
        <div class="right-title">非公经济、社会组织统计</div>
        <div class="chart-3">
          <div id="myChart3" :options="option3" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </div>
      <div class="block-4">
        <div class="right-title">土地资源统计</div>
        <div class="block-right-content">
          <div class="item-block-4">
            <div class="item" v-for="(item, index) in item4" :key="index">
              <div>{{ item.name }}</div>
              <div :style="{ color: item.color, fontSize: '28px', fontWeight: 'bold', marginTop: '4px' }">
                <ICountUp
                  ref="countUp"
                  :delay="600"
                  :endVal="item.num"
                  :options="{
                    decimalPlaces: item.decimals || 0
                  }"
                />
                <span :style="{ fontSize: '16px' }">{{ item.unit }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block-5">
        <div class="right-title">智能防控统计</div>
        <div class="block-right-content">
          <div class="item-block-5">
            <item :ops="ops5" @itemClick="onCenterShow"></item>
          </div>
          <div class="item5-wrap">
            <div class="item" v-for="item in item5" :key="item.name">
              <template v-if="!item.item">
                <div class="item-title">{{ item.name }}</div>
                <div class="item-num">
                  <span>{{ item.num }}</span
                  ><span class="item-unit">{{ item.unit }}</span>
                </div>
              </template>
              <template v-else>
                <div class="item-title">{{ item.name }}</div>
                <div v-for="iitem in item.item" :key="iitem.name" style="display: flex; font-size: 16px; margin-top: 4px; align-items: left; width: 80px">
                  <div style="margin-right: 4px">{{ iitem.name }}</div>
                  <div>
                    <span>{{ iitem.num }}</span
                    ><span>{{ iitem.unit || '人' }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import item from '@/components/item'
import jkCenter from '@/components/jkCenterBlock'
import { getCountByType } from '@/request/api'
const axisLineColor = 'rgba(97, 255, 251, .3)'
import bjCenter from '@/components/jkCenterBlock/bjCenter'
export default {
  components: {
    item,
    jkCenter,
    bjCenter
  },
  watch: {
    centerShow: {
      handler(val) {
        this.$store.state.centerShow = val
      }
    },
    jkLen: {
      handler(val) {
        this.ops5.item[0].num = val
      },
      immediate: true
    }
  },
  computed: {
    jkLen() {
      return this.$store.state.jiankong.length
    }
  },
  data() {
    return {
      centerShow: false,
      myChart1: null,
      option1: {
        title: {
          //图表的标题，注释掉就没有名称了
          text: '实有房屋、出租屋占比',
          textStyle: {
            color: '#fff',
            fontSize: '16px'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          textStyle: {
            color: '#333'
          },
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          formatter: function (params) {
            let domHtml = `<div style="margin-bottom:6px">${params[0].name}</div>`
            params.forEach((item) => {
              domHtml += `<span style='display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color.colorStops[1].color}'></span><span style="width:50px;display:inline-block;">${item.seriesName}</span> <span style="font-weight:bold"> ${item.value}</span> 间<br/>`
            })
            return domHtml
          }
        },
        legend: {
          data: ['房屋', '出租屋'], //这里设置柱状图上面的方块，名称跟series里的name保持一致
          align: 'right', //上面方块靠右还是居中的设置.不设置则居中
          left: '64%',
          itemHeight: 14,
          itemWidth: 14,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '3%', //柱状图距离左边的距离，也可以用像素px
          right: '4%', //柱状图距离右边的距离，也可以用像素px
          bottom: '10%', //网格图（柱状图、折线图、气泡图等）离底部的距离，也可以用像素比如10px
          containLabel: true //grid 区域是否包含坐标轴的刻度标签。false可能溢出，默认为false
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              //刻度
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: '#fff'
            },
            axisLine: {
              lineStyle: {
                color: axisLineColor
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              fontSize: 10,
              color: '#fff'
            },
            splitLine: {
              lineStyle: {
                color: axisLineColor
              }
            }
          }
        ],
        series: [
          {
            //柱状图-柱子1
            name: '房屋', //需要跟legend配置项对应
            type: 'bar',
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: 'rgba(225,179,53,0.2)'
                },
                {
                  offset: 1,
                  color: 'rgba(225,179,53,1)'
                }
              ]),
              barBorderRadius: [10, 10, 0, 0] //控制柱状图的圆角显示弧度，（顺时针左上，右上，右下，左下）
            },
            barGap: '50%',
            barWidth: '14%' //barWidth设置每根柱状图的宽度
          },
          {
            //柱状图-柱子2
            name: '出租屋',
            type: 'bar',
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: 'rgba(21,176,252,0.2)'
                },
                {
                  offset: 1,
                  color: 'rgba(21,176,252,1)'
                }
              ]),
              barBorderRadius: [10, 10, 0, 0] //控制柱状图的圆角显示弧度，（顺时针左上，右上，右下，左下）
            },
            barWidth: '14%'
            // data: [10, 66, 98, 289, 370, 310, 200, 134, 67, 12, 123, 240]
          }
        ]
      },
      ops1: {
        title: '房屋统计',
        item: [
          { img: require('@/assets/img/7.png'), name: '实有房屋', num: 567, color: 'rgba(172, 161, 232, 1)' },
          { img: require('@/assets/img/2.png'), name: '出租屋', num: 0, color: 'rgba(102, 244, 168, 1)' }
        ]
      },
      ops2: {
        title: '人口统计',
        item: [
          { img: require('@/assets/img/3.png'), name: '实有人口', num: 2058, color: 'rgba(119, 251, 73, 1)' }, //2058
          { img: require('@/assets/img/4.png'), name: '实有户数', num: 567, color: 'rgba(255, 244, 92, 1)' }
        ]
      },
      myChart2: null,
      option2: {
        title: {
          //图表的标题，注释掉就没有名称了
          text: '实有人口、户数占比',
          textStyle: {
            color: '#fff',
            fontSize: '16px'
          }
        },
        color: ['#FFB335', '#14B1FF'], //依次选择颜色，默认为第一个颜色，多根柱子多个颜色
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          textStyle: {
            color: '#333'
          },
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          formatter: function (params) {
            let domHtml = `<div style="margin-bottom:6px">${params[0].name}</div>`
            params.forEach((item) => {
              domHtml += `<span style='display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color}'></span><span style="width:50px;display:inline-block;">${item.seriesName}</span> <span style="font-weight:bold"> ${item.value}</span> 间<br/>`
            })
            return domHtml
          }
        },
        legend: {
          data: ['人口', '户数'], //这里设置柱状图上面的方块，名称跟series里的name保持一致
          align: 'right', //上面方块靠右还是居中的设置.不设置则居中
          left: '64%',
          itemHeight: 14,
          itemWidth: 14,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '3%', //柱状图距离左边的距离，也可以用像素px
          right: '4%', //柱状图距离右边的距离，也可以用像素px
          bottom: '10%', //网格图（柱状图、折线图、气泡图等）离底部的距离，也可以用像素比如10px
          containLabel: true //grid 区域是否包含坐标轴的刻度标签。false可能溢出，默认为false
        },
        xAxis: [
          {
            type: 'category',
            // data: ['某某村', '某某村', '某某村','某某村','某某村'],
            axisTick: {
              //刻度
              show: false
            },
            axisLabel: {
              fontSize: 10,
              color: '#fff'
            },
            axisLine: {
              lineStyle: {
                color: axisLineColor
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              fontSize: 10,
              color: '#fff'
            },
            splitLine: {
              lineStyle: {
                color: axisLineColor
              }
            }
          }
        ],
        series: [
          {
            //柱状图-柱子1
            name: '人口', //需要跟legend配置项对应
            type: 'line',
            smooth: true,
            showAllSymbol: true,
            symbolSize: 0,
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            lineStyle: {
              color: 'rgba(225,179,53,1)',
              shadowColor: 'rgba(0, 0, 0, .3)',
              shadowBlur: 0
            },
            // itemStyle: {
            // 	color: '#3988C8',
            // 	borderColor: '#fff',
            // 	borderWidth: 2,
            // 	shadowColor: 'rgba(0, 0, 0, .3)',
            // 	shadowBlur: 0,
            // 	shadowOffsetY: 2,
            // 	shadowOffsetX: 2,
            // },
            label: {
              show: false
            },
            // areaStyle: { //区域填充样式
            // 	color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            // 		offset: 0,
            // 		color: 'rgba(225,179,53,0.5)'
            // 	},
            // 	{
            // 		offset: 1,
            // 		color: 'rgba(225,179,53,0)'
            // 	}
            // 	], false),
            // 	shadowColor: 'rgba(225,179,53, 0.9)', //阴影颜色
            // 	shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
            // },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255, 179, 53, .5)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 179, 53, .1)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            }
            // data: [10, 52, 200, 334, 390, 330, 220, 34, 67, 12, 123, 255]
          },
          {
            //柱状图-柱子2
            name: '户数',
            type: 'line',
            smooth: true,
            showAllSymbol: true,
            symbolSize: 0,
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            // data: [10, 66, 98, 289, 370, 310, 200, 134, 67, 12, 123, 240],
            lineStyle: {
              color: 'rgba(21,176,252,1)'
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0,175,242,0.5)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,175,242,0)' // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            }
            // areaStyle: { //区域填充样式
            // 	color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            // 		offset: 0,
            // 		color: 'rgba(21,176,252,.5)'
            // 	},
            // 	{
            // 		offset: 1,
            // 		color: 'rgba(21,176,252, 0)'
            // 	}
            // 	], false),
            // 	shadowColor: 'rgba(21,176,252, 0.9)', //阴影颜色
            // 	shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
            // },
          }
        ]
      },
      item4: [
        { name: '总面积', unit: '平方公里', num: 4.5, color: 'rgba(236, 89, 100, 1)', decimals: 1 },
        { name: '水田面积', unit: '亩', num: 986, color: 'rgba(255, 179, 53, 1)' },
        { name: '旱地面积', unit: '亩', num: 110, color: 'rgba(255, 244, 92, 1)' },
        { name: '山林面积', unit: '亩', num: 6499, color: 'rgba(86, 229, 150, 1)' },
        { name: '油茶林面积', unit: '亩', num: 3739, color: 'rgba(0, 203, 255, 1)' }
        // {name:'总面积',unit:'m²',num:'211100',color:'rgba(157, 141, 237, 1)'}
      ],
      ops5: {
        item: [
          { img: require('@/assets/img/1.png'), name: '监控', num: 0, color: 'rgba(172, 161, 232, 1)', unit: '个' },
          { img: require('@/assets/img/5.png'), name: '报警柱', num: 1, color: 'rgba(95, 222, 255, 1)', unit: '个' }
        ]
      },
      item5: [
        {
          name: '村口客流',
          item: [
            { name: '进入', num: 348 },
            { name: '离开', num: 213 },
            { name: '重复', num: 75 }
          ]
        },
        { name: '区域入侵', num: 0, unit: '次' },
        { name: '越界侦测', num: 0, unit: '次' }
      ],
      option3: {
        colors: ['#8C1D17', '#43DEFF', '#00B3B0', '#47BD91', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
        legend: {
          align: 'right',
          layout: 'vertical',
          verticalAlign: 'middle',
          itemMarginBottom: 8,
          itemStyle: {
            color: '#fff'
          },
          useHTML: true,
          labelFormatter: function () {
            return `<span style="display:inline-block;width:70px">${this.name}</span> ${this.percentage.toFixed(1)}%`
          }
        },
        chart: {
          type: 'pie',
          plotBackgroundColor: null,
          backgroundColor: 'transparent',
          plotBorderWidth: null,
          plotShadow: false,
          options3d: {
            enabled: true,
            alpha: 60,
            beta: 0,
            depth: 200
          }
        },
        title: {
          text: ''
        },
        showName: '',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          textStyle: {
            color: '#ffffff'
          },
          useHTML: true,
          formatter: function () {
            return `<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${this.point.color}'></span>${this.point.name}${
              this.point.y
            }家</br>占比：${this.point.percentage.toFixed(1)}%`
          }
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 20,
            dataLabels: {
              enabled: false,
              format: '{point.name}'
            },
            showInLegend: true
            // slicedOffset:20
          }
        },
        series: [
          {
            type: 'pie',
            colorByPoint: true,
            // name: '浏览器占比',
            data: [
              // ['集体经济', 3],
              // ['个人经济',5],
              // ['某某组织', 8.5],
              // ['某某组织',6.2],
              // ['某某组织',0.7]
            ]
          }
        ]
      }
    }
  },
  created() {
    Promise.resolve().then(() => {
      this.initMyChart1()
      this.initMyChart2()
      this.initMyChart3()
    })
    getCountByType().then((res) => {
      const data = res.data
      const names = Object.keys(data)
      const item = this.item5
      item[1].num = data[names[0]] || 4
      item[2].num = data[names[1]] || 9
      this.item5 = item
    })
  },
  beforeDestroy() {
    this.$store.state.centerShow = false
  },
  methods: {
    onCenterShow(name) {
      console.log('🚀 ~ file: index.vue ~ line 516 ~ onCenterShow ~ name', name)
      this.showName = name
      this.$forceUpdate()
      if (name === '监控') {
        this.centerShow = true
        this.$store.commit('setBgBlockActive', false)
      } else if (name === '报警柱') {
        this.centerShow = true
        this.$store.commit('setBgBlockActive', false)
      }
    },
    initMyChart1() {
      const myChart1 = document.getElementById('myChart1')
      this.myChart1 = this.$echarts.init(myChart1)
      this.option1.series[0].data = [111, 89, 101, 27, 54, 68, 91, 26]
      this.option1.xAxis[0].data = ['大塘后', '黄墩', '坞里', '新塘', '前山', '上屋', '底屋', '樟家坞']
      this.option1.series[1].data = [0, 0, 0, 0, 0, 0, 0, 0]
      this.myChart1.setOption(this.option1)
    },
    initMyChart2() {
      const myChart2 = document.getElementById('myChart2')
      this.myChart2 = this.$echarts.init(myChart2)
      this.option2.xAxis[0].data = ['大塘后', '黄墩', '坞里', '新塘', '前山', '上屋', '底屋', '樟家坞']
      this.option2.series[0].data = [389, 299, 342, 94, 196, 242, 310, 82]
      this.option2.series[1].data = [111, 89, 101, 27, 54, 68, 91, 26]
      this.myChart2.setOption(this.option2)
    },
    initMyChart3() {
      const myChart3 = document.getElementById('myChart3')
      this.option3.series[0].data = [
        ['樟涧民宿', 18],
        ['樟涧农家乐', 3],
        ['樟涧土特产', 5],
        ['特色餐饮', 5],
        ['乡村合作社', 6],
        ['乡村农场', 1]
      ]
      this.chart = new Highcharts.Chart(myChart3, this.option3)
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
