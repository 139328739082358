import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index/index.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		redirect: { path:'/3' }
	},
	{
		path: '/:id',
		name: 'Home',
		component: Home,
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
})

export default router
