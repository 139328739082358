<template>
  <div class="video-wrap" id="jiankong2">
    <div class="item-wrap">
      <swiper ref="slider" :options="swiperOptions">        
        <swiper-slide class="slider-item" v-for="a in Math.ceil( oSel.length / cur)" :key="a">
          <div v-for="(item,index) in oSel" :key="index">
            {{ item }}
          </div>
        </swiper-slide>
      </swiper> 
      <div class="swiper-button-prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
      <div class="swiper-button-next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
    </div>
  </div>
</template>
 
<script>
import {getAlarmInfo} from '@/request/api'
export default {
	name: 'Videojs',
	props:{
		centerShow:Boolean
	},
	watch:{
	
	},
	computed:{
	},
	data () {
		return {
			cur:4,
			baseUrl:process.env.VUE_APP_API_BASE_URL,
			swiperOptions:{
				initialSlide:0,
				autoplay:false,//可选选项，自动滑动
				loop:true,
				delay:5000,
				observeParents: true,
				observer: true,
				observeSlideChildren:true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on:{
					slideChange:this.slideChange
				}
			},
			oSel:[]
		}
	},
	methods: {
		onLoad(){
			getAlarmInfo().then(res =>{
				this.oSel = res.data
			})
		},
		slideChange(){		
		}
	}
}
</script>
 
<style lang='less' scoped>
.video-wrap{
	flex:1;
	width: 0;
	padding:20px 30px;
	height:420px;
}
.item-wrap{
	width:100%;
	height:100%;

}
.slider-item{
	display: flex;
	flex-wrap: wrap;
	justify-content: start
}
.table-row-slide-video{
	width: calc(50% - 10px);
	flex-shrink: 0;
	height:200px;
	background-image: url("../../assets/img/jiankong/3.png");
	background-size: cover;
	background-repeat: no-repeat;	
	margin: 5px;
	position: relative;
	cursor: pointer;
		img{
			position: absolute;
			top: calc(50% - 25px);
			left: calc(50% - 25px);
		}
	}
.item-cell{
	position: relative;

}
.video-name{
	position: absolute;
	font-size: 16px;
	top: 0;
	left: 0;
	background: linear-gradient(-45deg, transparent 15px, rgba(0, 0, 0, .4) 0);
	width: auto;
	line-height: 1.5;
	padding-left: 8px;
	z-index: 10;
	text-align: left;
	padding:0 20px 0 10px;
}
.swiper-button-next,.swiper-button-prev{
  color:rgba(255,255,255,.6)
}
.yulanWrap{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}
.close{
	cursor: pointer;
}
.plugin2{
	position: fixed;
	z-index: 20;
}
.tool{
	position: absolute;
	bottom: 0;
	left: 0;
	width:100%;
	height:30px;
	background-color: rgba(255,255,255,.5);
	display: flex;
	align-items: center;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	img{
		height:20px;
		cursor: pointer;
	}
}
</style>