import axios from 'axios'

const baseUrl = process.env.NODE_ENV === 'production' ? process.env.baseUrl : ''

const service = axios.create({
	baseUrl: baseUrl,
	timeout: 50000
})

service.interceptors.request.use(
	config => {
		// const cookie = "configsite_token ='f17e5d65-70cf-4ffa-a726-a6d74ab62ac1';JSESSIONID = '0E11909F5BEBF705DD598234AE88E14D'"
		// config.headers.Cookie=cookie
		return config
	},
	error => {
		return error
	}
)
service.interceptors.response.use(
	response => {
		if (response.status === 200) {
			return Promise.resolve(response.data)
		} else {
			return Promise.reject(response)
		}
	},
	error => {
		return Promise.reject(error.response)
	}
)
export default service