<template>
  <transition name="fade">
    <div
      class="plugin"
      :style="{
        width: oPlugin.width + 'px',
        height: oPlugin.height + 'px',
        top: oPlugin.top,
        left: oPlugin.left
      }"
      v-show="play"
    >
      <div
        id="dplayer"
        :style="{
          width: oPlugin.width + 'px',
          height: oPlugin.height + 'px'
        }"
      ></div>
    </div>
  </transition>
</template>

<script>
import DPlayer from 'dplayer'
const Hls = require('hls.js')
export default {
  name: 'Jk',
  computed: {
    oPlugin() {
      return this.$store.state.jkOps
    }
  },
  data() {
    return {
      dp: null,
      hls: null,
      play: false
    }
  },
  mounted() {},
  methods: {
    initPlay(url) {
      this.dp = new DPlayer({
        container: document.getElementById('dplayer'),
        live: true,
        autoplay: true,
        lang: 'zh-cn',
        theme: '#FADFA3',
        preload: 'auto',
        volume: 0,
        video: {
          url: url,
          // pic:require('../../assets/img/jiankong/3.png'),
          type: 'customHls',
          customType: {
            customHls: (video) => {
              if (this.hls) {
                this.hls.destroy()
              }
              this.hls = new Hls()
              this.hls.loadSource(video.src)
              this.hls.attachMedia(video)
            }
          }
        }
      })
    },
    onPlay(url) {
      this.play = true
      if (this.dp) {
        this.dp.destroy()
        this.hls.destroy()
      }

      this.$nextTick(() => {
        this.initPlay(url)
      })
    },
    onPause() {
      this.play = false
      if (this.dp) {
        this.dp.destroy()
        this.hls.destroy()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.plugin {
  position: fixed;
  z-index: 20;
  // transition:all ease-in 0.5s;
}
.tool {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  // padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  img {
    height: 20px;
    cursor: pointer;
  }
  // img:nth-of-type(1),img:nth-of-type(2){
  // 	padding-left: 10px;
  // }
  img {
    padding-right: 10px;
  }
}
</style>
