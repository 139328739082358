<template>
  <div class="block-4" :class="activeTab === 1 ? 'page1Shuizhi' : ''">
    <div class="block-4-inner">
      <div class="right-title">水质监测</div>
      <div class="item-wrap">
        <div class="item-1">
          <div class="item-cell" v-for="(item,index) in left" :key="index">
            <div>
              <div>{{ item.name }}</div>
              <div style="color:rgba(0, 255, 228, 1);fontSize:14px"></div>
            </div>
            <div>
              <span style="color:rgba(0, 255, 228, 1);fontSize:36px;fontWeight:bold">
                <ICountUp 
                  ref="countUp"
                  :delay="activeTab === 5 ? 600 : 0"
                  :endVal="item.value"
                  :options="{
                    decimalPlaces:item.decimals || 0
                  }"
                />
              </span>
              <span style="color:rgba(0, 255, 228, 1);fontSize:16px">{{ item.unit }}</span>
            </div>
          </div>
        </div>
        <div class="item-2">
          <div>{{ center.name }}<span style="color:rgba(0, 255, 228, 1)"></span>{{ center.unit }}</div>
          <div style="color:rgba(0, 255, 228, 1);fontSize:50px;fontWeight:bold">
            <ICountUp 
              ref="countUp"
              :delay="activeTab === 5 ? 600 : 0"
              :endVal="center.value"
              :options="{
                decimalPlaces:center.decimals || 0
              }"
            />
          </div>
        </div>
        <div class="item-3">
          <div class="item-cell" v-for="(item,index) in right" :key="index">
            <div>
              <div>{{ item.name }} </div>
              <div style="color:rgba(0, 255, 228, 1);fontSize:14px"></div>
            </div>
            <div>
              <span style="color:rgba(0, 255, 228, 1);fontSize:36px;fontWeight:bold">
                <ICountUp 
                  ref="countUp"
                  :delay="activeTab === 5 ? 600 : 0"
                  :endVal="item.value"
                  :options="{
                    decimalPlaces:item.decimals || 0
                  }"
                />
              </span>
              <span style="color:rgba(0, 255, 228, 1);fontSize:16px">{{ item.unit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	components:{
	},
	computed:{
		activeTab(){
			return this.$store.state.pageNum
		}
	},
	data(){
		return {
			left:[
				{name:'氧气浓度',value:0.1,unit:'%',decimals:1},
				{name:'蒸发量',value:59,unit:'g',decimals:0}
			],
			right:[
				{name:'水中电导率',value:47,unit:'us/cm',decimals:0},
				{name:'水中PH',value:5.9,unit:'pH',decimals:1}
			],
			center:{name:'溶解氧',value:0.52,unit:'mg/L',decimals:2}
		}
	},
	methods:{
	}
}
</script>

<style lang='less' scoped>
.block-4{
  background-image: url('../../assets/img/page5-bg4.png');
  background-size: 100% 100%;
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 @bgposY;
  display: flex;
  flex-direction: column;
  // margin-bottom: 12px;
  &::after{
    content:'';
    display: block;
    margin-bottom: 58%;
  }
  .item-wrap{
    display: flex;
    // flex:1;
    margin-top: 20px;
    padding:0 20px;
    flex:1;
    height:0;
    .item-1,.item-2,.item-3{
      flex:1;
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 0;
      height:100%;
      overflow: hidden;
    }
    .item-1,.item-3{
      flex-direction: column;
      .item-cell{
        // flex:1;
        // height:0;
      }
    }
    .item-2{
      align-items: center;
      height:100%;
      flex-direction: column;
    }
    .item-cell{
      width:100%;
      display: flex;
      flex-direction: column;
      & div:nth-of-type(1){
        flex:1;
        margin-top: 10px;
      }
      & div:nth-of-type(2){
        margin-bottom: 10px;
      }
    }
    .item-3{
      .item-cell{
       text-align: right;
      }
    }
  }
}
.right-title{
  text-align: left;
  font-weight: bold;
  position: relative;
  padding-left: 16px;
  font-size: 16px;
}
.page1Shuizhi{
  background-image: url('../../assets/img/page5-bg4-2.png');
  .right-title{
    margin-top: 6px;
  }
}
.block-4-inner{
  position: absolute;
  height:100%;
  width:100%;
  display: flex;
  flex-direction: column;
}
</style>