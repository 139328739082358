import request from './http'
const baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : ''
const baseUrl2 = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_M3U8_BASE_URL : ''

export function getWeather () {
	return request.get('https://restapi.amap.com/v3/weather/weatherInfo?key=2c7fae60acc027f530c16a36b47fecff&city=361100')
}

//党员人数统计
export function getPartyCount () {
	return request.get(baseUrl + '/api/party/get_count?vid=2')
}

//党龄统计
export function getPartystandingCount () {
	return request.get(baseUrl + '/api/party/get_party_standing?vid=2')
}

//党员年龄统计
export function getPartyAgeCount () {
	return request.get(baseUrl + '/api/party/get_member_age?vid=2')
}

//党员学历统计
export function getPartyEduCount () {
	return request.get(baseUrl + '/api/party/get_education?vid=2')
}

//最新活动
export function getPartyActivity () {
	return request.get(baseUrl + '/api/party/get_activity?vid=2')
}

//意见反馈
export function getproposalList () {
	return request.get(baseUrl + '/api/proposal/list?vid=2')
}

//政务数据 cid 9：村务公开 10：财务公开 14：党务公开
export function getarticleList (cid) {
	return request.get(baseUrl + '/api/article/list?vid=2&cid=' + cid)
}

//政务数据详情
export function getarticleDetail (params) {
	return request.get(baseUrl + `/api/article/detail?vid=2&cid=${params.cid}&id=${params.id}`)
}

//摄像头列表
export function getvideomonitorList () {
	return request.get(baseUrl + `/api/videomonitor/list?vid=2`)
}

//反馈分类统计数据
export function getproposal (params = 'today') {
	if (typeof params === 'string') {
		return request.get(baseUrl + `/api/proposal/today?vid=2&starttime=today`)
	} else {
		return request.get(baseUrl + `/api/proposal/today?vid=2&starttime=${params.starttime}&endtime=${params.endtime}`)
	}
}

//景点top6
export function getsightsTop6 () {
	return request.get(baseUrl + `/api/sights/top6?vid=2`)
}

//食品top6
export function getfoodsTop6 () {
	return request.get(baseUrl + `/api/food/top6?vid=2`)
}

//农技课堂
export function getcoursecount () {
	return request.get(baseUrl + `/api/course/count?vid=2`)
}

//广播
export function getbroadcast () {
	return request.get(baseUrl + `/api/broadcast/status?vid=2`)
}

//手表在线率
export function getwatchOnlineRate () {
	return request.get(baseUrl + `/api/watch/online_rate?vid=2`)
}

//监控列表
export function getcamerasList () {
	return request.get(baseUrl2 + `/digital/api/hik/camerasList`)
}

//报警信息
export function getCountByType () {
	return request.get(baseUrl2 + `/digital/api/hik/getCountByType`)
}

//紧急报警事件日志查询
export function getAlarmInfo (params) {
	return request.get(baseUrl2 + `/digital/api/hik/getAlarmInfo`, {
		params: params
	})
}

//报警立柱弹窗
export function getAlarmPopUps (params) {
	return request.post(baseUrl2 + `/digital/api/hik/getAlarmPopUps`, params)
}

export function getFile (url) {
	return request.get(baseUrl2 + url)
}