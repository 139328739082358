import { render, staticRenderFns } from "./page2.vue?vue&type=template&id=3e0cf4bd&scoped=true&"
import script from "./page2.vue?vue&type=script&lang=js&"
export * from "./page2.vue?vue&type=script&lang=js&"
import style0 from "./page2.vue?vue&type=style&index=0&id=3e0cf4bd&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_geeguvfn2njjaew5w23blzhalq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e0cf4bd",
  null
  
)

export default component.exports