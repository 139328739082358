<template>
  <div class="centerShow1">
    <img class="clsoe"
         src="../../../../../assets/img/close.png"
         alt=""
         @click="onClose">
    <div class="title">
      村 情 简 介
    </div>
    <div class="content">
      <div style="text-indent:2em">
        彭家坞村位于广信区北部，距离市区55公里，镇政府3公里，与华坛山、望仙、郑坊三乡镇交界，总面积4.5平方公里。全村拥有水田面积986亩，旱地面积110亩，林地面积6499亩（其中油茶林3739亩），2002年与原樟涧村两村合并，保留现彭家坞行政村村名，全村共有9个自然村，15个村民小组，545户，2000余人。由于地处山区，受地理环境的影响，村子无主要的支柱产业，村民主要以外出打工和种植水稻为主。全村现有建档立卡110户393人，其中 2014年脱贫42户174 人，2016年脱贫6户25人，2017年脱贫47户162人，2018年脱贫0户,2019年脱贫5户11人，2020年脱贫10户15人。
      </div>
      <div class="c-title">组织构架图</div>
      <div class="table">
        <div v-for="item in list"
             class="cell"
             :key="item.name">
          <div class="td td1">{{item.de}}</div>
          <div class="td td2">{{item.name}}</div>
          <div class="td td3">
            <div>手机号码：{{item.phone}}</div>
            <div>{{item.worker}}</div>
            <div v-if="item.fanwei">包干片区：{{item.fanwei}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  components: {},
  data() {
    return {
      list: [
        {
          de: "支部书记",
          name: "周永春",
          phone: "15879375278",
          worker: "负责全面工作",
          fanwei: "前山、上屋",
        },
        {
          de: "副支部书记",
          name: "姜远成",
          phone: "18688731493",
          worker: "纪检委员和党建工作、民俗接待",
          fanwei: "大塘后",
        },
        {
          de: "支部委员",
          name: "周财发",
          phone: "15216096090",
          worker: "组织委员和农、林、水、合作社、民宿",
          fanwei: "底屋、樟家坞",
        },
        {
          de: "妇女主席",
          name: "王宝凤",
          phone: "13755313424",
          worker: "负责文卫、妇联、文明实践站工作",
          fanwei: "黄墩",
        },
        {
          de: "村委委员",
          name: "姜金政",
          phone: "13426638698",
          worker: "负责环境整治、民政、农保、危改工作",
          fanwei: "坞里",
        },
        {
          de: "聘用干部",
          name: "郑胜春",
          phone: "17379380099",
          worker: "负责扶贫、信息员、报账员",
          // fanwei: "黄墩",
        },
      ],
    };
  },
  created() {},
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.centerShow1 {
  background-image: url("../../../../../assets/img/page4-bg-center1.png");
  width: 80%;
  flex-direction: column;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 40px;
  height: calc(80vh - 200px);
  position: relative;
  display: flex;
}
.centerShow1 .clsoe {
  position: absolute;
  width: 40px;
  right: 0;
  top: -30px;
  cursor: pointer;
}
.centerShow1 .content {
  text-align: center;
  margin: 40px 6px;
  padding: 0 6px;
  flex: 1;
  height: 0;
  overflow: auto;
}
.centerShow1 .title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-top: 1.45%;
  margin-left: 10px;
}
.c-title {
  text-align: center;
  margin: 12px 0;
  font-size: 16px;
}
.table {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .cell {
    display: flex;
    .td {
      border: 1px solid #fff;
      padding: 8px;
    }
    .td1,
    .td2 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
    }
    .td3 {
      flex: 1;
      text-align: left;
      div {
        padding: 6px 0;
      }
    }
  }
}
</style>
