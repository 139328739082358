<template>
  <div style="width: 100%;position: relative;margin: auto;min-height:30vh">
    <div
      class="wrap" 
      v-for="item in list"
      :key="item.name"
    >
      <div style="margin: 0 0 3px 28px">{{ item.name }}</div>
      <div class="content">
        {{ item.content }}
      </div>
      <img src="../../assets/img/gb-icon.png" alt="" class="icon-wrap">
    </div>
  </div>
</template>

<script>
import {getbroadcast} from '@/request/api'
export default {
	name:'GbList',
	props:{
		ops:{
			type:Object,
			default:()=>{}
		}
	},
	data(){
		return{
			list:[]
		}
	},
	created(){
		const list = []
		getbroadcast().then(res =>{
			res.data.forEach((item,index) =>{
				if(index > 5) return
				const content = []
				content.push(`广播状态：${item.byCurStateStr}`)
				if(item.musicFullInfo){
					content.push(`正在播放：${item.musicFullInfo}`)
				}
				content.push(`设备类型：${item.byDevTypeName}, 广播音量：${item.byCurVol}`)
				list.push({content:content.join(', '),name:item.cpDevName})
			})
			this.list = list
		})
	}
}
</script>
<style lang="less" scoped>
.icon-wrap{
  width:55px;
  height:auto;
  position: absolute;
  top: -10px;
  left: -34px;
}
.wrap{
  margin-bottom: 50px;
  position: relative;
}
.content{
  background: linear-gradient( rgba(24,140,120, 1), rgba(24,140,120,0.6));
  border: 1px solid #00FFCC;  
  padding:10px 16px 10px 25px;
  // position: absolute;
  // top: 40px;
  // left:40px;
  min-width: 150px;
  min-height: 18px;
}
</style>