// import Vue from 'vue'
import Jk from './index2.vue'
import store from '@/store'
// const JkConstructor = Vue.extend(Jk)

export default{
	install(Vue){
		const Com = Vue.extend(Jk)
		const vm = new Com()
		vm.$store = store
		vm.$mount()
		// console.log('🚀 ~ file: index.js ~ line 12 ~ install ~ vm', vm)
		document.body.appendChild(vm.$el)
		Vue.prototype.$jkDplayer = vm
	}
}
