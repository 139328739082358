<template>
  <div class="page-content page-content-2">
    <div class="left">
      <div class="block-1">
        <div class="left-title">农村基础党建</div>
        <div class="content">
          <Jtitle :ops="title1" />
          <div class="chart-wrap">
            <div class="chart-left">
              <div class="chart-left-block">
                <div>党组织数量</div>
                <div class="num">
                  <ICountUp ref="countUp" :delay="600" :end-val="2" />
                </div>
              </div>
              <div class="chart-left-block">
                <div>党员数量</div>
                <div class="num">
                  <ICountUp v-if="titlec1.num" ref="countUp" :delay="600" :end-val="titlec1.num" />
                </div>
              </div>
            </div>
            <div id="myChart21" :style="{ width: '100%', height: '100%' }" />
          </div>
          <div class="chart-block1_b">
            <div class="chart-left-block">
              <div>预备党员</div>
              <div class="num">
                <ICountUp ref="countUp" :delay="600" :end-val="1" />
              </div>
            </div>
            <div class="chart-left-block">
              <div>入党积极分子</div>
              <div class="num">
                <ICountUp ref="countUp" :delay="600" :end-val="3" />
              </div>
            </div>
          </div>
        </div>
        <div class="chart-bottom" @click="onCenterShow1">党组织分布<img src="@/assets/img/page2-2.png" /></div>
      </div>
      <div class="block-2">
        <div class="left-title">农村生态环境</div>
        <div class="content">
          <Jtitle :ops="title2" />
          <div class="item-wrap">
            <div class="item" v-for="(item, index) in item2" :key="index" @click="onShowCenter2(index)">
              <div class="item-name">
                {{ item.name }}
              </div>
              <div class="item-num">
                <ICountUp
                  ref="countUp"
                  :delay="600"
                  :end-val="item.num"
                  :options="{
                    decimalPlaces: item.decimals || 0
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block-3">
        <div class="item-wrap">
          <div class="item" :style="[{ cursor: item.onClick ? 'pointer' : '' }]" v-for="(item, index) in item3" @click="item.onClick ? item.onClick() : ''" :key="index">
            {{ item.name }}<img :src="item.icon" />
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        class="center"
        :class="{
          center2: centerShow2,
          center3: centerShow3
        }"
      >
        <template v-if="centerShow1">
          <div class="center-block">
            <img class="clsoe" src="@/assets/img/close.png" alt="" @click="onClose" />
            <div class="center-title">农村基础党建</div>
            <div class="center-item-wrap">
              <div class="center-tab-wrap">
                <div class="center-tab" :class="tabActive === item.id ? 'active' : ''" v-for="(item, index) in centerTab" :key="index" />
              </div>
              <div class="tab-content">
                <div class="tab-content-left">
                  <div class="left-block-1">
                    <Jtitle :ops="titlec1" />
                    <div class="item-wrap">
                      <div class="item" v-for="(item, index) in itemCenter1" :key="index">
                        <div class="item-title">
                          <span class="name">{{ item.name }}</span>
                          <span
                            :style="{
                              color: item.color,
                              fontSize: '22px',
                              fontWeight: 'bold'
                            }"
                          >
                            {{ item.num }}</span
                          >
                        </div>
                        <div
                          class="item-chart"
                          :style="{
                            backgroundImage: `url(${item.img})`
                          }"
                        >
                          <div class="item-percent">
                            {{ Percentage(item.num, titlec1.num) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="left-block-2">
                    <Jtitle :ops="titlec2" />
                    <div class="chart-wrap">
                      <div
                        id="myChartcenter2"
                        :style="{
                          width: '100%',
                          height: '100%'
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="tab-content-right">
                  <div class="right-block-1">
                    <Jtitle :ops="titlec3" />
                    <div class="chart-wrap">
                      <div
                        id="myChartcenter3"
                        :style="{
                          width: '100%',
                          height: '100%'
                        }"
                      />
                    </div>
                  </div>
                  <div class="right-block-2">
                    <Jtitle :ops="titlec4" />
                    <div class="content">
                      <div class="table-title-wrap">
                        <div class="table-title-item" v-for="(item, index) in center4Column" :key="index">
                          {{ item.name }}
                        </div>
                      </div>
                      <div class="table-center-wrap">
                        <swiperTable :block-data="center4Data" :block-column="center4Column" :cur="4" :total="center4Data.length" />
                        <!-- <div class="table-row" v-for="(row,index) in center4Data" :key="index" style="height:20%;align-items: center;">
                          <div v-show-tips v-for="(item,key) in center4Column" :key="key" class="item-cell">
                            {{ row[item.key] }}
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="centerShow2">
          <div class="center-block">
            <img class="clsoe" src="@/assets/img/close.png" alt="" @click="onClose2" />
            <div class="center-title">农村生态环境</div>
            <div class="center-item-wrap">
              <div class="center-tab-wrap">
                <div class="center-tab" :class="center2Active === index ? 'active' : ''" v-for="(item, index) in item2" :key="index" @click="onTab2Change(index)">
                  {{ item.name }}
                </div>
              </div>
              <div class="tab-content" v-for="(a, i) in centerTab2Item" :key="i" v-show="center2Active === i">
                <template>
                  <swiper class="slider-wrap" ref="mySwiper2" :options="swiperOptions">
                    <swiper-slide class="slider-item" v-for="(item, index) in centerTab2Item[i].list" :key="index" v-viewer>
                      <div class="slider-item-inner">
                        <div class="item-title">{{ indexArray[index] }}、{{ item.name }}</div>
                        <div class="item-left">
                          <template v-if="item.des">
                            <img v-if="item.img" align="right" width="200" hspace="7" vspace="6" :src="require('../../../../assets/img/techan/' + item.img[0])" alt="" />
                            {{ item.des }}
                          </template>
                          <div class="img-wrap" v-else>
                            <img v-for="(img, imgi) in item.img" :key="imgi" width="200" hspace="7" vspace="6" :src="require('../../../../assets/img/techan/' + img)" alt="" />
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-button-prev" />
                  <div class="swiper-button-next" />
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-if="centerShow3">
          <jkCenter :show.sync="centerShow3" />
        </template>
        <!-- <transition name="fade"> -->
        <div v-if="centerShow4" style="position: relative; width: 80%">
          <img class="clsoe" src="@/assets/img/close.png" width="50" alt="" @click="onClose4" style="position: absolute; right: 0; top: -40px; cursor: pointer" />
          <GbList />
        </div>
        <template v-if="centerShow5">
          <bjList :show.sync="centerShow5" />
        </template>
        <div v-if="centerShow6" style="position: relative; width: 80%">
          <img class="clsoe" src="@/assets/img/close.png" alt="" style="position: absolute; right: -40px; top: -40px; height: 30px; cursor: pointer" @click="onClose6" />

          <div
            class="centerchart6"
            :style="{
              width: '100%',
              height: '100%',
              padding: '20px'
            }"
          >
            <div class="main">
              <div style="display: flex; justify-content: space-between">
                <span>民宿入住量统计</span>
                <span>共{{ center6Total }}人</span>
              </div>
              <div id="myChartCenter6" :style="{ width: '100%', height: '230px' }"></div>
              <div style="display: flex; justify-content: space-between; margin-top: 10px">
                <span>民宿入住率</span>
                <span>共{{ roomNum }}间</span>
              </div>
              <div id="myChartCenter6-2" :style="{ width: '100%', height: '230px' }"></div>
            </div>
            <div class="aside">
              <div class="myChartCenter6-2" :style="{ width: '100%' }">
                <div class="" v-for="(check, index) in checkAside" :key="index">
                  <!-- <div class="item-title">
                  {{ check.title }}
                </div> -->
                  <Jtitle
                    :ops="{
                      title: check.title,
                      color1: 'rgba(5, 255, 242, 1)',
                      color2: 'rgba(5, 255, 242, .4)'
                    }"
                  />
                  <div class="item-wrap">
                    <div class="item" v-for="(item, i) in check.val" :key="i">
                      <div class="item-name">
                        {{ item.name }}
                      </div>
                      <div class="item-num">
                        <ICountUp
                          ref="countUp"
                          :delay="600"
                          :end-val="item.num"
                          :options="{
                            decimalPlaces: item.decimals || 0
                          }"
                        />
                        <div class="unit">
                          {{ item.unit }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="centerShow7" style="position: relative; width: 40%">
          <img class="clsoe" src="@/assets/img/close.png" alt="" style="position: absolute; right: -40px; top: -40px; height: 30px; cursor: pointer" @click="onClose7" />
          <img src="@/assets/img/ljfl.jpg" style="width: 100%; height: auto" v-viewer alt="" />
        </div>
        <wushui v-if="centerShow8" @close="closeCenterChart8" />
        <caiwu v-if="centerShow9" @close="closeCenterChart9" />
        <gengdi v-if="centerShow10" @close="closeCenterChart10" />
        <jianjie v-if="centerShow11" @close="closeCenterChart11" />
        <fengxian v-if="centerShow12" @close="closeCenterChart12" />
        <!-- </transition> -->
      </div>
    </transition>
    <div class="right">
      <div class="block-4">
        <div class="right-title">农村民意反馈</div>
        <div class="content">
          <Jtitle :ops="title4" />
          <div class="content-inner">
            <div class="content-left">
              <div class="item" v-for="(item, index) in item4" :key="index">
                <div class="name">
                  {{ item.name }}
                </div>
                <div>
                  <span class="num">
                    <ICountUp
                      ref="countUp"
                      :delay="600"
                      :end-val="item.num"
                      :options="{
                        decimalPlaces: item.decimals || 0
                      }"
                    />
                  </span>
                  <span style="color: #ffb335">{{ item.unit }}</span>
                </div>
              </div>
            </div>
            <div class="content-right">
              <div id="myChart4" :style="{ width: '100%', height: '100%' }" />
            </div>
          </div>
        </div>
      </div>
      <div class="block-5">
        <div class="item-wrap">
          <div class="item" :style="[{ cursor: item.onClick ? 'pointer' : '' }]" @click="item.onClick ? item.onClick() : ''" v-for="(item, index) in item5" :key="index">
            <img :src="item.icon" />{{ item.name }}
          </div>
        </div>
      </div>
      <div class="block-6">
        <div class="right-title">农村集体经济</div>
        <div class="content">
          <Jtitle :ops="title6" />
          <div class="item-wrap">
            <div class="item" v-for="(item, index) in item6" :key="index">
              <div class="item-name">
                {{ item.name }}
              </div>
              <div class="item-num">
                <ICountUp
                  ref="countUp"
                  :delay="600"
                  :end-val="item.num"
                  :options="{
                    decimalPlaces: item.decimals || 0
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block-7">
        <div class="item-wrap">
          <div class="item" :style="[{ cursor: item.onClick ? 'pointer' : '' }]" @click="item.onClick ? item.onClick() : ''" v-for="(item, index) in item7" :key="index">
            <img :src="item.icon" />{{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swiperTable from '@/components/swiperTable'
import GbList from '@/components/gb/list'
const axisLineColor = 'rgba(97, 255, 251, .3)'
import Jtitle from '@/components/title'
import bjList from '@/components/bjList'
let data1 = []
import { Percentage } from '@/utils/util'
import jkCenter from '@/components/jkCenterBlock'
import wushui from './components/wushui.vue'
import caiwu from './components/caiwu.vue'
import gengdi from './components/gengdi.vue'
import jianjie from './components/jianjie.vue'
import fengxian from './components/fengxian.vue'
// import JsonData from "/static/json/index.json";
// console.log("🚀 ~ file: page2.vue ~ line 408 ~ JsonData", JsonData);
// import axios from "axios";
// axios.get("/static/json/index.json").then((res) => {
//   console.log(res);
// });
import { getproposal, getPartyCount, getPartystandingCount, getPartyAgeCount, getPartyEduCount, getPartyActivity } from '@/request/api'
export default {
  components: {
    Jtitle,
    jkCenter,
    swiperTable,
    GbList,
    bjList,
    wushui,
    caiwu,
    gengdi,
    jianjie,
    fengxian
  },
  computed: {
    roomNum() {
      return this.$store.state.JsonData.roomNum
    }
  },
  data() {
    return {
      checkAside: this.$store.state.JsonData.checkAside,
      centerShow3: false,
      centerShow5: false,
      centerShow6: false,
      centerShow7: false,
      centerShow8: false,
      centerShow9: false,
      centerShow10: false,
      centerShow11: false,
      centerShow12: false,
      swiperOptions: {
        initialSlide: 0,
        autoplay: false, //可选选项，自动滑动
        loop: true,
        delay: 5000,
        observeParents: true,
        observer: true,
        observeSlideChildren: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      centerShow1: false,
      centerShow2: false,
      center6Total: 0,
      centerOption6: {
        title: {
          //图表的标题，注释掉就没有名称了
          show: false
        },
        // color: ['#FFB335','#14B1FF'],
        tooltip: {
          backgroundColor: 'rgba(255,255,255,.8)',
          trigger: 'axis', //触发类型；轴触发，axis则鼠标hover到一条柱状图显示全部数据，item则鼠标hover到折线点显示相应数据，
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            var dotHtml = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:rgba(97, 255, 251, 1)"></span>'
            return dotHtml + params[0].name + '：' + params[0].value + ''
          }
        },
        legend: {
          show: false,
          data: ['人数'], //这里设置柱状图上面的方块，名称跟series里的name保持一致
          align: 'right', //上面方块靠右还是居中的设置.不设置则居中
          right: 10,
          itemHeight: 14,
          itemWidth: 14,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          top: '10%',
          left: '3%', //柱状图距离左边的距离，也可以用像素px
          right: '4%', //柱状图距离右边的距离，也可以用像素px
          bottom: '2%', //网格图（柱状图、折线图、气泡图等）离底部的距离，也可以用像素比如10px
          containLabel: true //grid 区域是否包含坐标轴的刻度标签。false可能溢出，默认为false
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              //刻度
              show: false
            },
            axisLabel: {
              fontSize: 10,
              color: '#fff',
              interval: 0,
              rotate: 60
            },
            axisLine: {
              lineStyle: {
                color: axisLineColor
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              fontSize: 10,
              color: '#fff'
            },
            splitLine: {
              lineStyle: {
                color: axisLineColor
              }
            }
          }
        ],
        series: [
          {
            //柱状图-柱子1
            name: '入住人数', //需要跟legend配置项对应
            type: 'bar',
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: 'rgba(225,179,53,0.2)'
                },
                {
                  offset: 1,
                  color: 'rgba(225,179,53,1)'
                }
              ]),
              barBorderRadius: [10, 10, 0, 0] //控制柱状图的圆角显示弧度，（顺时针左上，右上，右下，左下）
            },
            barGap: '50%',
            barWidth: '14%', //barWidth设置每根柱状图的宽度
            data: [10, 52, 200, 334, 390, 330, 220, 34, 67, 12, 123, 255]
          }
        ]
      },
      center2Active: 0,
      tabActive: 1,
      titlec1: {
        title: '党员数量',
        color1: 'rgba(5, 255, 242, 1)',
        color2: 'rgba(5, 255, 242, .4)',
        num: 0
      },
      titlec2: {
        title: '党员年龄分布',
        color1: 'rgba(5, 255, 242, 1)',
        color2: 'rgba(5, 255, 242, .4)'
      },
      titlec3: {
        title: '党员学历分布',
        color1: 'rgba(5, 255, 242, 1)',
        color2: 'rgba(5, 255, 242, .4)'
      },
      titlec4: {
        title: '党建活动',
        color1: 'rgba(5, 255, 242, 1)',
        color2: 'rgba(5, 255, 242, .4)'
      },
      title1: {
        title: '关键指标展示',
        color1: 'rgba(127, 118, 255, 1)',
        color2: 'rgba(127, 118, 255, .4)'
      },
      title2: {
        title: '关键指标展示',
        color1: 'rgba(5, 255, 242, 1)',
        color2: 'rgba(5, 255, 242, .4)'
      },
      title6: {
        title: '关键指标展示',
        color1: 'rgba(0, 255, 240, 1)',
        color2: 'rgba(0, 255, 240, .4)'
      },
      title4: {
        title: '关键指标展示',
        color1: 'rgba(255, 179, 53, 1)',
        color2: 'rgba(255, 179, 53, .4)'
      },
      centerTab: [
        { name: '某某党组织', id: 1 },
        { name: '某某党组织', id: 2 },
        { name: '某某党组织', id: 3 }
      ],
      item2: [
        { name: '红色革命文化', num: 3 },
        { name: '乡村振兴产业', num: 4 },
        { name: '历史文化建筑', num: 2 },
        { name: '特色景点', num: 3 }
      ],
      indexArray: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      centerTab2Item: [
        {
          name: '红色革命文化',
          list: [
            {
              name: '广信区华坛山镇樟涧人民起义暴动',
              des: '樟涧村红色革命根据地党史资料记载，樟涧人民在1926年-1930年反饥饿及首次起来革命进行武装暴动，都是当时方志敏所在领导领导的革命根据地。为积极响应，习近平总书记多次作重要讲话，要把红色资源利用好，把红色传统发扬好，要把红色基因传承好，弘扬革命传统文化基因，是全党全社会的共同责任。'
            },
            {
              name: '樟涧人民首次举义旗暴动旧址、广信区樟涧革命烈士纪念馆(旧址)、樟涧村苏维埃政府旧址',
              des: '1930年5月17日，东北红军500多余人，游民杨金江村去攻打玉山，沿途群众欢欣鼓舞，纷纷要求在自己的家乡建立起人民政权，当天，樟涧人民在上饶二区苏维埃主席王财金同志领导下。组织召开了700余人的群众大会。全体成立了苏维埃政府，选举周忠林、周金星、周金桂、周善才、姜子云等五人为代表。第二天上午，又在江村召开了1000余人的群众大会，选举姜正南、姜子正、姜玉漳、姜喜梅等十人为代表，成立了苏维埃政府；第三天，在车边召开了700等人群众大会，正当大家高唱国际歌时，包山底(离车边两公里)传来了郑坊靖卫团的枪声，靖卫团进车边、姜村后，烧伤抢掠、四处扰民，樟涧村预备连16人，奋起反抗击毙的匪徒30余人，后因周村靖卫团进攻团增援，预备连寡不敌众而撤离，樟涧村逐敌侵占，当天，27名革命群众被杀害，烈士们的鲜血染红了苏维埃革命的旗帜。'
            },
            {
              name: '樟涧人民反饥饿的斗争',
              des: '国民革命运动的发展，进一步促进了上饶县人民反帝反封建、反压迫的斗争。1926年正值青黄不接时，多数农家已断粮，穷苦农民进入一年当中最煎熬的时候，樟涧村的穷苦农民三五成群到望江的祝师村去买稻谷度饥荒，大地主汪日春想尽办法来剥削和穷人，掠夺钱财，一方面故意不卖稻谷，而是将稻谷加工成糙米出售，利润高而又可得现钱；另一方面暗中勾结和教唆当地流氓，在路上设卡，以阻止米谷出境为由没收糙米。这对缺粮的农民来说，是一个致命的打击。 财去粮空消息传来，樟涧村人民惊恐不已，反抗的怒火在胸中燃烧。贫农周落山主动走上反饥饿的斗争前列，一面号召本村群众必须赶快团结起来，用武装斗争打击地主恶霸。另一方面，写信给周村、小车村、姜村各地的学生，要他们发动当地群众声援，参加反饥饿斗争。农历3月11日早，周明山、周志强二人发令出发，各地700余人群众浩浩荡荡冲进祝狮村，大地主汪日春、汪草包等闻风而逃。愤怒的群众打开地主大门，开仓济穷。这天人人满载而归，只挑稻谷600余弹。樟涧人民用武装斗争战胜了饥荒。'
            }
          ]
        },
        {
          name: '乡村振兴产业',
          list: [
            {
              name: '光伏，点亮群众致富新希望',
              img: ['11.png'],
              des: '为在扶贫攻坚中处理好“输血”与“造血”的关系，彭家坞村中积极探索行之有效的新办法，2016年3月，投资250万元率先建成全县首家光伏扶贫电站，全村60户贫困户全覆盖参与。主要采取扶持贷款+扶贫资金组成的模式。村委会为60户贫困户协调农信社每户贷款1万元，争取扶贫政策性资金1.5万元。通过一年的运行，目前发电量达到34万度，每个贫困户在光伏发电项目上可年增收3000元左右，保障了贫困户长期收入来源，为脱贫奠定了坚实的基础。'
            },
            {
              name: '菌棒，助推绿色发展新路径',
              img: ['12.png'],
              des: '彭家坞村扶贫产业与绿色经济的有效结合，2015年建成食用菌种植基地。采取合作社+贫困户的模式，2015年以种植黑木耳为主，参与贫困户22户，2016年以种植香菇为主，参与贫困户16户，该基地的建成，带动了贫困户增收致富的同时，每年还可就地解决贫困户劳力就业，是一种绿色低碳经济，是农村循环经济的重要支柱产业。'
            },
            {
              name: '毛竹，打造传统产业新生力',
              img: ['13.png'],
              des: '在“十三五”脱贫攻坚中，彭家坞村积极探索传统产业和扶贫产业的有效衔接，充分利用本地毛竹资源优势，2016年10月，投资250万元建成毛竹产业扶贫基地，由富邦毛竹合作社牵头，全村60户贫困户全部参与，以扶贫资金入股，每户年收益1300元左右，达到了贫困户就业、增收的双赢。'
            },
            // {
            //   name: '电商，搭建城乡共享新生活',
            //   img: ['14.png'],
            //   des: '为了发挥“互联网+”在扶贫中的作用，彭家坞村于2016年8月，村里牵头建成电商脱贫村邮乐购站，由贫困户姜献江夫妇经营，解决了贫困户和当地群众的“购”与“销”，贫困户家中的土特产可以通过该站点销往全国各地，也可以购买到全国各地商品，让贫困户也享受到互联网带来的收益和便捷。'
            // },
            {
              name: '民宿，共同致富样板区',
              img: ['19.jpg'],
              des: '我们充分依托乡村振兴示范带，推行“望仙游、樟涧留”的错位发展理念，发挥望仙谷景区“聚人气、引客流”的旅游效应。围绕乡村旅游，扎实推进“民宿、美丽庭院、现代农场”的打造建设，促进农文旅融合发展，增加农业产业附加值。'
            }
          ]
        },
        {
          name: '历史文化建筑',
          list: [
            {
              img: ['15.jpg', '15-1.jpg'],
              name: '祈雨广场',
              des: '这块地方是原来34户村民的宅基地，我们村集体征用过来，建设面积达2000㎡。对面山上有个瀑布，为龙潭相传，樟涧龙潭与上饶龙潭水脉相通，自唐朝以来，每逢久旱未雨，周边乡镇就会祭祀，求龙赐雨。有传明万历年间，信州旱灾严重，信州知府体恤民情，曾亲自来此祈雨，所以祈雨文化由此产生。现在祈雨广场的建立也可以方便周围群众娱乐场所使用，夜晚，还可以在这举办篝火晚会等一系列特色的娱乐活动，点亮“夜”生活。'
            },
            { img: ['17.png', '17.jpg'], name: '周氏宗祠' }
          ]
        },
        {
          name: '特色景点',
          list: [
            { name: '龙潭', img: ['16-2.jpg', '16-4.jpg'] },
            { name: '龙潭漫步道', img: ['18.png'] },
            {
              name: '祈雨广场',
              img: ['15-1.jpg', '15.jpg'],
              des: '这块地方是原来34户村民的宅基地，我们村集体征用过来，建设面积达2000㎡。对面山上有个瀑布，为龙潭相传，樟涧龙潭与上饶龙潭水脉相通，自唐朝以来，每逢久旱未雨，周边乡镇就会祭祀，求龙赐雨。有传明万历年间，信州旱灾严重，信州知府体恤民情，曾亲自来此祈雨，所以祈雨文化由此产生。现在祈雨广场的建立也可以方便周围群众娱乐场所使用，夜晚，还可以在这举办篝火晚会等一系列特色的娱乐活动，点亮“夜”生活。'
            },
            { name: '樟涧桥', img: ['16.jpg', '16-3.jpg'] }
          ]
        }
      ],
      option1: {
        tooltip: {},
        color: ['rgba(234, 104, 162, 1)', 'rgba(97, 255, 251, 1)', 'rgba(0, 160, 233, 1)', 'rgba(236, 186, 114, 1)', 'rgba(71, 189, 145, 1)', 'rgba(215, 94, 94, 1)'],
        legend: {
          top: 'middle',
          right: 10,
          orient: 'vertical',
          itemWidth: 8,
          itemHeight: 8,
          borderRadius: 50,
          textStyle: {
            color: '#fff',
            borderWidth: 0,
            rich: {
              a: {
                fontSize: 14,
                width: 60
              },
              b: {
                fontSize: 16,
                align: 'right',
                padding: [0, 10, 0, 15]
              }
            }
          },
          formatter: (name) => {
            const data = data1
            let total = 0
            let target
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value
              if (data[i].name == name) {
                target = data[i].value
              }
            }
            var arr = ['{a|' + name + '}{b|' + ((target / total) * 100).toFixed(2) + '%}']
            return arr.join('\n')
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '65%'], // 中间白色圆周面积
            center: ['25%', '50%'], // 左右距离
            avoidLabelOverlap: false,
            tooltip: {
              trigger: 'item',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              formatter: function (params) {
                return params.name + '：' + params.value + '人<br>占比：' + params.percent.toFixed(2) + '%'
              }
            },
            label: {
              show: true,
              position: 'center',
              formatter: '党龄分布',
              fontSize: 16,
              color: '#fff'
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
      option4: {
        colors: ['#8C1D17', '#00B3B0', '#43DEFF', '#47BD91', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
        noData: '暂无数据',
        legend: {
          align: 'right',
          layout: 'vertical',
          itemMarginBottom: 8,
          verticalAlign: 'middle',
          itemStyle: {
            color: '#fff'
          },
          labelFormat: '{name}: {percentage:.1f}%',
          navigation: {
            activeColor: '#fff',
            inactiveColor: 'rgba(255,255,255,.8)',
            arrowSize: 8,
            style: {
              color: '#fff'
            }
          }
        },
        chart: {
          type: 'pie',
          plotBackgroundColor: null,
          backgroundColor: 'transparent',
          plotBorderWidth: null,
          plotShadow: false,
          options3d: {
            enabled: true,
            alpha: 60,
            beta: 0
          }
          // spacingTop: 40,
          // margin: [40, 0, 15, 40]
        },
        title: {
          text: '事件类型分类',
          align: 'left',
          // floating: true,
          style: {
            color: '#FFB335'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          textStyle: {
            color: '#ffffff'
          },
          useHTML: true,
          formatter: function () {
            return `<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${this.point.color}'></span>${this.point.name}:${
              this.point.y
            }件</br>占比：${this.point.percentage.toFixed(1)}%`
          }
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 25,
            dataLabels: {
              enabled: false //指示器
            },
            showInLegend: true
          }
        },
        series: [
          {
            center: [50, 60],
            size: '80%',
            type: 'pie',
            colorByPoint: true,
            avoidLabelOverlap: true,
            data: []
          }
        ]
      },
      optionCenter2: {
        colors: ['#db2a23', '#3aa7e3', '#e07b2b', '#e2b839', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
        legend: {
          align: 'bottom',
          layout: 'vertical',
          itemMarginBottom: 6,
          symbolPadding: 10,
          symbolWidth: 5,
          itemStyle: {
            color: '#fff'
          },
          useHTML: true,
          labelFormat:
            '<div style="width:60px;display:inline-block">{name}</div><div style="width:50px;display:inline-block">{percentage:.1f}%</div> | <div style="margin-left:12px;display:inline-block">{y}人</div>',
          // verticalAlign:'bottom',
          x: 15
        },
        chart: {
          type: 'pie',
          plotBackgroundColor: null,
          backgroundColor: 'transparent',
          plotBorderWidth: null,
          plotShadow: false,
          options3d: {
            enabled: true,
            alpha: 70,
            beta: 0
          }
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{point.x}: <b>{point.percentage:.1f}%</b> '
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 20,
            dataLabels: {
              enabled: false,
              format: '{point.name}'
            },
            showInLegend: true
            // slicedOffset:20
          }
        },
        series: [
          {
            center: [100, 15],
            size: '70%',
            type: 'pie',
            colorByPoint: true,
            data: [
              ['35岁以下', 7],
              ['35-60岁', 26],
              ['60岁以上', 24]
            ]
          }
        ]
      },
      myChart24: null,
      myChart21: null,
      myChartcenter2: null,
      myChartcenter3: null,
      optionCenter3: {
        title: {
          //图表的标题，注释掉就没有名称了
          show: false
        },
        tooltip: {
          trigger: 'axis', //触发类型；轴触发，axis则鼠标hover到一条柱状图显示全部数据，item则鼠标hover到折线点显示相应数据，
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: '3%', //柱状图距离左边的距离，也可以用像素px
          right: '4%', //柱状图距离右边的距离，也可以用像素px
          bottom: '20px', //网格图（柱状图、折线图、气泡图等）离底部的距离，也可以用像素比如10px
          top: '20px',
          containLabel: true //grid 区域是否包含坐标轴的刻度标签。false可能溢出，默认为false
        },

        xAxis: [
          {
            type: 'category',
            // data: ['初中以下','高中','大专以上'],
            axisTick: {
              //刻度
              show: false
            },
            axisLabel: {
              fontSize: 10,
              color: '#fff'
            },
            axisLine: {
              lineStyle: {
                color: axisLineColor
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              fontSize: 10,
              color: '#fff'
            },
            splitLine: {
              lineStyle: {
                color: axisLineColor
              }
            }
          }
        ],
        series: [
          {
            //柱状图-柱子1
            name: '人数', //需要跟legend配置项对应
            type: 'bar',
            barWidth: 8, //柱图宽度
            label: {
              show: false
            },
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 0, 0],
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(255, 197, 39, 1)'
                  }, //柱图渐变色
                  // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                  {
                    offset: 1,
                    color: 'rgba(97, 255, 251, 0.1)'
                  } //柱图渐变色
                ])
              }
            }
            // data:[20,19,8]
          }
        ]
      },
      item3: [
        // {
        //   name: "传统文化村落",
        //   icon: require("../../../../assets/img/page2-3.png"),
        // },
        {
          // name: "新时代美丽乡村",
          name: '民宿入住统计',
          icon: require('../../../../assets/img/page2-4.png'),
          onClick: this.openCenterChart6
        },
        // {
        //   name: "重要农业文化遗产",
        //   icon: require("../../../../assets/img/page2-5.png"),
        // },
        {
          name: '农村生活污水处理措施',
          icon: require('../../../../assets/img/page2-6.png'),
          onClick: this.openCenterChart8
        },
        {
          name: '农村大广播',
          icon: require('../../../../assets/img/page2-7.png'),
          onClick: this.openGuangBo
        },
        {
          name: '农村垃圾分类',
          icon: require('../../../../assets/img/page2-8.png'),
          onClick: this.openCenter7
        }
      ],
      centerShow4: false,
      item4: [
        { name: '本周相关事件', num: 0 },
        { name: '本周处理率', num: 0, unit: '%' }
      ],
      item6: [
        { name: '农村人口', num: 2058 },
        { name: '劳动力人口', num: 742 },
        { name: '基础设施项目', num: 12 },
        { name: '扶贫项目', num: 16 }
      ],
      item7: [
        {
          name: '乡村基本情况',
          icon: require('../../../../assets/img/page2-12.png'),
          onClick: this.openCenterChart11
        },
        {
          name: '乡村三务公开',
          icon: require('../../../../assets/img/page2-13.png'),
          onClick: this.openCenterChart9
        },
        {
          name: '耕地承包情况',
          icon: require('../../../../assets/img/page2-14.png'),
          onClick: this.openCenterChart10
        },
        {
          name: '疫情中高风险地区提示',
          icon: require('../../../../assets/img/page2-15.png'),
          onClick: this.openCenterChart12
        }
      ],
      item5: [
        {
          name: '公共视频分布',
          icon: require('../../../../assets/img/page2-9.png'),
          onClick: this.openjiankong
        }
        // {
        //   name: "社会矛盾纠纷调解中心",
        //   icon: require("../../../../assets/img/page2-10.png"),
        // },
        // {
        //   name: "村域人流趋势",
        //   icon: require("../../../../assets/img/page2-11.png"),
        // },
      ],
      itemCenter1: [
        {
          name: '男',
          num: 0,
          img: require('../../../../assets/img/page2center1.png'),
          color: 'rgba(0, 160, 233, 1)'
        },
        {
          name: '女',
          num: 0,
          img: require('../../../../assets/img/page2center2.png'),
          color: 'rgba(234, 104, 162, 1)'
        }
      ],
      center4Column: [
        { name: '活动类型', key: 'category' },
        { name: '活动名称', key: 'title' },
        { name: '活动时间', key: 'starttime' }
      ],
      center4Data: []
    }
  },
  created() {
    // this.$store.dispatch("getFile", "dang/党风廉政建设.docx").then((res) => {
    //   console.log(
    //     "🚀 ~ file: page2.vue ~ line 1127 ~ this.$store.dispatch ~ res",
    //     res
    //   );
    //   let blob = new Blob([res], { type: "application/json" });
    //   console.log(
    //     "🚀 ~ file: page2.vue ~ line 1128 ~ this.$store.dispatch ~ blob",
    //     blob
    //   );
    //   mammoth
    //     .convertToHtml({ arrayBuffer: new Uint8Array(blob) })
    //     .then((displayResult) => {
    //       console.log(displayResult);
    //     })
    //     .done();
    // });

    setTimeout(() => {
      Promise.resolve().then(() => {
        this.initMyChart1()
        this.initMyChart4()
        // this.initCenterChart6();
      })
    }, 600)
    getPartyCount().then((res) => {
      this.titlec1.num = 54 || res.data.organ_count
      this.itemCenter1[0].num = 47 || res.data.male_party_member_count
      this.itemCenter1[1].num = 7 || res.data.female_party_member_count
    })
    getPartyAgeCount().then((res) => {
      const data = []
      for (const key in res.data) {
        data.push([key, res.data[key]])
      }
      // this.optionCenter2.series[0].data = data
    })
    getPartyEduCount().then((res) => {
      this.optionCenter3.series[0].data = Object.values(res.data).length ? [30, 19, 8] : Object.values(res.data)
      this.optionCenter3.xAxis[0].data = Object.keys(res.data).length ? ['初中以下', '高中', '大专以上'] : Object.keys(res.data)
    })
    getPartyActivity().then((res) => {
      res.data.forEach((item) => {
        item.starttime = this.$moment(item.starttime * 1000).format('YYYY/MM/DD')
      })
      this.center4Data = res.data
    })
  },
  watch: {
    centerShow1(val) {
      if (val) {
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow2 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow7 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow11 = false
        this.centerShow12 = false
        Promise.resolve().then(() => {
          this.initMyChartcenter2()
          this.initMyChartcenter3()
        })
      }
    },
    centerShow2(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow7 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow11 = false
        this.centerShow12 = false
      }
    },
    centerShow3(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow4 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow7 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow11 = false
        this.centerShow12 = false
      }
    },
    centerShow4(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow3 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow7 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow11 = false
        this.centerShow12 = false
      }
    },
    centerShow5(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow7 = false
        this.centerShow6 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow11 = false
        this.centerShow12 = false
      }
    },
    centerShow6(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow5 = false
        this.centerShow7 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow11 = false
        this.centerShow12 = false
      }
    },
    centerShow7(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow11 = false
        this.centerShow12 = false
      }
    },
    centerShow8(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow7 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow11 = false
        this.centerShow12 = false
      }
    },
    centerShow9(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow7 = false
        this.centerShow8 = false
        this.centerShow10 = false
        this.centerShow11 = false
        this.centerShow12 = false
      }
    },
    centerShow10(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow7 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow11 = false
        this.centerShow12 = false
      }
    },
    centerShow11(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow7 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow12 = false
      }
    },
    centerShow12(val) {
      if (val) {
        this.centerShow1 = false
        this.centerShow2 = false
        this.centerShow3 = false
        this.centerShow4 = false
        this.centerShow5 = false
        this.centerShow6 = false
        this.centerShow7 = false
        this.centerShow8 = false
        this.centerShow9 = false
        this.centerShow10 = false
        this.centerShow11 = false
      }
    }
  },
  methods: {
    sum(arr) {
      return arr.reduce(function (prev, curr, idx, arr) {
        return prev + curr
      })
    },

    initCenterChart6() {
      const myChartdom = document.getElementById('myChartCenter6')
      const myChart = this.$echarts.init(myChartdom)
      const option = JSON.parse(JSON.stringify(this.centerOption6))
      let arr = this.$store.state.JsonData.checkin
      let arr2 = this.$store.state.JsonData.checkinRate
      this.checkAside = this.$store.state.JsonData.checkAside
      this.center6Total = this.sum(arr)
      option.series[0].data = arr
      option.xAxis[0].data = this.$store.state.JsonData.month
      myChart.setOption(option)
      const myChartdom2 = document.getElementById('myChartCenter6-2')
      const myChart2 = this.$echarts.init(myChartdom2)
      const option2 = JSON.parse(JSON.stringify(this.centerOption6))
      option2.series[0].type = 'line'
      option2.series[0].name = '月入住率'
      option2.series[0].data = arr2
      option2.xAxis[0].data = this.$store.state.JsonData.month
      myChart2.setOption(option2)
    },
    openCenterChart6() {
      this.centerShow6 = true
      setTimeout(() => {
        this.initCenterChart6()
      })
    },
    openCenterChart8() {
      this.centerShow8 = true
    },
    openCenterChart9() {
      this.centerShow9 = true
    },
    openCenterChart10() {
      this.centerShow10 = true
    },
    openCenterChart11() {
      this.centerShow11 = true
    },
    closeCenterChart8() {
      this.centerShow8 = false
    },
    closeCenterChart9() {
      this.centerShow9 = false
    },
    closeCenterChart10() {
      this.centerShow10 = false
    },
    closeCenterChart11() {
      this.centerShow11 = false
    },
    openCenterChart12() {
      this.centerShow12 = true
    },
    closeCenterChart12() {
      this.centerShow12 = false
    },
    openCenter7() {
      this.centerShow7 = true
    },
    openjiankong() {
      this.centerShow3 = true
    },
    openGuangBo() {
      this.centerShow4 = true
    },
    onTab2Change(index) {
      this.center2Active = index
      this.$nextTick(() => {
        this.$refs.mySwiper2[index].swiper.slideTo(1)
      })
    },
    onCenterShow1() {
      this.centerShow1 = true
    },
    onShowCenter2(index) {
      this.centerShow2 = true
      this.center2Active = index
      this.$nextTick(() => {
        this.$refs.mySwiper2[index].swiper.slideTo(1)
      })
    },
    onClose7() {
      this.centerShow7 = false
    },
    onClose6() {
      this.centerShow6 = false
    },
    onClose4() {
      this.centerShow4 = false
    },
    onClose() {
      this.centerShow1 = false
    },
    onClose2() {
      this.centerShow2 = false
    },
    Percentage,
    initMyChart1() {
      const myChart21 = document.getElementById('myChart21')
      // data1 = []
      data1 = this.$store.state.JsonData.ages
      this.option1.series[0].data = data1
      this.myChart21 = this.$echarts.init(myChart21)
      this.myChart21.setOption(this.option1)
      getPartystandingCount().then((res) => {
        // console.log(
        //   "🚀 ~ file: page2.vue ~ line 819 ~ getPartystandingCount ~ res",
        //   res
        // );
        // for (const key in res.data) {
        // 	data1.push({name:key,value: res.data[key]})
        // }
        // this.option1.series[0].data = data1
        // [6,4,23,22]
        // this.option1.series[0].data = data1
        // this.myChart21 = this.$echarts.init(myChart21)
        // this.myChart21.setOption(this.option1)
      })
    },
    initMyChart4() {
      const myChart24 = document.getElementById('myChart4')
      getproposal({
        starttime: this.$moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        endtime: this.$moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      }).then((res) => {
        this.item4[0].num = res.data.total
        this.item4[1].num = Percentage(res.data.handle, res.data.total)
        const category = res.data.category
        const data = []
        for (const key in category) {
          data.push([key, category[key]])
          // data.push([key, 1])
        }
        this.option4.series[0].data = data
        this.myChart24 = new Highcharts.Chart(myChart24, this.option4)
      })
    },
    initMyChartcenter2() {
      const myChartcenter2 = document.getElementById('myChartcenter2')
      // option.xAxis[0].data =
      // this.optionCenter.series[0].data= [['35以下',9],['36-45',8],['46-55',13],['55以上',27]]
      this.myChartcenter2 = new Highcharts.Chart(myChartcenter2, this.optionCenter2)
    },
    initMyChartcenter3() {
      const myChartcenter3 = document.getElementById('myChartcenter3')
      this.myChart21 = this.$echarts.init(myChartcenter3)
      this.myChart21.setOption(this.optionCenter3)
    }
  }
}
</script>

<style lang="less" scoped>
@import './page2.less';
</style>
