var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content page-content-4"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"block-1"},[_c('div',{staticClass:"left-title"},[_vm._v("三务公开")]),_c('div',{staticClass:"block-wrap"},[_c('div',{staticClass:"item-wrap"},_vm._l((_vm.item1),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticStyle:{"marginLeft":"5px","color":"rgba(127, 118, 255, 1)","fontWeight":"bold","fontSize":"32px"}},[_c('ICountUp',{ref:"countUp",refInFor:true,attrs:{"delay":600,"endVal":item.num,"options":{
                    decimalPlaces:item.decimals || 0
                  }}})],1),_c('span',{staticStyle:{"color":"rgba(127, 118, 255, 1)"}},[_vm._v(_vm._s(item.unit))])])}),0),_c('div',{staticClass:"tab-wrap"},[_c('div',{staticClass:"tab-item"},_vm._l((_vm.tab1),function(item,index){return _c('div',{key:index,staticClass:"tab-cell",class:item.id === _vm.tab1Active ? 'active' : '',on:{"click":function($event){return _vm.onTabChange(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"table-title-wrap"},_vm._l((_vm.block1Column),function(item,index){return _c('div',{key:index,staticClass:"table-title-item",style:({flex:item.flex || 1})},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"table-center-wrap-slider"},[_c('swiperTable',{ref:"swiperTable",attrs:{"blockData":_vm.block1Data,"blockColumn":_vm.block1Column,"cur":4,"total":_vm.block1Data.length},on:{"onClick":_vm.getarticleDetail}})],1)])])])]),_c('div',{staticClass:"block-2"},[_c('div',{staticClass:"left-title"},[_vm._v("意见反馈")]),_c('div',{staticClass:"block-wrap"},[_c('div',{staticClass:"wrap-item"},_vm._l((_vm.item2),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"marginTop":"4px","color":"rgba(33, 226, 255, 1)","fontSize":"30px","fontWeight":"bold"}},[_c('ICountUp',{ref:"countUp",refInFor:true,attrs:{"delay":600,"endVal":item.num,"options":{
                    decimalPlaces:item.decimals || 0
                  }}})],1)])}),0),_c('div',{staticClass:"chart-wrap"},[(_vm.data2 && _vm.data2.length)?_c('swiper',{ref:"mySwiper",staticClass:"slider-wrap",attrs:{"options":_vm.swiperOptions}},_vm._l((Math.ceil(_vm.data2.length / _vm.cur2)),function(row,rindex){return _c('swiper-slide',{key:rindex,staticClass:"slider-item"},[_vm._l((_vm.data2),function(item,index){return [(index < row * _vm.cur2 && index >= (row-1)*_vm.cur2)?_c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-title"},[(index < 9)?_c('div',{class:'item-title-no item-title-no'+index},[_vm._v("NO.0"+_vm._s(index+1))]):_c('div',{class:'item-title-no item-title-no'+index},[_vm._v("NO."+_vm._s(index+1))]),_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"chart-bar",style:({height: '100%'}),attrs:{"id":'myChart42-'+index}}),_c('div',{staticClass:"item-num"},[_vm._v(_vm._s(item.num))])]):_vm._e()]})],2)}),1):_vm._e()],1)])]),_c('div',{staticClass:"block-3"},[_c('div',{staticClass:"left-title"},[_vm._v("乡村教育")]),_c('div',{staticClass:"block-wrap"},[_c('div',{staticClass:"wrap-item"},_vm._l((_vm.item3),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"marginTop":"4px","color":"rgba(95, 255, 245, 1)","fontSize":"30px","fontWeight":"bold"}},[_c('ICountUp',{ref:"countUp",refInFor:true,attrs:{"delay":600,"endVal":item.num,"options":{
                    decimalPlaces:item.decimals || 0
                  }}}),_vm._v(" "+_vm._s(item.unit))],1)])}),0),_c('div',{staticClass:"chart-wrap"},[_c('div',{style:({width: '100%', height: '100%'}),attrs:{"id":"myChart43"}})])])])]),_c('div',{staticClass:"center",class:{'center1': _vm.centerShow1}},[(_vm.centerShow1)?_c('div',{staticClass:"centerShow1"},[_c('img',{staticClass:"clsoe",attrs:{"src":require("../../../../assets/img/close.png"),"alt":""},on:{"click":_vm.onCenter1Close}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.center1Content.title))]),_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.center1Content.content)}})]):_vm._e()]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"block-4"},[_c('div',{staticClass:"right-title"},[_vm._v("精准扶贫")]),_c('div',{staticClass:"block-wrap"},[_c('div',{staticClass:"wrap-item"},_vm._l((_vm.item4),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"marginTop":"4px","color":"rgba(255, 179, 53, 1)","fontSize":"30px","fontWeight":"bold","marginLeft":"8px"}},[_c('ICountUp',{ref:"countUp",refInFor:true,attrs:{"delay":600,"endVal":item.num,"options":{
                    decimalPlaces:item.decimals || 0
                  }}}),_vm._v(_vm._s(item.unit))],1)])}),0),_c('div',{staticClass:"chart-block"},[_c('div',{staticClass:"chart-wrap"},[_c('div',{style:({width: '100%', height: '100%'}),attrs:{"id":"myChart44"}})]),_c('div',{staticClass:"chart-wrap"},[_c('div',{staticClass:"title"},[_c('div',[_vm._v("村组扶贫人数统计")]),_c('div',[_vm._v("共"),_c('span',{staticStyle:{"fontSize":"26px","color":"rgba(255, 179, 53, 1)","fontWeight":"bold"}},[_vm._v(" "+_vm._s(_vm.num1)+" ")]),_vm._v("人")])]),_c('div',{style:({width: '100%', flex:1}),attrs:{"id":"myChart45"}})]),_c('div',{staticClass:"chart-wrap"},[_c('div',{staticStyle:{"margin":"4px 0"}},[_vm._v("村组脱贫人数统计")]),_vm._l((_vm.data6),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"chart-bar",style:({height: '100%'}),attrs:{"id":'myChart46-'+index}}),_c('div',{staticStyle:{"marginLeft":"8px","marginRight":"8px","fontWeight":"bold"}},[_vm._v(_vm._s(_vm.Percentage(item.num,2058))+"%")])])})],2)])])]),_c('div',{staticClass:"block-7"},[_c('div',{staticClass:"right-title"},[_vm._v("医疗健康")]),_c('div',{staticClass:"block-wrap"},[_c('div',{staticClass:"wrap-item"},_vm._l((_vm.item7),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"marginLeft":"4px","color":"rgba(95, 255, 245, 1)","fontSize":"30px","fontWeight":"bold"}},[_c('ICountUp',{ref:"countUp",refInFor:true,attrs:{"delay":600,"endVal":item.num,"options":{
                    decimalPlaces:item.decimals || 0
                  }}}),_vm._v(_vm._s(item.unit))],1)])}),0),_c('div',{staticStyle:{"marginTop":"4px","marginBottom":"4px"}},[_vm._v("老年人类型")]),_c('div',{staticClass:"chart-wrap"},[_c('div',{style:({width: '100%', height: '100%'}),attrs:{"id":"myChart47"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }