var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.play),expression:"play"}],staticClass:"plugin",style:({
      width: _vm.oPlugin.width + 'px',
      height: _vm.oPlugin.height + 'px',
      top: _vm.oPlugin.top,
      left: _vm.oPlugin.left
    })},[_c('div',{style:({
        width: _vm.oPlugin.width + 'px',
        height: _vm.oPlugin.height + 'px'
      }),attrs:{"id":"dplayer"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }