import Vue from 'vue'
import Vuex from 'vuex'
import { getvideomonitorList, getFile } from '@/request/api'
Vue.use(Vuex)
import axios from 'axios'
export default new Vuex.Store({
  state: {
    pageNum: 3,
    bgBlockActive: false,
    jiankong: [{}, {}],
    centerShow: false,
    jkOps: {
      width: 0,
      height: 0,
      top: 0,
      left: 0
    },
    jkz: null,
    JsonData: {},
    SanwuJsonData: [],
    sanwuFile: [],
    fengxianFile: []
  },
  mutations: {
    setPageNum(state, num) {
      state.pageNum = num
    },
    setBgBlockActive(state, num) {
      state.bgBlockActive = num
    },
    setjiankong(state, num) {
      state.jiankong = num
    }
  },
  actions: {
    getJsonData({ state }) {
      axios.get('/static/json/index.json').then((res) => {
        // console.log("🚀 ~ file: index.js ~ line 34 ~ axios.get ~ res", res)
        state.JsonData = res.data
      })
      axios
        .get('/static/json/sanwugongkai.json')
        .then((res) => {
          // console.log("🚀 ~ file: index.js ~ line 34 ~ axios.get ~ res", res)
          state.SanwuJsonData = res.data
        })
      axios.get('/static/json/sanwu.json').then((res) => {
        // console.log("🚀 ~ file: index.js ~ line 34 ~ axios.get ~ res", res)
        state.sanwuFile = res.data
      })
      axios
        .get('/static/json/fengxian.json')
        .then((res) => {
          // console.log("🚀 ~ file: index.js ~ line 34 ~ axios.get ~ res", res)
          state.fengxianFile = res.data
        })
    },
    getFile({}, url) {
      return axios.get('/file/' + url)
    },
    getcamerasList({ state, commit }) {
      // getvideomonitorList()
      return new Promise((resolve) => {
        getvideomonitorList().then((res) => {
          console.log(res)
          const data = res.data || []
          const i = data.findIndex(
            (item) => item.name === 'Camera 01'
          )
          state.jkz = res.data[i]
          if (i != -1) {
            data.splice(
              data.length,
              0,
              data.splice(i, 1)[0]
            )
          }
          commit('setjiankong', data)
          resolve()
        })
      })
    }
  },
  modules: {}
})
