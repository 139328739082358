<template>
  <div class="page-content page-content-5">
    <div class="left">
      <div class="block-1">
        <div class="left-title">农村基层党建</div>
        <div class="content">
          <div class="chart-wrap">
            <div class="chart-left">
              <!-- <div class="chart-left-title">事件类型分类</div> -->
              <div class="chart-left-block">
                <div class="card-title">党组织数量</div>
                <div class="num">2 <div class="percent">0%</div>
                </div>
              </div>
            </div>
            <div id="myChart51"
                 :style="{width: '100%', height: '100%'}"></div>
          </div>
          <div class="chart-wrap">
            <div class="chart-left">
              <!-- <div class="chart-left-title">事件类型分类</div> -->
              <div class="chart-left-block">
                <div class="card-title">党员数量</div>
                <div class="num">
                  {{ partyCount }}
                </div>
              </div>
            </div>
            <div class="item-wrap">
              <div class="item"
                   v-for="(item,index) in item2"
                   :key="index">
                <div class="item-title">
                  <span class="name">{{ item.name }}</span>
                  <span :style="{color:item.color,fontSize:'22px',fontWeight:'bold',marginLeft:'6px'}">
                    <ICountUp ref="countUp"
                              :delay="600"
                              :endVal="item.num"
                              :options="{
                        decimalPlaces:item.decimals || 0
                      }" />
                  </span>
                </div>
                <div class="item-chart"
                     :style="{backgroundImage:`url(${item.img})`}">
                  <div class="item-percent">
                    {{ Percentage(item.num,partyCount) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-wrap">
            <div class="chart-title">
              <div class="card-title">党员分布</div>
              <div class="tab">
                <div class="tab-item"
                     @click="tabClick(0)"
                     :class="tabActive === 0 ? 'active' : ''">
                  <span>学历</span>
                  <img src="../../../../assets/img/page5-1.png"
                       alt="">
                </div>
                <div class="tab-item"
                     @click="tabClick(1)"
                     :class="tabActive === 1 ? 'active' : ''">
                  <span>年龄</span>
                  <img src="../../../../assets/img/page5-1.png"
                       alt="">
                </div>
              </div>
            </div>
            <div id="myChart53"
                 :style="{width: '100%', flex:1,height:0}"></div>
          </div>
        </div>
      </div>
      <div class="block-2">
        <div class="block-2-inner">
          <div class="left-title">智能垃圾站</div>
          <div class="top-title">
            <div class="item">
              <div>投放人数</div>
              <div style="color:rgba(33, 226, 255, 1);fontSize:32px;fontWeight:bold">212</div>
            </div>
            <div class="item">
              <div style="color:rgba(33, 226, 255, 1);fontSize:34px;fontWeight:bold">88%</div>
              <div>分类准确率</div>
            </div>
            <div class="item">
              <div>分类错误</div>
              <div style="color:rgba(33, 226, 255, 1);fontSize:32px;fontWeight:bold">25</div>
            </div>
          </div>
          <div class="bottom-item">
            <div class="item"
                 v-for="(item,index) in item3"
                 :key="index">
              <img :src="item.icon"
                   alt="">
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center"></div>
    <div class="right">
      <div class="block-3">
        <div class="right-title">农村集体经济</div>
        <div class="card-1">
          <div class="item"
               v-for="(item,index) in item4"
               :key="index">
            <div>{{ item.name }}(<span style="color:rgba(255, 233, 149, 1);fontWeight:bold;">{{ item.unit }}</span>)</div>
            <div style="fontSize:42px;color:rgba(255, 233, 149, 1);fontWeight:bold;marginTop:8px;">
              <ICountUp ref="countUp"
                        :delay="600"
                        :endVal="item.num"
                        :options="{
                  decimalPlaces:item.decimals || 0
                }" />
            </div>
          </div>
        </div>
        <div class="card-2">
          <div class="card-title">产业经济</div>
          <div id="myChart55"
               :style="{width: '100%', flex:1,height:0}"></div>
        </div>
        <div class="card-3">
          <div class="card-title">农产品分布(亩)</div>
          <div id="myChart54"
               :style="{width: '100%', flex:1,height:0}"></div>
        </div>
      </div>
      <shuizhi />
    </div>
  </div>
</template>

<script>
let data1 = [];
const axisLineColor = "rgba(97, 255, 251, .3)";
import { Percentage } from "@/utils/util";
import shuizhi from "@/components/shuizhi";
import {
  getPartyCount,
  getPartystandingCount,
  getPartyAgeCount,
  getPartyEduCount,
} from "@/request/api";
export default {
  components: {
    shuizhi,
  },
  data() {
    return {
      partyCount: 0,
      tabActive: 0,
      option1: {
        tooltip: {
          // trigger: 'item'
        },
        color: [
          "rgba(234, 104, 162, 1)",
          "rgba(97, 255, 251, 1)",
          "rgba(0, 160, 233, 1)",
          "rgba(236, 186, 114, 1)",
          "rgba(71, 189, 145, 1)",
          "rgba(215, 94, 94, 1)",
        ],
        legend: {
          top: "middle",
          right: 10,
          orient: "vertical",
          itemWidth: 8,
          itemHeight: 8,
          borderRadius: 50,
          textStyle: {
            color: "#fff",
            borderWidth: 0,
            rich: {
              a: {
                fontSize: 14,
                width: 55,
              },
              b: {
                fontSize: 16,
                align: "right",
                padding: [0, 10, 0, 15],
              },
            },
          },
          formatter: (name) => {
            const data = data1;
            let total = 0;
            let target;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            var arr = [
              "{a|" +
                name +
                "}{b|" +
                ((target / total) * 100).toFixed(2) +
                "%}",
            ];
            return arr.join("\n");
          },
        },
        series: [
          {
            type: "pie",
            radius: ["55%", "70%"], // 中间白色圆周面积
            center: ["25%", "50%"], // 左右距离
            avoidLabelOverlap: false,
            tooltip: {
              trigger: "item",
              formatter: function (params) {
                return (
                  params.name +
                  "：" +
                  params.value +
                  "<br>占比：" +
                  params.percent.toFixed(2) +
                  "%"
                );
              },
            },
            label: {
              show: true,
              position: "center",
              formatter: "党龄分布",
              fontSize: 16,
              color: "#fff",
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      item2: [
        {
          name: "男",
          num: 0,
          img: require("../../../../assets/img/page2center1.png"),
          color: "rgba(0, 160, 233, 1)",
        },
        {
          name: "女",
          num: 0,
          img: require("../../../../assets/img/page2center2.png"),
          color: "rgba(234, 104, 162, 1)",
        },
      ],
      item3: [
        {
          name: "有害垃圾",
          icon: require("../../../../assets/img/page5-2.png"),
        },
        {
          name: "可回收垃圾",
          icon: require("../../../../assets/img/page5-3.png"),
        },
        { name: "干垃圾", icon: require("../../../../assets/img/page5-4.png") },
        { name: "湿垃圾", icon: require("../../../../assets/img/page5-5.png") },
      ],
      item4: [
        { name: "农户数量", unit: "户", num: 567 },
        { name: "总收入", unit: "万元", num: 55 },
        { name: "总费用", unit: "万元", num: 150 },
      ],
      option3: {
        title: {
          //图表的标题，注释掉就没有名称了
          show: false,
        },
        color: ["#FFB335", "#14B1FF"],
        tooltip: {
          backgroundColor: "rgba(255,255,255,.8)",
          trigger: "axis", //触发类型；轴触发，axis则鼠标hover到一条柱状图显示全部数据，item则鼠标hover到折线点显示相应数据，
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            console.log(params);
            var dotHtml =
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:rgba(95, 250, 246, 1)"></span>';
            return dotHtml + params[0].name + "：" + params[0].value + "人";
          },
        },
        legend: {
          show: false,
          data: ["人数"], //这里设置柱状图上面的方块，名称跟series里的name保持一致
          align: "right", //上面方块靠右还是居中的设置.不设置则居中
          right: 10,
          itemHeight: 14,
          itemWidth: 14,
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "10%",
          left: "3%", //柱状图距离左边的距离，也可以用像素px
          right: "4%", //柱状图距离右边的距离，也可以用像素px
          bottom: "2%", //网格图（柱状图、折线图、气泡图等）离底部的距离，也可以用像素比如10px
          containLabel: true, //grid 区域是否包含坐标轴的刻度标签。false可能溢出，默认为false
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              //刻度
              show: false,
            },
            axisLabel: {
              fontSize: 12,
              color: "#fff",
            },
            axisLine: {
              lineStyle: {
                color: axisLineColor,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              fontSize: 10,
              color: "#fff",
            },
            splitLine: {
              lineStyle: {
                color: axisLineColor,
              },
            },
          },
        ],
        series: [
          {
            //柱状图-柱子1
            name: "人数", //需要跟legend配置项对应
            type: "bar",
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "rgba(97, 255, 251, 0.1)",
                },
                {
                  offset: 1,
                  color: "rgba(95, 250, 246, 1)",
                },
              ]),
              barBorderRadius: [10, 10, 0, 0], //控制柱状图的圆角显示弧度，（顺时针左上，右上，右下，左下）
            },
            barGap: "50%",
            barWidth: "10px", //barWidth设置每根柱状图的宽度
            data: [],
          },
        ],
      },
      option4: {
        exporting: {
          enabled: false,
        },
        title: {
          text: "",
        },
        colors: [
          "#8C1D17",
          "#00B3B0",
          "#43DEFF",
          "#47BD91",
          "#1aadce",
          "#492970",
          "#f28f43",
          "#77a1e5",
          "#c42525",
          "#a6c96a",
        ],
        legend: {
          enabled: false,
        },
        chart: {
          type: "pie",
          plotBackgroundColor: null,
          backgroundColor: "transparent",
          plotBorderWidth: null,
          plotShadow: false,
          options3d: {
            enabled: true,
            alpha: 60,
            beta: 0,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          textStyle: {
            color: "#ffffff",
          },
          useHTML: true,
          formatter: function () {
            return `<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${
              this.point.color
            }'></span>${this.point.name}:${
              this.point.y
            }亩</br>占比：${this.point.percentage.toFixed(1)}%`;
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 28,
            dataLabels: {
              enabled: true,
              useHTML: true, //使用formatter内的标签和样式
              formatter: function () {
                return (
                  '<div><p style="color: #ccf9ff;">' +
                  this.point.name +
                  "</p>" +
                  '<p><span style="font-size: 14px;margin-right: 4px;">' +
                  this.y +
                  '</span><span style="color: #5c9da5;font-size: 12px;">亩</span></p>' +
                  '<p style="font-size: 14px;color: ' +
                  this.point.color +
                  '">' +
                  this.percentage.toFixed(1) +
                  "%</p></div>"
                );
              },
              style: {
                color: "#ffffff",
                textOutline: "none",
              },
              shadow: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            type: "pie",
            // center: ['50%', '50%'],
            // size:'100%',
            colorByPoint: true,
            // name: '浏览器占比',
            data: [
              ["水稻", 45],
              ["食用菌", 16],
              ["毛竹", 18],
              ["茶叶", 16],
              ["油茶", 8],
              ["果树", 16],
              ["其他", 6],
            ],
          },
        ],
      },
      option5: {
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          textStyle: {
            color: "#ffffff",
          },
          useHTML: true,
          formatter: function () {
            return `<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${
              this.point.color
            }'></span>${this.point.name}:${
              this.point.y
            }万元</br>占比：${this.point.percentage.toFixed(1)}%`;
          },
        },
        chart: {
          type: "pyramid3d",
          plotBackgroundColor: null,
          backgroundColor: "transparent",
          plotBorderWidth: null,
          plotShadow: false,
          options3d: {
            enabled: true,
            alpha: 10,
            depth: 50,
            viewDistance: 100,
          },
        },
        title: {
          text: "",
        },
        colors: ["#816a3d", "#a89359", "#d4bd76", "#ffe995"],
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              // </b> ({point.y:,.0f})
              format: "<b>{point.name}{y}万元",
              color: "#fff",
              allowOverlap: true,
              shadow: false,
              x: 10,
              y: -5,
              style: {
                color: "#ffffff",
                textOutline: "none",
              },
            },
            width: "60%",
            height: "80%",
            center: ["50%", "45%"],
          },
        },
        series: [
          {
            name: "产业经济",
            data: [
              ["第一产业", 122],
              ["第二产业", 102],
              ["第三产业", 87],
            ],
          },
        ],
      },
    };
  },
  created() {
    setTimeout(() => {
      Promise.resolve().then(() => {
        this.initMyChart1();
        this.initMyChart3();
        this.initMyChart4();
        this.initMyChart5();
      });
    }, 600);
    getPartyCount().then((res) => {
      this.partyCount = 54 || res.data.organ_count;
      this.item2[0].num = 47 || res.data.male_party_member_count;
      this.item2[1].num = 7 || res.data.female_party_member_count;
    });
  },
  methods: {
    Percentage,
    initMyChart1() {
      const myChart51 = document.getElementById("myChart51");
      data1 = [
        { name: "50以上", value: 6 },
        { name: "30-50", value: 4 },
        { name: "10-30", value: 23 },
        { name: "10以下", value: 22 },
      ];
      getPartystandingCount().then((res) => {
        // console.log(
        //   "🚀 ~ file: page5.vue ~ line 477 ~ getPartystandingCount ~ res",
        //   res
        // );
        // for (const key in res.data) {
        // 	data1.push({name:key,value: res.data[key]})
        // }
        // this.option1.series[0].data = data1
        // this.myChart51 = this.$echarts.init(myChart51)
        // this.myChart51.setOption(this.option1)
      });
      this.option1.series[0].data = data1;
      this.myChart51 = this.$echarts.init(myChart51);
      this.myChart51.setOption(this.option1);
    },
    initMyChart3() {
      const myChart1 = document.getElementById("myChart53");
      this.myChart53 = this.$echarts.init(myChart1);
      const option = this.option3;
      getPartyAgeCount();
      getPartyEduCount();
      if (this.tabActive === 0) {
        option.series[0].data = [30, 19, 8];
        option.xAxis[0].data = ["初中以下", "高中", "大专以上"];
        this.myChart53.setOption(option);
        // getPartyEduCount().then((res) => {
        //   console.log(Object.values(res.data));
        //   option.series[0].data = Object.keys(res.data).length
        //     ? Object.values(res.data)
        //     : [20, 19, 8];
        //   option.xAxis[0].data = Object.keys(res.data).length
        //     ? Object.keys(res.data)
        //     : ["初中以下", "高中", "大专以上"];
        //   this.myChart53.setOption(option);
        // });
      }
      if (this.tabActive === 1) {
        // data:[['35岁以下',7],['35-60岁',26],['60岁以上',24]]
        getPartyAgeCount().then((res) => {
          console.log(Object.values(res.data));
          option.series[0].data = [7, 26, 24];
          // option.series[0].data = Object.values(res.data)
          option.xAxis[0].data = Object.keys(res.data);
          this.myChart53.setOption(option);
        });
      }
    },
    tabClick(index) {
      this.tabActive = index;
      this.initMyChart3();
    },
    initMyChart4() {
      const myChart3 = document.getElementById("myChart54");
      new Highcharts.Chart(myChart3, this.option4);
    },
    initMyChart5() {
      const myChart3 = document.getElementById("myChart55");
      new Highcharts.Chart(myChart3, this.option5);
    },
  },
};
</script>

<style lang='less' scoped>
@import "./page5.less";
</style>