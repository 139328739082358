<template>
  <swiper 
    class="slider-wrap"
    ref="mySwiper"
    :options="options"
    v-if="total || (blockData && blockData.length)"
  > 
    <swiper-slide 
      class="slider-item"
      v-for="(row,rindex) in Math.ceil((total || blockData.length) / cur)"
      :key="rindex"
    >
      <template v-for="(item,index) in blockData">
        <div
          class="table-row" 
          :style="{height: (100/cur).toFixed(1)+'%',cursor: (item.onClick ? 'pointer' : '')}"
          :key="index"
          v-if="index < row * cur && index >= (row-1)*cur && total"
          @click.stop="item.onClick ? itemClick(item) : ''"
        >
          <div
            v-show-tips
            :style="{flex:blockColumn[cindex].flex || 1}"
            v-for="(cell,cindex) in blockColumn"
            :key="cindex"
            class="item-cell"
          >
            {{ item[cell.key] }}
          </div>
        </div>
      </template>
    </swiper-slide>
  </swiper> 
</template>

<script>
export default {
	props:{
		blockData:{
			type:Array,
			default:()=>[]
		},
		blockColumn:{
			type:Array,
			default:()=>[]
		},
		cur:{
			type:Number,
			default:5
		},
		total:{
			type:Number,
			default:5
		},
		option:{
			type:Object,
			default:()=>{}
		}
	},
	computed:{
		mySwiper(){
			return this.$refs.mySwiper
		},
	},
	watch:{
		option:{
			handler(val){
				this.options = Object.assign({},this.defaultoptions,val)
			},
			immediate:true
		}
	},
	data(){
		return {
			options:{},
			defaultoptions:{
				initialSlide:0,
				direction : 'vertical',
				autoplay:true,
				loop:false,
				observeParents: true,
				observer: true,
				on:{
					slideChange:this.slideChange
				}
			}
		}
	},
	methods: {
		slideChange(){
			this.$emit('slideChange',this.$refs.mySwiper.swiper.activeIndex)
		},
		itemClick(item){
			console.log('🚀 ~ file: index.vue ~ line 67 ~ itemClick ~ item', item)
			this.mySwiper.swiper.autoplay.stop()
			this.$emit('onClick',item)
		},
		autoplayStart(){
			this.mySwiper.swiper.autoplay.start()
		}
	}
}
</script>

<style lang="less" scoped>
 .slider-wrap{
  flex:1;
  height: 0;
  width:100%;
}
.slider-item{
  display: flex;
  flex-direction: column;
  width:100%;
  justify-content: start;
}
.table-row{
  width:100%;
  height:16.6%;
  font-size: 14px;
  text-align: left;
  flex:0 0 auto;
  align-items: center;
}
</style>