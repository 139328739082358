export default [{ 'name': '周锡贵', 'mail1': '1.68', 'mail2': '188.16' },
{ 'name': '周锡林', 'mail1': '1.68', 'mail2': '188.16' },
{ 'name': '姜善波', 'mail1': '1.94', 'mail2': '217.28' },
{ 'name': '姜善荣', 'mail1': '3.45', 'mail2': '386.4' },
{ 'name': '姜善洪', 'mail1': '3.33', 'mail2': '372.96' },
{ 'name': '姜善炬', 'mail1': '3.47', 'mail2': '388.64' },
{ 'name': '姜仁林', 'mail1': '3.08', 'mail2': '344.96' },
{ 'name': '周焕亮', 'mail1': '3.85', 'mail2': '431.2' },
{ 'name': '周堂良', 'mail1': '1.6', 'mail2': '179.2' },
{ 'name': '姜仁全', 'mail1': '3.08', 'mail2': '344.96' },
{ 'name': '姜善水', 'mail1': '1.55', 'mail2': '173.6' },
{ 'name': '郑火香', 'mail1': '4.29', 'mail2': '480.48' },
{ 'name': '姜益隼', 'mail1': '0.83', 'mail2': '92.96' },
{ 'name': '周传良', 'mail1': '3.9', 'mail2': '436.8' },
{ 'name': '姜善根', 'mail1': '3.08', 'mail2': '344.96' },
{ 'name': '姜仁泉', 'mail1': '4.63', 'mail2': '518.56' },
{ 'name': '姜仁钱', 'mail1': '0.34', 'mail2': '38.08' },
{ 'name': '姜善财', 'mail1': '3.85', 'mail2': '431.2' },
{ 'name': '姜善林', 'mail1': '3.08', 'mail2': '344.96' },
{ 'name': '姜根梁', 'mail1': '3.08', 'mail2': '344.96' },
{ 'name': '郑初丰', 'mail1': '4.68', 'mail2': '524.16' },
{ 'name': '王忠炳', 'mail1': '2.79', 'mail2': '312.48' },
{ 'name': '王水木', 'mail1': '1.5', 'mail2': '168' },
{ 'name': '王忠金', 'mail1': '3.62', 'mail2': '405.44' },
{ 'name': '姜仁发', 'mail1': '4.01', 'mail2': '449.12' },
{ 'name': '徐良福', 'mail1': '4.82', 'mail2': '539.84' },
{ 'name': '徐根达', 'mail1': '2.41', 'mail2': '269.92' },
{ 'name': '徐良深', 'mail1': '3.18', 'mail2': '356.16' },
{ 'name': '姜益福', 'mail1': '4.01', 'mail2': '449.12' },
{ 'name': '姜清炎', 'mail1': '3.19', 'mail2': '357.28' },
{ 'name': '姜仁亮', 'mail1': '4.8', 'mail2': '537.6' },
{ 'name': '姜清标', 'mail1': '1.61', 'mail2': '180.32' },
{ 'name': '姜益富', 'mail1': '2.41', 'mail2': '269.92' },
{ 'name': '姜仁旺', 'mail1': '5.12', 'mail2': '573.44' },
{ 'name': '姜益良', 'mail1': '2.84', 'mail2': '318.08' },
{ 'name': '姜仁和', 'mail1': '2.97', 'mail2': '332.64' },
{ 'name': '姜仁标', 'mail1': '2.97', 'mail2': '332.64' },
{ 'name': '姜仁朝', 'mail1': '2.97', 'mail2': '332.64' },
{ 'name': '汪荣凤', 'mail1': '2.97', 'mail2': '332.64' },
{ 'name': '姜益松', 'mail1': '3.16', 'mail2': '353.92' },
{ 'name': '姜清钱', 'mail1': '3.34', 'mail2': '374.08' },
{ 'name': '姜益荣', 'mail1': '2.84', 'mail2': '318.08' },
{ 'name': '姜清荣', 'mail1': '3.87', 'mail2': '433.44' },
{ 'name': '姜益金', 'mail1': '3.9', 'mail2': '436.8' },
{ 'name': '姜益炳', 'mail1': '2.63', 'mail2': '294.56' },
{ 'name': '姜益财', 'mail1': '3.55', 'mail2': '397.6' },
{ 'name': '姜益春', 'mail1': '2.48', 'mail2': '277.76' },
{ 'name': '姜益林', 'mail1': '2.84', 'mail2': '318.08' },
{ 'name': '姜清河', 'mail1': '1.05', 'mail2': '117.6' },
{ 'name': '陈生水', 'mail1': '5.35', 'mail2': '599.2' },
{ 'name': '姜光强', 'mail1': '2.99', 'mail2': '334.88' },
{ 'name': '姜建强', 'mail1': '1.13', 'mail2': '126.56' },
{ 'name': '姜远发', 'mail1': '3.02', 'mail2': '338.24' },
{ 'name': '祝拥军', 'mail1': '2.37', 'mail2': '265.44' },
{ 'name': '姜益雄', 'mail1': '0.73', 'mail2': '81.76' },
{ 'name': '周干华', 'mail1': '1.47', 'mail2': '164.64' },
{ 'name': '周富贤', 'mail1': '2.64', 'mail2': '295.68' },
{ 'name': '周仁贤', 'mail1': '2', 'mail2': '224' },
{ 'name': '周干堂', 'mail1': '1.87', 'mail2': '209.44' },
{ 'name': '周永贤', 'mail1': '2.05', 'mail2': '229.6' },
{ 'name': '周干平', 'mail1': '5.69', 'mail2': '637.28' },
{ 'name': '周新平', 'mail1': '1.145', 'mail2': '128.24' },
{ 'name': '周新忠', 'mail1': '1.145', 'mail2': '128.24' },
{ 'name': '周贡水', 'mail1': '2.62', 'mail2': '293.44' },
{ 'name': '周生罗', 'mail1': '1.46', 'mail2': '163.52' },
{ 'name': '周朝水', 'mail1': '2.79', 'mail2': '312.48' },
{ 'name': '周瑞成', 'mail1': '4.26', 'mail2': '477.12' },
{ 'name': '杨小月', 'mail1': '0.66', 'mail2': '73.92' },
{ 'name': '周瑞贤', 'mail1': '2.23', 'mail2': '249.76' },
{ 'name': '周华贤', 'mail1': '4.35', 'mail2': '487.2' },
{ 'name': '项丁绪', 'mail1': '3.18', 'mail2': '356.16' },
{ 'name': '项明土', 'mail1': '2.74', 'mail2': '306.88' },
{ 'name': '周干美', 'mail1': '0.43', 'mail2': '48.16' },
{ 'name': '周干阳', 'mail1': '1.25', 'mail2': '140' },
{ 'name': '陈绍秋', 'mail1': '2.74', 'mail2': '306.88' },
{ 'name': '周干荣', 'mail1': '1.05', 'mail2': '117.6' },
{ 'name': '周干和', 'mail1': '1.41', 'mail2': '157.92' },
{ 'name': '周干全', 'mail1': '1.09', 'mail2': '122.08' },
{ 'name': '周干彪', 'mail1': '1.51', 'mail2': '169.12' },
{ 'name': '周干良', 'mail1': '1.53', 'mail2': '171.36' },
{ 'name': '周新水', 'mail1': '2.84', 'mail2': '318.08' },
{ 'name': '周桂标', 'mail1': '3.11', 'mail2': '348.32' },
{ 'name': '郑初中', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '郑初林', 'mail1': '0.52', 'mail2': '58.24' },
{ 'name': '夏锡华', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '夏锡均', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '夏公飞', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '夏俊俊', 'mail1': '2.34', 'mail2': '262.08' },
{ 'name': '夏公良', 'mail1': '1.28', 'mail2': '143.36' },
{ 'name': '郑初森', 'mail1': '1.85', 'mail2': '207.2' },
{ 'name': '夏公新', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '夏宗均', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '夏公朝', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '夏公云', 'mail1': '1.91', 'mail2': '213.92' },
{ 'name': '周彰松', 'mail1': '2.65', 'mail2': '296.8' },
{ 'name': '周生荷', 'mail1': '0.56', 'mail2': '62.72' },
{ 'name': '徐正石', 'mail1': '1.6', 'mail2': '179.2' },
{ 'name': '周仁生', 'mail1': '1.56', 'mail2': '174.72' },
{ 'name': '周清先', 'mail1': '2.95', 'mail2': '330.4' },
{ 'name': '周清华', 'mail1': '1.95', 'mail2': '218.4' },
{ 'name': '周清松', 'mail1': '1.59', 'mail2': '178.08' },
{ 'name': '罗炳汉', 'mail1': '1.29', 'mail2': '144.48' },
{ 'name': '饶火生', 'mail1': '4.1', 'mail2': '459.2' },
{ 'name': '姜桂炉', 'mail1': '0.83', 'mail2': '92.96' },
{ 'name': '姜德荣', 'mail1': '3.36', 'mail2': '376.32' },
{ 'name': '周锡华', 'mail1': '2.52', 'mail2': '282.24' },
{ 'name': '周锡荣', 'mail1': '4.2', 'mail2': '470.4' },
{ 'name': '周锡富', 'mail1': '3.3', 'mail2': '369.6' },
{ 'name': '王水松', 'mail1': '2.69', 'mail2': '301.28' },
{ 'name': '姜益生', 'mail1': '6.62', 'mail2': '741.44' },
{ 'name': '饶召建', 'mail1': '3.15', 'mail2': '352.8' },
{ 'name': '刘如发', 'mail1': '3.52', 'mail2': '394.24' },
{ 'name': '姜益标', 'mail1': '5.05', 'mail2': '565.6' },
{ 'name': '姜仁江', 'mail1': '2.53', 'mail2': '283.36' },
{ 'name': '姜仁梁', 'mail1': '4.01', 'mail2': '449.12' },
{ 'name': '姜仁良', 'mail1': '2.97', 'mail2': '332.64' },
{ 'name': '姜清亮', 'mail1': '1.05', 'mail2': '117.6' },
{ 'name': '姜益海', 'mail1': '0.96', 'mail2': '107.52' },
{ 'name': '姜善欢', 'mail1': '3.08', 'mail2': '344.96' },
{ 'name': '张伟', 'mail1': '3.19', 'mail2': '357.28' },
{ 'name': '姜献仁', 'mail1': '1.54', 'mail2': '172.48' },
{ 'name': '姜益根', 'mail1': '3.18', 'mail2': '356.16' },
{ 'name': '姜如丰', 'mail1': '1.63', 'mail2': '182.56' },
{ 'name': '姜益树', 'mail1': '4.85', 'mail2': '543.2' },
{ 'name': '姜培新', 'mail1': '4.06', 'mail2': '454.72' },
{ 'name': '姜献忠', 'mail1': '2.44', 'mail2': '273.28' },
{ 'name': '姜立芳', 'mail1': '0.69', 'mail2': '77.28' },
{ 'name': '姜献福', 'mail1': '1.64', 'mail2': '183.68' },
{ 'name': '姜献兴', 'mail1': '2.44', 'mail2': '273.28' },
{ 'name': '姜传行', 'mail1': '7.15', 'mail2': '800.8' },
{ 'name': '周春仙', 'mail1': '1.72', 'mail2': '192.64' },
{ 'name': '姜献林', 'mail1': '1.83', 'mail2': '204.96' },
{ 'name': '周汝康', 'mail1': '4.06', 'mail2': '454.72' },
{ 'name': '姜清华', 'mail1': '4.06', 'mail2': '454.72' },
{ 'name': '程小春', 'mail1': '3.18', 'mail2': '356.16' },
{ 'name': '吴克均', 'mail1': '2.44', 'mail2': '273.28' },
{ 'name': '吴克云', 'mail1': '4.89', 'mail2': '547.68' },
{ 'name': '王雪花', 'mail1': '0.81', 'mail2': '90.72' },
{ 'name': '周生金', 'mail1': '3.17', 'mail2': '355.04' },
{ 'name': '张标', 'mail1': '1.52', 'mail2': '170.24' },
{ 'name': '郑彩芝', 'mail1': '3.98', 'mail2': '445.76' },
{ 'name': '周新陆', 'mail1': '2.09', 'mail2': '234.08' },
{ 'name': '周新海', 'mail1': '2.44', 'mail2': '273.28' },
{ 'name': '姜献炉', 'mail1': '1.88', 'mail2': '210.56' },
{ 'name': '姜金财', 'mail1': '3.73', 'mail2': '417.76' },
{ 'name': '姜春华', 'mail1': '4.87', 'mail2': '545.44' },
{ 'name': '姜献荣', 'mail1': '2.3', 'mail2': '257.6' },
{ 'name': '姜益堂', 'mail1': '5.16', 'mail2': '577.92' },
{ 'name': '姜益炉', 'mail1': '3.86', 'mail2': '432.32' },
{ 'name': '姜金松', 'mail1': '5.66', 'mail2': '633.92' },
{ 'name': '姜献生', 'mail1': '3.88', 'mail2': '434.56' },
{ 'name': '姜献伟', 'mail1': '1.98', 'mail2': '221.76' },
{ 'name': '姜建华', 'mail1': '2.51', 'mail2': '281.12' },
{ 'name': '周鹏', 'mail1': '2.12', 'mail2': '237.44' },
{ 'name': '姜益金', 'mail1': '0.77', 'mail2': '86.24' },
{ 'name': '姜远财', 'mail1': '2.52', 'mail2': '282.24' },
{ 'name': '祝荣富', 'mail1': '5.05', 'mail2': '565.6' },
{ 'name': '程雪连', 'mail1': '2.21', 'mail2': '247.52' },
{ 'name': '徐福山', 'mail1': '1.02', 'mail2': '114.24' },
{ 'name': '周干炉', 'mail1': '1.51', 'mail2': '169.12' },
{ 'name': '周新林', 'mail1': '2.08', 'mail2': '232.96' },
{ 'name': '周柱锡', 'mail1': '0.66', 'mail2': '73.92' },
{ 'name': '周干发', 'mail1': '1.87', 'mail2': '209.44' },
{ 'name': '周台标', 'mail1': '1.81', 'mail2': '202.72' },
{ 'name': '周松德', 'mail1': '1.93', 'mail2': '216.16' },
{ 'name': '姜正艳', 'mail1': '1.94', 'mail2': '217.28' },
{ 'name': '周成贤', 'mail1': '1.17', 'mail2': '131.04' },
{ 'name': '徐华贵', 'mail1': '1.09', 'mail2': '122.08' },
{ 'name': '毛饶发', 'mail1': '1.59', 'mail2': '178.08' },
{ 'name': '周干火', 'mail1': '1.19', 'mail2': '133.28' },
{ 'name': '周干军', 'mail1': '1.37', 'mail2': '153.44' },
{ 'name': '周坤贤', 'mail1': '0.7', 'mail2': '78.4' },
{ 'name': '周干华', 'mail1': '1.1', 'mail2': '123.2' },
{ 'name': '余良飞', 'mail1': '1.16', 'mail2': '129.92' },
{ 'name': '余良生', 'mail1': '2.31', 'mail2': '258.72' },
{ 'name': '余良顺', 'mail1': '2.67', 'mail2': '299.04' },
{ 'name': '周干江', 'mail1': '1.12', 'mail2': '125.44' },
{ 'name': '周干林', 'mail1': '2.39', 'mail2': '267.68' },
{ 'name': '徐福四', 'mail1': '2.11', 'mail2': '236.32' },
{ 'name': '徐嘉棋', 'mail1': '0.64', 'mail2': '71.68' },
{ 'name': '周干水', 'mail1': '1', 'mail2': '112' },
{ 'name': '周新火', 'mail1': '1.44', 'mail2': '161.28' },
{ 'name': '徐正章', 'mail1': '2.61', 'mail2': '292.32' },
{ 'name': '徐正标', 'mail1': '1.25', 'mail2': '140' },
{ 'name': '周松华', 'mail1': '1.34', 'mail2': '150.08' },
{ 'name': '周清龙', 'mail1': '3.3', 'mail2': '369.6' },
{ 'name': '周松福', 'mail1': '1.74', 'mail2': '194.88' },
{ 'name': '周松贵', 'mail1': '0.55', 'mail2': '61.6' },
{ 'name': '周松才', 'mail1': '2', 'mail2': '224' },
{ 'name': '周松海', 'mail1': '1.23', 'mail2': '137.76' },
{ 'name': '周松标', 'mail1': '1.06', 'mail2': '118.72' },
{ 'name': '周松兴', 'mail1': '2.44', 'mail2': '273.28' },
{ 'name': '周松金', 'mail1': '2.24', 'mail2': '250.88' },
{ 'name': '周松林', 'mail1': '1.42', 'mail2': '159.04' },
{ 'name': '周彰标', 'mail1': '1.94', 'mail2': '217.28' },
{ 'name': '周发金', 'mail1': '4.03', 'mail2': '451.36' },
{ 'name': '周生太', 'mail1': '1.2', 'mail2': '134.4' },
{ 'name': '周生成', 'mail1': '2.11', 'mail2': '236.32' },
{ 'name': '周松银', 'mail1': '3.84', 'mail2': '430.08' },
{ 'name': '周元祥', 'mail1': '3.48', 'mail2': '389.76' },
{ 'name': '陈山西', 'mail1': '2', 'mail2': '224' },
{ 'name': '周生如', 'mail1': '2.4', 'mail2': '268.8' },
{ 'name': '周生龙', 'mail1': '1.32', 'mail2': '147.84' },
{ 'name': '周龙生', 'mail1': '2.52', 'mail2': '282.24' },
{ 'name': '姜金福', 'mail1': '2.25', 'mail2': '252' },
{ 'name': '姜献良', 'mail1': '3.29', 'mail2': '368.48' },
{ 'name': '姜献清', 'mail1': '2.53', 'mail2': '283.36' },
{ 'name': '姜金清', 'mail1': '2.9', 'mail2': '324.8' },
{ 'name': '姜献松', 'mail1': '3.23', 'mail2': '361.76' },
{ 'name': '姜金寿', 'mail1': '3.96', 'mail2': '443.52' },
{ 'name': '姜金山', 'mail1': '3.4', 'mail2': '380.8' },
{ 'name': '姜远灶', 'mail1': '3.18', 'mail2': '356.16' },
{ 'name': '姜伟', 'mail1': '4.59', 'mail2': '514.08' },
{ 'name': '姜立章', 'mail1': '1', 'mail2': '112' },
{ 'name': '姜立旺', 'mail1': '1.61', 'mail2': '180.32' },
{ 'name': '郑学财', 'mail1': '2.35', 'mail2': '263.2' },
{ 'name': '夏益良', 'mail1': '3.84', 'mail2': '430.08' },
{ 'name': '姜如波', 'mail1': '4.61', 'mail2': '516.32' },
{ 'name': '姜益标', 'mail1': '4.61', 'mail2': '516.32' },
{ 'name': '姜金仰', 'mail1': '3.85', 'mail2': '431.2' },
{ 'name': '温梅珠', 'mail1': '3.63', 'mail2': '406.56' },
{ 'name': '姜立耀', 'mail1': '3.08', 'mail2': '344.96' },
{ 'name': '姜立飞', 'mail1': '3.85', 'mail2': '431.2' },
{ 'name': '姜立彬', 'mail1': '3.08', 'mail2': '344.96' },
{ 'name': '姜立全', 'mail1': '2.21', 'mail2': '247.52' },
{ 'name': '姜崇干', 'mail1': '4.68', 'mail2': '524.16' },
{ 'name': '姜金水', 'mail1': '3.08', 'mail2': '344.96' },
{ 'name': '姜益柱', 'mail1': '2.68', 'mail2': '300.16' },
{ 'name': '姜如车', 'mail1': '6.34', 'mail2': '710.08' },
{ 'name': '姜益良', 'mail1': '2.91', 'mail2': '325.92' },
{ 'name': '姜益友', 'mail1': '3.57', 'mail2': '399.84' },
{ 'name': '姜益炬', 'mail1': '2.9', 'mail2': '324.8' },
{ 'name': '姜益焕', 'mail1': '3.35', 'mail2': '375.2' },
{ 'name': '姜益忠', 'mail1': '3.35', 'mail2': '375.2' },
{ 'name': '姜崇均', 'mail1': '2.67', 'mail2': '299.04' },
{ 'name': '姜作水', 'mail1': '7.12', 'mail2': '797.44' },
{ 'name': '姜立荣', 'mail1': '4.64', 'mail2': '519.68' },
{ 'name': '姜金贵', 'mail1': '2.8', 'mail2': '313.6' },
{ 'name': '姜远标', 'mail1': '2.8', 'mail2': '313.6' },
{ 'name': '姜如良', 'mail1': '3.15', 'mail2': '352.8' },
{ 'name': '姜如炎', 'mail1': '4.62', 'mail2': '517.44' },
{ 'name': '徐仙梅', 'mail1': '2.1', 'mail2': '235.2' },
{ 'name': '姜善福', 'mail1': '4.44', 'mail2': '497.28' },
{ 'name': '姜善贵', 'mail1': '6.71', 'mail2': '751.52' },
{ 'name': '姜如炉', 'mail1': '1.93', 'mail2': '216.16' },
{ 'name': '姜如松', 'mail1': '2.8', 'mail2': '313.6' },
{ 'name': '姜如贤', 'mail1': '3.85', 'mail2': '431.2' },
{ 'name': '姜如万', 'mail1': '3.85', 'mail2': '431.2' },
{ 'name': '姜如忠', 'mail1': '3.15', 'mail2': '352.8' },
{ 'name': '姜如海', 'mail1': '2.37', 'mail2': '265.44' },
{ 'name': '吴良泉', 'mail1': '2.8', 'mail2': '313.6' },
{ 'name': '周水英', 'mail1': '3.4', 'mail2': '380.8' },
{ 'name': '周华丰', 'mail1': '4.61', 'mail2': '516.32' },
{ 'name': '周新荣', 'mail1': '2.8', 'mail2': '313.6' },
{ 'name': '姜益文', 'mail1': '2.38', 'mail2': '266.56' },
{ 'name': '姜益武', 'mail1': '1.4', 'mail2': '156.8' },
{ 'name': '姜益华', 'mail1': '3.76', 'mail2': '421.12' },
{ 'name': '姜益才', 'mail1': '1.59', 'mail2': '178.08' },
{ 'name': '郑国柱', 'mail1': '1.9', 'mail2': '212.8' },
{ 'name': '周丽刚', 'mail1': '2.21', 'mail2': '247.52' },
{ 'name': '周瑞火', 'mail1': '2.28', 'mail2': '255.36' },
{ 'name': '周生林', 'mail1': '2.19', 'mail2': '245.28' },
{ 'name': '姜新根', 'mail1': '2.19', 'mail2': '245.28' },
{ 'name': '周金芳', 'mail1': '0.99', 'mail2': '110.88' },
{ 'name': '周发旺', 'mail1': '0.53', 'mail2': '59.36' },
{ 'name': '周清林', 'mail1': '1.9', 'mail2': '212.8' },
{ 'name': '周瑞春', 'mail1': '2', 'mail2': '224' },
{ 'name': '周新旺', 'mail1': '2.1', 'mail2': '235.2' },
{ 'name': '周新良', 'mail1': '2.47', 'mail2': '276.64' },
{ 'name': '周生金', 'mail1': '2.54', 'mail2': '284.48' },
{ 'name': '周新堂', 'mail1': '2.57', 'mail2': '287.84' },
{ 'name': '周新海', 'mail1': '2.7', 'mail2': '302.4' },
{ 'name': '周祥华', 'mail1': '3.58', 'mail2': '400.96' },
{ 'name': '周德寿', 'mail1': '1.61', 'mail2': '180.32' },
{ 'name': '周德亮', 'mail1': '1.38', 'mail2': '154.56' },
{ 'name': '徐远富', 'mail1': '2.77', 'mail2': '310.24' },
{ 'name': '周德江', 'mail1': '1.94', 'mail2': '217.28' },
{ 'name': '周发兴', 'mail1': '1.24', 'mail2': '138.88' },
{ 'name': '徐振六', 'mail1': '2.02', 'mail2': '226.24' },
{ 'name': '周新贵', 'mail1': '1.18', 'mail2': '132.16' },
{ 'name': '周生福', 'mail1': '1.66', 'mail2': '185.92' },
{ 'name': '周发林', 'mail1': '0.53', 'mail2': '59.36' },
{ 'name': '周新亮', 'mail1': '1.03', 'mail2': '115.36' },
{ 'name': '刘金水', 'mail1': '1.4', 'mail2': '156.8' },
{ 'name': '刘金良', 'mail1': '0.67', 'mail2': '75.04' },
{ 'name': '周必兴', 'mail1': '0.24', 'mail2': '26.88' },
{ 'name': '周仁水', 'mail1': '3.3', 'mail2': '369.6' },
{ 'name': '周松良', 'mail1': '2.82', 'mail2': '315.84' },
{ 'name': '周松发', 'mail1': '2.84', 'mail2': '318.08' },
{ 'name': '周仁炎', 'mail1': '1.34', 'mail2': '150.08' },
{ 'name': '吴秋英', 'mail1': '1.52', 'mail2': '170.24' },
{ 'name': '周生期', 'mail1': '1.51', 'mail2': '169.12' },
{ 'name': '周松生', 'mail1': '0.68', 'mail2': '76.16' },
{ 'name': '姜吉荣', 'mail1': '2.5', 'mail2': '280' },
{ 'name': '周吉炎', 'mail1': '2.7', 'mail2': '302.4' },
{ 'name': '项明祥', 'mail1': '2.44', 'mail2': '273.28' },
{ 'name': '周双贤', 'mail1': '1.25', 'mail2': '140' },
{ 'name': '周忠发', 'mail1': '0.8', 'mail2': '89.6' },
{ 'name': '汪金水', 'mail1': '1.13', 'mail2': '126.56' },
{ 'name': '汪忠勇', 'mail1': '0.73', 'mail2': '81.76' },
{ 'name': '刘金柱', 'mail1': '0.9', 'mail2': '100.8' },
{ 'name': '周成发', 'mail1': '0.38', 'mail2': '42.56' },
{ 'name': '周新荣', 'mail1': '1.31', 'mail2': '146.72' },
{ 'name': '周财发', 'mail1': '1.27', 'mail2': '142.24' },
{ 'name': '周松相', 'mail1': '2.57', 'mail2': '287.84' },
{ 'name': '张丁顺', 'mail1': '3.35', 'mail2': '375.2' },
{ 'name': '姜永标', 'mail1': '2.8', 'mail2': '313.6' },
{ 'name': '姜永枫', 'mail1': '1.41', 'mail2': '157.92' },
{ 'name': '夏公林', 'mail1': '2.64', 'mail2': '295.68' },
{ 'name': '李月花', 'mail1': '2.8', 'mail2': '313.6' },
{ 'name': '姜如升', 'mail1': '0.78', 'mail2': '87.36' },
{ 'name': '姜清荣', 'mail1': '2.63', 'mail2': '294.56' },
{ 'name': '姜益忠', 'mail1': '1.77', 'mail2': '198.24' },
{ 'name': '姜远明', 'mail1': '4.12', 'mail2': '461.44' },
{ 'name': '姜庆华', 'mail1': '4.29', 'mail2': '480.48' },
{ 'name': '姜清章', 'mail1': '1.97', 'mail2': '220.64' },
{ 'name': '姜益银', 'mail1': '1.83', 'mail2': '204.96' },
{ 'name': '姜益如', 'mail1': '1.7', 'mail2': '190.4' },
{ 'name': '姜建顺', 'mail1': '4.08', 'mail2': '456.96' },
{ 'name': '周金松', 'mail1': '3.04', 'mail2': '340.48' },
{ 'name': '姜建森', 'mail1': '4.12', 'mail2': '461.44' },
{ 'name': '姜崇清', 'mail1': '3.81', 'mail2': '426.72' },
{ 'name': '姜光明', 'mail1': '2.18', 'mail2': '244.16' },
{ 'name': '姜远成', 'mail1': '2.57', 'mail2': '287.84' },
{ 'name': '姜有兴', 'mail1': '2.2', 'mail2': '246.4' },
{ 'name': '潘作春', 'mail1': '3.66', 'mail2': '409.92' },
{ 'name': '姜为金', 'mail1': '3.44', 'mail2': '385.28' },
{ 'name': '姜清华', 'mail1': '3.62', 'mail2': '405.44' },
{ 'name': '姜远华', 'mail1': '2.19', 'mail2': '245.28' },
{ 'name': '姜远平', 'mail1': '1.5', 'mail2': '168' },
{ 'name': '姜远消', 'mail1': '1.5', 'mail2': '168' },
{ 'name': '姜益良', 'mail1': '3.3', 'mail2': '369.6' },
{ 'name': '符正江', 'mail1': '0.8', 'mail2': '89.6' },
{ 'name': '姜远清', 'mail1': '3.28', 'mail2': '367.36' },
{ 'name': '姜建炉', 'mail1': '3.16', 'mail2': '353.92' },
{ 'name': '邱新良', 'mail1': '2.28', 'mail2': '255.36' },
{ 'name': '邱新全', 'mail1': '2.3', 'mail2': '257.6' },
{ 'name': '邱新富', 'mail1': '3.23', 'mail2': '361.76' },
{ 'name': '邱新贵', 'mail1': '3.33', 'mail2': '372.96' },
{ 'name': '姜建章', 'mail1': '1.85', 'mail2': '207.2' },
{ 'name': '姜远彬', 'mail1': '1.43', 'mail2': '160.16' },
{ 'name': '姜建炎', 'mail1': '2.62', 'mail2': '293.44' },
{ 'name': '姜远荣', 'mail1': '0.31', 'mail2': '34.72' },
{ 'name': '姜建贤', 'mail1': '3.14', 'mail2': '351.68' },
{ 'name': '郑志忠', 'mail1': '1.76', 'mail2': '197.12' },
{ 'name': '郑学全', 'mail1': '1.57', 'mail2': '175.84' },
{ 'name': '郑学武', 'mail1': '3.37', 'mail2': '377.44' },
{ 'name': '郑学文', 'mail1': '2.77', 'mail2': '310.24' },
{ 'name': '姜炳敬', 'mail1': '3.11', 'mail2': '348.32' },
{ 'name': '周元兴', 'mail1': '3.99', 'mail2': '446.88' },
{ 'name': '王长元', 'mail1': '1.89', 'mail2': '211.68' },
{ 'name': '夏宗发', 'mail1': '3.38', 'mail2': '378.56' },
{ 'name': '夏宗明', 'mail1': '2.55', 'mail2': '285.6' },
{ 'name': '姜清炉', 'mail1': '3.54', 'mail2': '396.48' },
{ 'name': '姜清福', 'mail1': '2.95', 'mail2': '330.4' },
{ 'name': '姜建奇', 'mail1': '2.35', 'mail2': '263.2' },
{ 'name': '郑初中', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '郑初林', 'mail1': '0.52', 'mail2': '58.24' },
{ 'name': '夏锡华', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '夏锡均', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '夏公飞', 'mail1': '2.56', 'mail2': '286.72' },
{ 'name': '张丁文', 'mail1': '2.29', 'mail2': '256.48' },
{ 'name': '周发元', 'mail1': '1.35', 'mail2': '151.2' },
{ 'name': '周发泉', 'mail1': '1.43', 'mail2': '160.16' },
{ 'name': '姜冬凤', 'mail1': '2.19', 'mail2': '245.28' },
{ 'name': '周小华', 'mail1': '1.7', 'mail2': '190.4' },
{ 'name': '周发金', 'mail1': '0.15', 'mail2': '16.8' },
{ 'name': '周真发', 'mail1': '1.12', 'mail2': '125.44' },
{ 'name': '周高贤', 'mail1': '1.78', 'mail2': '199.36' },
{ 'name': '周文发', 'mail1': '1.56', 'mail2': '174.72' },
{ 'name': '周生发', 'mail1': '1.24', 'mail2': '138.88' },
{ 'name': '周新玉', 'mail1': '0.45', 'mail2': '50.4' },
{ 'name': '周德忠', 'mail1': '0.23', 'mail2': '25.76' },
{ 'name': '周德荣', 'mail1': '1.39', 'mail2': '155.68' },
{ 'name': '周奀水', 'mail1': '1.29', 'mail2': '144.48' },
{ 'name': '周端午', 'mail1': '1.5', 'mail2': '168' },
{ 'name': '周海林', 'mail1': '2.17', 'mail2': '243.04' },
{ 'name': '周有水', 'mail1': '1.18', 'mail2': '132.16' },
{ 'name': '姜良福', 'mail1': '1.56', 'mail2': '174.72' },
{ 'name': '姜永华', 'mail1': '0.805', 'mail2': '90.16' },
{ 'name': '姜永高', 'mail1': '0.94', 'mail2': '105.28' },
{ 'name': '姜永忠', 'mail1': '1.22', 'mail2': '136.64' },
{ 'name': '姜永茂', 'mail1': '0.985', 'mail2': '110.32' },
{ 'name': '周胜发', 'mail1': '0.95', 'mail2': '106.4' },
{ 'name': '周贵发', 'mail1': '0.99', 'mail2': '110.88' },
{ 'name': '周海金', 'mail1': '0.58', 'mail2': '64.96' },
{ 'name': '周祥发', 'mail1': '2.6', 'mail2': '291.2' },
{ 'name': '周银发', 'mail1': '1.09', 'mail2': '122.08' },
{ 'name': '周德泉', 'mail1': '2.75', 'mail2': '308' },
{ 'name': '周新茂', 'mail1': '2.7', 'mail2': '302.4' },
{ 'name': '周方贤', 'mail1': '1.84', 'mail2': '206.08' },
{ 'name': '周林贤', 'mail1': '2.28', 'mail2': '255.36' },
{ 'name': '周桂贤', 'mail1': '1.83', 'mail2': '204.96' },
{ 'name': '周德富', 'mail1': '2.69', 'mail2': '301.28' },
{ 'name': '周元兴', 'mail1': '0.51', 'mail2': '57.12' },
{ 'name': '周德水', 'mail1': '2.84', 'mail2': '318.08' },
{ 'name': '程金水', 'mail1': '1.83', 'mail2': '204.96' },
{ 'name': '程金泉', 'mail1': '1.84', 'mail2': '206.08' },
{ 'name': '程金禄', 'mail1': '1.42', 'mail2': '159.04' },
{ 'name': '童金福', 'mail1': '2.16', 'mail2': '241.92' },
{ 'name': '周水贤', 'mail1': '1.85', 'mail2': '207.2' },
{ 'name': '周德进', 'mail1': '1.75', 'mail2': '196' },
{ 'name': '鲁月秀', 'mail1': '1.46', 'mail2': '163.52' },
{ 'name': '周明贤', 'mail1': '2.34', 'mail2': '262.08' },
{ 'name': '周祥炎', 'mail1': '1.25', 'mail2': '140' },
{ 'name': '周奀兴', 'mail1': '1.04', 'mail2': '116.48' },
{ 'name': '周仁财', 'mail1': '0.67', 'mail2': '75.04' },
{ 'name': '周松干', 'mail1': '2.94', 'mail2': '329.28' }]