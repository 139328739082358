var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-4",class:_vm.activeTab === 1 ? 'page1Shuizhi' : ''},[_c('div',{staticClass:"block-4-inner"},[_c('div',{staticClass:"right-title"},[_vm._v("水质监测")]),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"item-1"},_vm._l((_vm.left),function(item,index){return _c('div',{key:index,staticClass:"item-cell"},[_c('div',[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"color":"rgba(0, 255, 228, 1)","fontSize":"14px"}})]),_c('div',[_c('span',{staticStyle:{"color":"rgba(0, 255, 228, 1)","fontSize":"36px","fontWeight":"bold"}},[_c('ICountUp',{ref:"countUp",refInFor:true,attrs:{"delay":_vm.activeTab === 5 ? 600 : 0,"endVal":item.value,"options":{
                  decimalPlaces:item.decimals || 0
                }}})],1),_c('span',{staticStyle:{"color":"rgba(0, 255, 228, 1)","fontSize":"16px"}},[_vm._v(_vm._s(item.unit))])])])}),0),_c('div',{staticClass:"item-2"},[_c('div',[_vm._v(_vm._s(_vm.center.name)),_c('span',{staticStyle:{"color":"rgba(0, 255, 228, 1)"}}),_vm._v(_vm._s(_vm.center.unit))]),_c('div',{staticStyle:{"color":"rgba(0, 255, 228, 1)","fontSize":"50px","fontWeight":"bold"}},[_c('ICountUp',{ref:"countUp",attrs:{"delay":_vm.activeTab === 5 ? 600 : 0,"endVal":_vm.center.value,"options":{
              decimalPlaces:_vm.center.decimals || 0
            }}})],1)]),_c('div',{staticClass:"item-3"},_vm._l((_vm.right),function(item,index){return _c('div',{key:index,staticClass:"item-cell"},[_c('div',[_c('div',[_vm._v(_vm._s(item.name)+" ")]),_c('div',{staticStyle:{"color":"rgba(0, 255, 228, 1)","fontSize":"14px"}})]),_c('div',[_c('span',{staticStyle:{"color":"rgba(0, 255, 228, 1)","fontSize":"36px","fontWeight":"bold"}},[_c('ICountUp',{ref:"countUp",refInFor:true,attrs:{"delay":_vm.activeTab === 5 ? 600 : 0,"endVal":item.value,"options":{
                  decimalPlaces:item.decimals || 0
                }}})],1),_c('span',{staticStyle:{"color":"rgba(0, 255, 228, 1)","fontSize":"16px"}},[_vm._v(_vm._s(item.unit))])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }