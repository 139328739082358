<template>
  <div class="page-content page-content-3">
    <div class="left">
      <div class="left-title">
        <span>村街道种植情况 </span>
        <!-- <span class="title-unit">单位(亩)</span> -->
      </div>
      <div class="content">
        <div class="block-1">
          <Jtitle :ops="title1" />
          <div class="chart-wrap">
            <div
              id="myChart31"
              :style="{ width: '100%', height: '100%' }"
            />
          </div>
        </div>
        <div class="block-2">
          <Jtitle :ops="title2" />
          <div class="chart-wrap">
            <div
              v-for="(item, index) in data2"
              :key="index"
              class="item"
            >
              <div class="item-title">
                <div>{{ item.name }}</div>
                <div>{{ item.num }}</div>
              </div>
              <div
                class="chart-bar"
                :id="'myChart32-' + index"
                :style="{ width: '100%', height: '100%' }"
              />
            </div>
          </div>
          <!-- <div class="block2-footer">单位(亩)</div> -->
        </div>
      </div>
    </div>
    <div class="center">
      <div class="center-block-1">
        <div
          class="item"
          v-for="(item, index) in centerItem"
          :key="index"
        >
          <Jtitle :ops="item" />
          <div
            class="center-3-title-wrap"
            style="marginbottom: 16px; paddingleft: 25px"
          >
            <span class="center-3-title-num">
              <ICountUp
                ref="countUp"
                :delay="600"
                :end-val="item.number"
                :options="{
                  decimalPlaces: item.decimals || 0
                }"
              /> </span
            >{{ item.unit }}
          </div>
        </div>
      </div>
      <div class="center-block-2">
        <div id="player3" />
        <!-- <VueVideo ref="video1" :srcUrl="baseUrl+'static/video/0930-广信区北乡片区汇报片.mp4'"/> -->
      </div>
    </div>
    <div class="right">
      <div class="block-3">
        <div class="right-title">当地特色产品</div>
        <div class="chart-wrap" v-show="!slideShow">
          <div
            class="item"
            v-for="(item, index) in item3"
            :key="index"
            :style="{
              'background-image': `url(${item.bg})`
            }"
            @click="onShowSlider(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          class="chart-wrap chart-wrap-2"
          v-show="slideShow"
        >
          <img
            src="../../../../assets/img/close.png"
            alt=""
            class="close"
            height="28"
            @click="slideShow = false"
          />
          <swiper
            v-viewer
            class="slider-wrap"
            ref="mySwiper"
            :options="swiperOptions"
          >
            <swiper-slide
              class="slider-item"
              v-for="(item, index) in item3"
              :key="index"
            >
              <div class="slider-item-inner">
                <img
                  align="left"
                  width="100"
                  hspace="7"
                  vspace="6"
                  :src="reqUrl + item.cover_image"
                  alt=""
                  style="cursor: pointer"
                />
                <div
                  class="item-left"
                  v-html="item.content"
                />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="block-4">
        <div class="right-title">推荐特色景点</div>
        <div class="chart-wrap">
          <div
            class="chart-bar"
            v-show="!detail34"
            id="myChart34"
            :style="{ flex: 1, height: 0 }"
          />
          <div class="detail" v-if="detail34">
            <img
              class="close"
              src="../../../../assets/img/close.png"
              height="28"
              @click="detail34 = null"
            />
            <div class="detail-content">
              <img
                align="right"
                width="120"
                hspace="7"
                vspace="6"
                :src="reqUrl + detail34.cover_image"
                alt=""
                style="cursor: pointer"
                v-viewer
              />
              <div v-html="detail34.content" />
            </div>
          </div>
        </div>
      </div>
      <div class="block-5">
        <div class="right-title">
          乡村网格统计（政策宣传员 卫生监督员）
        </div>
        <div class="content" v-show="!block5Show">
          <div class="table-title-wrap">
            <div
              class="table-title-item"
              :style="{ flex: item.flex || 1 }"
              v-for="(item, index) in block5Column"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="table-center-wrap">
            <swiperTable
              @onClick="getBlockNames"
              :block-data="block5Data"
              :block-column="block5Column"
              :cur="6"
              :total="11"
              ref="swiperTable"
            />
          </div>
        </div>
        <div
          v-show="block5Show"
          style="
            flex: 1;
            height: 0;
            display: flex;
            flex-direction: column;
            padding: 30px 10px 10px 16px;
          "
        >
          <img
            class="close"
            src="../../../../assets/img/close.png"
            height="28"
            @click="closeBlock5"
          />
          <div
            style="
              font-weight: bold;
              font-size: 16px;
              margin-bottom: 8px;
            "
          >
            村组村民 ( {{ block5Show.length }}户 )
          </div>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              flex: 1;
              padding-right: 10px;
              font-size: 14px;
              overflow: auto;
            "
          >
            <div
              v-for="(item, index) in block5Show"
              :key="index"
              style="
                width: 20%;
                height: 26px;
                line-height: 26px;
              "
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Jtitle from '@/components/title'
// import VueVideo from '@/components/video'
import swiperTable from '@/components/swiperTable'
const data1 = [
  { value: 8, name: '大塘后' },
  { value: 20, name: '黄墩' },
  { value: 25, name: '坞里' },
  { value: 12, name: '新塘' },
  { value: 13, name: '前山' },
  { value: 9, name: '上屋' },
  { value: 33, name: '底屋' },
  { value: 9, name: '樟家坞' }
]
import { getsightsTop6, getfoodsTop6 } from '@/request/api'
var legendData = ['好评', '中评', '差评']
let indicator = []
import DPlayer from 'dplayer'
export default {
  components: {
    Jtitle,
    // VueVideo,
    swiperTable
  },
  data() {
    return {
      block5Show: false,
      reqUrl: process.env.VUE_APP_API_BASE_URL,
      detail34: null,
      cur: 6,
      slideShow: false,
      swiperOptions: {
        initialSlide: 0,
        autoplay: false, //可选选项，自动滑动
        // autoplay:{
        // 	disableOnInteraction: false
        // },
        loop: true,
        delay: 5000,
        observeParents: true,
        observer: true
      },
      baseUrl: process.env.VUE_APP_BASE_API,
      title1: {
        title: '种植户分布图',
        color1: 'rgba(0, 255, 240, 1)',
        color2: 'rgba(0, 255, 240, .4)',
        unit: '村组/百分比',
        unitColor: 'rgba(46, 212, 234, 1)'
      },
      title2: {
        title: '种植面积分布图',
        color1: 'rgba(0, 255, 240, 1)',
        color2: 'rgba(0, 255, 240, .4)',
        unit: '单位(亩)',
        unitColor: 'rgba(46, 212, 234, 1)'
      },
      myChart31: null,
      option1: {
        color: [
          'rgba(234, 104, 162, 1)',
          'rgba(97, 255, 251, 1)',
          'rgba(43, 182, 105, 1)',
          'rgba(0, 203, 255, 1)',
          'rgba(0, 160, 233, 1)',
          'rgba(255, 244, 92, 1)',
          'rgba(236, 186, 114, 1)'
        ],
        legend: {
          top: 'middle',
          right: 10,
          orient: 'vertical',
          itemWidth: 8,
          itemHeight: 8,
          borderRadius: 50,
          textStyle: {
            color: '#fff',
            borderWidth: 0,
            rich: {
              a: {
                fontSize: 14,
                width: 90
              },
              b: {
                fontSize: 16,
                align: 'right',
                padding: [0, 10, 0, 15]
              }
            }
          },
          formatter: (name) => {
            const data = data1
            let total = 0
            let target
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value
              if (data[i].name == name) {
                target = data[i].value
              }
            }
            var arr = [
              '{a|' +
                name +
                '}{b|' +
                ((target / total) * 100).toFixed(2) +
                '%}'
            ]
            return arr.join('\n')
          }
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '55%'], // 中间白色圆周面积
            center: ['25%', '50%'], // 左右距离
            avoidLabelOverlap: false,
            tooltip: {
              trigger: 'item',
              formatter: function (params) {
                return `<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${
                  params.color
                }'></span>${params.name}：${
                  params.value
                }户<br>占比：${params.percent.toFixed(2)}%`
              }
            },
            label: {
              show: true,
              position: 'center',
              formatter: '129户',
              fontSize: 16,
              color: '#fff'
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
      option2: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          formatter: function (params) {
            console.log(params)
            return (
              "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
              params[0].seriesName +
              ' : ' +
              params[0].value +
              '亩 <br/>'
            )
          }
        },
        backgroundColor: 'transparent',
        grid: {
          top: 0,
          bottom: 0,
          left: '0px',
          right: '0px'
        },
        xAxis: {
          show: false,
          type: 'value',
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: 'category',
            data: [''],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: '亩',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              borderRadius: 30,
              color:
                new this.$echarts.graphic.LinearGradient(
                  1,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 115, 126, 1)'
                    },
                    {
                      offset: 1,

                      color: 'rgba(252, 67, 82, 0)'
                    }
                  ]
                )
            },
            barWidth: 8,
            data: []
            // data: [1800]
          },
          {
            name: '',
            type: 'bar',
            barWidth: 8,
            barGap: '-100%',
            data: [1300],
            itemStyle: {
              color: 'rgba(28, 128, 213, 0.19)',
              borderRadius: 30
            }
          }
        ]
      },
      data2: [
        { name: '大塘后', num: 1264 },
        { name: '黄墩', num: 485 },
        { name: '坞里', num: 485 },
        { name: '新塘', num: 58 },
        { name: '前山', num: 628 },
        { name: '上屋', num: 1300 },
        { name: '底屋', num: 607 },
        { name: '樟家坞', num: 134 }
      ],
      myChart34: null,
      data4: [],
      option4: {
        backgroundColor: 'transparent',
        color: [],
        tooltip: {},
        calculable: true,
        legend: {
          top: 'middle',
          orient: 'vertical',
          icon: 'circle', //图例形状
          data: [],
          // bottom:35,
          right: 20,
          // itemWidth: 8, // 图例标记的图形宽度。[ default: 25 ]
          itemHeight: 10, // 图例标记的图形高度。[ default: 14 ]
          itemGap: 16, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
          textStyle: {
            fontSize: 14,
            color: '#fff'
          }
        },
        radar: {
          triggerEvent: true,
          radius: 68, //缩放
          center: ['40%', '50%'], //位置
          axisName: {
            color: '#fff',
            fontSize: 14,
            padding: [-5, 12]
          },
          indicator: [],
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: [
                'rgba(255,255,255,0)',
                'rgba(255,255,255,0)'
              ] // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            }
          },
          axisLine: {
            //指向外圈文本的分隔线样式
            lineStyle: {
              color: '#466266'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#466266', // 分隔线颜色
              width: 1 // 分隔线线宽
            }
          }
        },
        series: [
          {
            type: 'radar',
            symbolSize: 4,
            symbol: 'emptyCircle' /*曲线圆点*/,
            tooltip: {
              show: true,
              formatter: function (params) {
                // console.log('🚀 ~ file: page3.vue ~ line 400 ~ data ~ params', params)
                let relVal = `<span style="font-weight:bold;font-size:15px">${params.name}</span>`
                const typeList = indicator
                for (
                  let i = 0;
                  i < params.data.value.length;
                  i++
                ) {
                  relVal += `<br/><span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${params.color}'></span>${typeList[i].text} ${params.data.value[i]}个`
                  // relVal += '<br/><span style="color:"></span>' + typeList[i].text + ' : '+ params.data.value[i] + ' ' + '分'
                }
                return relVal
              }
            },
            data: []
          }
        ]
      },
      block5Column: [
        { name: '片区', key: 1, flex: 2 },
        { name: '网格员', key: 2, flex: 2 },
        { name: '联系电话', key: 3, flex: 2 }
      ],
      block5Data: [
        {
          1: '底屋',
          2: '周新良',
          3: '15270388493',
          content:
            '汪国林、周干良、姜正艳、余良生、周台标、周桂标、余良飞、徐福山、周松德、余良顺、周新水、毛饶发、陈绍秋、程雪连、周干彪、周干荣、周干江、周新林、徐华贵、徐嘉棋、余文标、徐福四、姜林芳、周干全、周生福、周德波、徐政亮、姜新根、周德亮、徐正章、周德清、徐正红、周丽平、周新海、周丽宾、周生林、周新堂、周瑞春、周德标、周德军、周德清、周生金、周祥华、周清林、徐振六、徐远富、周丽刚、周新旺、周德江、周新火、周德寿、周丽红、周德山、周德武、周发兴、周祥松、周生华'
        },
        {
          1: '上屋',
          2: '周干炉',
          3: '13330044578',
          content:
            '周干文、周新忠、周干炎、周瑞成、周富贤、徐干宾、周生标、周干金、周华贤、周瑞贤、项明祥、周干平、周干阳、周干堂、周双贤、周干华、周生罗、周永贤、周吉炎、周贡水、周干忠、周新平、项明相、项明土、周朝水、姜吉荣、周干强、周干火、周干和、周干发、周干华、周干军、周干林'
        },
        {
          1: '前山',
          2: '周传福',
          3: '19970371655',
          content:
            '周松生、周生期、周生成、周仁生、陈金枝、周清先、周生彬、周发金、周生荷、张丁顺、周元祥、周松相、周新章、陈山西、周生太、徐正石、周龙生、周松金、周彰标、周松银、周新江、周清华、周生敏、吴秋英、周生强、周松全、周生如、周松标、周生龙、周仁财、周松义、周松海、周松才、周彰松、周松发、周生宝、周发水、周生红、周松茂、周生海、周仁炎、周松华、周生忠、周彰良、周清松、周松贵、周松前、周新飞、周松平、周仁水、周生军、周生波'
        },
        {
          1: '樟家',
          2: '周林贤',
          3: '13699575250',
          content:
            '周华贤、周方贤、周桂贤、周德斌、童金寿、周德富、周明贤、周德泉、周新茂、周德水、程金泉、周水贤、程宇华、周林贤、程强、程金水、程金禄、周德贞、周德进、周庆风、周德根、周徳福、周徳灶、童金福、周庆华、周元兴'
        },
        {
          1: '新塘',
          2: '夏公新',
          3: '18770389525',
          content:
            '李月花、姜绍龙、姜绍城、周生金、郑初银、郑初森、郑初林、夏公新、夏锡标、夏锡均、夏益良、夏庆红、姜金松、郑初忠、郑国柱、夏公飞、夏公良、周新陆、姜永标、夏公海、周新海、夏宗均、夏公朝、夏公云、程小春、姜永枫、夏锡华'
        },
        {
          1: '大塘后',
          2: '姜传行',
          3: '18296398869',
          content:
            '姜益邦、姜立权、郑国福、姜献林、姜桂芳、姜远标、姜立彬、姜传行、姜益宾、姜如海、姜献松、姜献华、姜金山、黄郁仙、姜立飞、姜献伟、姜益堂、姜益根、姜益友、姜益炉、周双仙、姜献仁、姜如枝、李美春、姜益树、姜益成、姜金寿、姜益忠、周桂荣、张伟、姜献清、姜献忠、姜远灶、姜善福、姜立全、姜金政、周焕宝、姜崇均、姜献良、刘土香、姜如贤、姜益良、周新荣、姜益江、姜培新、姜清华、姜献生、姜益平、姜金福、姜金仰、姜昌伟、姜献海、姜金贵、姜如万、徐仙梅、周水英、姜献兴、姜善富、姜益华、姜善明、姜益银、姜立章、方彩英、姜如炎、姜师华、周干兵、姜献江、张作银、周干炳、姜善华、夏俊俊、姜献炉、吴良泉、吴克均、姜益炬、姜伟、姜春华、姜益炎、姜如升、姜慧宏、姜立耀、姜献林、张阳、姜黎明、姜立荣、吴光耀、夏春香、姜益金、姜文杰、吴光旺、姜昌荣、姜如波、姜如忠、姜恩刚、姜益焕、姜作水、姜昌兴、姜金水、姜献七、姜献荣、姜献鹏、张标、郑学财、周华丰、姜恩华、姜献恩、夏桃花、周春仙、姜益均、姜益柱'
        },
        {
          1: '大塘后',
          2: '姜献江',
          3: '13097385252',
          content:
            '姜益邦、姜立权、郑国福、姜献林、姜桂芳、姜远标、姜立彬、姜传行、姜益宾、姜如海、姜献松、姜献华、姜金山、黄郁仙、姜立飞、姜献伟、姜益堂、姜益根、姜益友、姜益炉、周双仙、姜献仁、姜如枝、李美春、姜益树、姜益成、姜金寿、姜益忠、周桂荣、张伟、姜献清、姜献忠、姜远灶、姜善福、姜立全、姜金政、周焕宝、姜崇均、姜献良、刘土香、姜如贤、姜益良、周新荣、姜益江、姜培新、姜清华、姜献生、姜益平、姜金福、姜金仰、姜昌伟、姜献海、姜金贵、姜如万、徐仙梅、周水英、姜献兴、姜善富、姜益华、姜善明、姜益银、姜立章、方彩英、姜如炎、姜师华、周干兵、姜献江、张作银、周干炳、姜善华、夏俊俊、姜献炉、吴良泉、吴克均、姜益炬、姜伟、姜春华、姜益炎、姜如升、姜慧宏、姜立耀、姜献林、张阳、姜黎明、姜立荣、吴光耀、夏春香、姜益金、姜文杰、吴光旺、姜昌荣、姜如波、姜如忠、姜恩刚、姜益焕、姜作水、姜昌兴、姜金水、姜献七、姜献荣、姜献鹏、张标、郑学财、周华丰、姜恩华、姜献恩、夏桃花、周春仙、姜益均、姜益柱'
        },
        {
          1: '坞里',
          2: '姜益林',
          3: '18146732038',
          content:
            '姜益荣、姜清亮、姜善发、徐良金、姜善江、姜善富、姜益平、姜善荣、姜德荣、姜善锋、姜仁发、姜益生、郑初丰、郑火香、姜昌华、姜昌荣、姜益金、姜仁全、徐根达、姜仁泉、姜仁和、姜仁林、姜益建、方春英、饶火生、姜清钱、王忠金、姜善水、徐良福、姜昌林、姜益和、姜善欢、姜益良、陈生水、姜善康、姜清标、姜仁钱、姜三江、王水松、姜益兴、姜益林、姜善塘、姜善炬、姜仁朝、姜仁元、俞奀凤、周堂才、姜善波、姜益财、姜清河、徐良深、姜善海、姜善洪、姜仁旺、姜善财、姜仁梁、周传良、姜善水、姜益标、魏彩菊、周伟伟、周锡富、姜益均、姜善军、姜善林、姜清荣、王水木、姜善根、周锡荣、王月英、方彩枝、姜善旺、姜益炳、姜善如、姜益春、姜根梁、姜善余、姜仁良、姜昌富、徐良军、姜仁江、周堂良、姜益松、姜善良、刘如发、姜昌水、姜清炎、姜善万、饶绍建、刘雪梅、姜庆、姜益隼、姜益芬、王水泉、姜益禄、王奀松、周锡林、潘爱春、周锡贵'
        },
        {
          1: '坞里',
          2: '姜益金',
          3: '15970372370',
          content:
            '姜益荣、姜清亮、姜善发、徐良金、姜善江、姜善富、姜益平、姜善荣、姜德荣、姜善锋、姜仁发、姜益生、郑初丰、郑火香、姜昌华、姜昌荣、姜益金、姜仁全、徐根达、姜仁泉、姜仁和、姜仁林、姜益建、方春英、饶火生、姜清钱、王忠金、姜善水、徐良福、姜昌林、姜益和、姜善欢、姜益良、陈生水、姜善康、姜清标、姜仁钱、姜三江、王水松、姜益兴、姜益林、姜善塘、姜善炬、姜仁朝、姜仁元、俞奀凤、周堂才、姜善波、姜益财、姜清河、徐良深、姜善海、姜善洪、姜仁旺、姜善财、姜仁梁、周传良、姜善水、姜益标、魏彩菊、周伟伟、周锡富、姜益均、姜善军、姜善林、姜清荣、王水木、姜善根、周锡荣、王月英、方彩枝、姜善旺、姜益炳、姜善如、姜益春、姜根梁、姜善余、姜仁良、姜昌富、徐良军、姜仁江、周堂良、姜益松、姜善良、刘如发、姜昌水、姜清炎、姜善万、饶绍建、刘雪梅、姜庆、姜益隼、姜益芬、王水泉、姜益禄、王奀松、周锡林、潘爱春、周锡贵'
        },
        {
          1: '黄墩',
          2: '邱新良',
          3: '15180302986',
          content:
            '姜坚、符正江、姜益忠、姜远良、姜海飞、邱新全、姜远福、姜建强、姜何松、姜远消、姜益良、姜秀英、姜建炎、姜益东、姜益交、姜远财、姜益才、郑学武、姜建森、姜清华、姜光银、谢金花、姜炳敬、姜清炉、周桂菊、邱裕标、姜远彬、姜海华、姜远炳、姜光强、姜秀花、邱新良、周学林、潘作春、姜远标、夏宗明、姜庆华、连彩花、姜远强、姜远塘、姜光明、张水容、姜清章、姜远荣、邱新富、姜为金、姜如炉、徐翠荣、郑华富、郑学文、姜何旺、夏宗发、郑学全、祝荣富、姜清福、周元兴、姜远平、姜远华、姜远明、姜海江、王长元、姜益银、姜建炉、祝拥军、夏爱华、姜建贤、姜远成、姜益如、姜远清、姜建华、姜远发、邱新贵、周冬荣、乐水香、周金水、周金香、郑学红、周金松、姜建顺、姜建章、姜少锋、姜远贞、姜昌平、周丽飞、郑学良、姜凯、夏宗水、姜益金、姜益丰'
        },
        {
          1: '黄墩',
          2: '姜远发',
          3: '18657730099',
          content:
            '姜坚、符正江、姜益忠、姜远良、姜海飞、邱新全、姜远福、姜建强、姜何松、姜远消、姜益良、姜秀英、姜建炎、姜益东、姜益交、姜远财、姜益才、郑学武、姜建森、姜清华、姜光银、谢金花、姜炳敬、姜清炉、周桂菊、邱裕标、姜远彬、姜海华、姜远炳、姜光强、姜秀花、邱新良、周学林、潘作春、姜远标、夏宗明、姜庆华、连彩花、姜远强、姜远塘、姜光明、张水容、姜清章、姜远荣、邱新富、姜为金、姜如炉、徐翠荣、郑华富、郑学文、姜何旺、夏宗发、郑学全、祝荣富、姜清福、周元兴、姜远平、姜远华、姜远明、姜海江、王长元、姜益银、姜建炉、祝拥军、夏爱华、姜建贤、姜远成、姜益如、姜远清、姜建华、姜远发、邱新贵、周冬荣、乐水香、周金水、周金香、郑学红、周金松、姜建顺、姜建章、姜少锋、姜远贞、姜昌平、周丽飞、郑学良、姜凯、夏宗水、姜益金、姜益丰'
        }
      ],
      item3: [],
      myChart32: null,
      centerItem: [
        {
          title: '种植户数',
          color1: 'rgba(0, 255, 240, 1)',
          color2: 'rgba(0, 255, 240, .4)',
          number: 129,
          unit: '户'
        },
        {
          title: '户均种植面积',
          color1: 'rgba(0, 255, 240, 1)',
          color2: 'rgba(0, 255, 240, .4)',
          number: 38.46,
          unit: '亩'
        },
        {
          title: '种植面积',
          color1: 'rgba(0, 255, 240, 1)',
          color2: 'rgba(0, 255, 240, .4)',
          number: 4961,
          unit: '亩'
        }
      ],
      dp: null
    }
  },
  created() {
    setTimeout(() => {
      Promise.resolve().then(() => {
        this.initMyChart1()
        this.initMyChart2()
        this.initMyChart4()
      })
    }, 200)
    this.block5Data.forEach((item) => {
      item.onClick = this.getBlockNames
      item.content = item.content.split('、')
    })
    getfoodsTop6().then((res) => {
      const data = res.data
      data.forEach((item, index) => {
        console.log(
          '🚀 ~ file: page3.vue ~ line 501 ~ data.forEach ~ item',
          item
        )
        item.bg = require(`../../../../assets/img/page3-${
          index + 1
        }.png`)
      })
      this.item3 = res.data
    })
  },
  mounted() {
    this.dp = new DPlayer({
      container: document.getElementById('player3'),
      mutex: false,
      autoplay: true,
      lang: 'zh-cn',
      theme: '#FADFA3',
      preload: 'auto',
      volume: 0,
      loop: true,
      video: {
        url:
          process.env.VUE_APP_BASE_API +
          'static/video/0930-广信区北乡片区汇报片.mp4'
      }
    })
  },
  methods: {
    getBlockNames(item) {
      this.block5Show = item.content
    },
    closeBlock5() {
      this.$refs.swiperTable.autoplayStart()
      this.block5Show = ''
    },
    onShowSlider(index) {
      this.slideShow = true
      this.$refs.mySwiper.swiper.slideTo(index)
    },
    initMyChart1() {
      const myChart31 = document.getElementById('myChart31')
      this.option1.series[0].data = data1
      this.myChart31 = this.$echarts.init(myChart31)
      this.myChart31.setOption(this.option1)
    },
    initMyChart2() {
      this.data2.forEach((item, index) => {
        const myChart32 = document.getElementById(
          'myChart32-' + index
        )
        const chart32 = this.$echarts.init(myChart32)
        this.option2.series[0].data = [item.num]
        this.option2.series[0].name = item.name
        let color0 = 'rgba(43, 228, 255, 1)'
        let color1 = 'rgba(97, 255, 251, 0)'
        if (index === 0) {
          color0 = 'rgba(255, 115, 126, 1)'
          color1 = 'rgba(252, 67, 82, 0)'
        }
        if (index === 1) {
          color0 = 'rgba(255, 179, 53, 1)'
          color1 = 'rgba(255, 179, 53, 0)'
        }
        if (index === 2) {
          color0 = 'rgba(255, 244, 92, 1)'
          color1 = 'rgba(255, 244, 92, 0)'
        }
        this.option2.series[0].itemStyle.color =
          new this.$echarts.graphic.LinearGradient(
            1,
            0,
            0,
            1,
            [
              {
                offset: 0,
                color: color0
              },
              {
                offset: 1,
                color: color1
              }
            ]
          )
        chart32.setOption(this.option2)
      })
    },
    initMyChart4() {
      const option = this.option4
      indicator = []
      getsightsTop6().then((res) => {
        this.data4 = res.data
        const value = []
        value[0] = []
        value[1] = []
        value[2] = []

        res.data.forEach((item, i) => {
          value[0].push(i + 1)
          value[1].push(item.general)
          value[2].push(item.poor)
          indicator.push({ text: item.name })
        })
        const max = Math.max(...value.flat())
        indicator.map((item) => (item.max = max))
        option.radar.indicator = indicator
        const color = [
          'rgba(144, 123, 254, 1)',
          'rgba(0, 203, 255, 1)',
          'rgba(255, 244, 92, 1)'
        ]
        const color2 = [
          'rgba(144, 123, 254, .5)',
          'rgba(0, 203, 255, .2)',
          'rgba(255, 244, 92, .1)'
        ]
        const dataArr = []
        legendData.forEach((item, index) => {
          dataArr.push({
            value: value[index],
            name: item,
            itemStyle: {
              lineStyle: {
                color: '#4A99FF'
              },
              shadowColor: '#4A99FF',
              shadowBlur: 10
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0, //右
                y: 0, //下
                x2: 1, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0,
                    color: color[index]
                  },
                  {
                    offset: 0.5,
                    color: color2[index]
                  },
                  {
                    offset: 1,
                    color: color[index]
                  }
                ],
                globalCoord: false
              },
              opacity: 1 // 区域透明度
            }
          })
        })
        const myChart34 =
          document.getElementById('myChart34')
        option.series[0].data = dataArr
        option.color = color
        option.legend.data = legendData
        this.myChart34 = this.$echarts.init(myChart34)
        this.myChart34.setOption(option)
        this.myChart34.on('click', (params) => {
          if (params.targetType === 'axisName') {
            const tem = this.data4.find(
              (item) => item.name === params.name
            )
            let content = tem.content
            const arr = content.split('src="')
            content = arr.join(
              'style="cursor: pointer;" src="https://village.wingnew.com'
            )
            tem.content = content
            this.detail34 = tem
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './page3.less';
</style>
