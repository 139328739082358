import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import moment from 'moment'
import * as echarts from 'echarts'
import animated from 'animate.css'
import './assets/less/page.less'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import countTo from 'vue-count-to'
//引入样式
import 'swiper/swiper-bundle.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import jkDplayer from '@/components/jk/index.js'
import quanjuBj from '@/components/quanjuBj/index.js'
// import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'
// Vue.use(VueDPlayer)
Vue.use(jkDplayer)
Vue.use(quanjuBj)
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)
Vue.use(countTo)
Vue.use(animated)
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
import ICountUp from 'vue-countup-v2'
Vue.component('ICountUp', ICountUp)
Vue.use(Viewer)
Viewer.setDefaults({
	Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
Vue.config.productionTip = false
import showTips from './utils/showTip.js'
import loadingDirective from '@/components/loading'
Vue.directive('loading', loadingDirective)
Vue.directive('showTips', showTips)
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
