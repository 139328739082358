<template>
  <div class="item-block">
    <Jtitle :ops="ops" v-if="ops.title"/>
    <div :class="ops.title ? 'item-wrap' : 'item-wrap item-wrap-noTitle'">
      <div
        class="item"
        v-for="item in ops.item"
        :key="item.name"
        :style="[{cursor: item.name === '监控' || item.name === '报警柱'? 'pointer' : ''}]"
        @click="onClick(item.name)"
      >
        <img
          :src="item.img"
          alt=""
        >
        <div>
          <span :style="{fontSize:'14px'}">{{ item.name }}</span>
          <span :style="{color:item.color,fontSize:'28px',fontWeight:'500'}">
            <ICountUp 
              ref="countUp"
              :delay="600"
              :endVal="item.num"
            />
            <span v-if="item.unit" style="font-size:16px;font-weight:bold;vertical-align:middle">{{ item.unit }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Jtitle from '../title'
export default {
	components:{Jtitle},
	props:{
		ops:{
			type:Object,
			default:()=>{}
		},
	},
	computed:{

	},
	watch:{
	},
	methods:{	
		onClick(name){
			this.$emit('itemClick',name)
		}
	}
}
</script>

<style lang="less" scoped>
.item-block{
  display: flex;
  flex-direction:column;
  height: 100%;
}
.item-wrap{
  display: flex;
  flex-grow: 1;
  height: calc( 100% - 48px);
  justify-content: space-around;
  .item{
    overflow: hidden;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    img{
      height: 60%;
      width: auto;
    }
    div{
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      line-height: 1.35;
    }
  }
}
.item-wrap-noTitle{
  height: 100%;
}
</style>