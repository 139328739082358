<template>
  <div class="video-wrap">
    <div class="item-wrap">
      <img class="close"
           src="@/assets/img/close.png"
           alt=""
           @click="onClose" />
      <swiper ref="slider"
              :options="swiperOptions">
        <swiper-slide class="slider-item">
          <img src="../../../../../assets/img/wushui/1.jpg"
               alt="">
        </swiper-slide>
        <swiper-slide class="slider-item">
          <img src="../../../../../assets/img/wushui/2.jpg"
               alt="">
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev"></div>
      <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
      <div class="swiper-button-next"></div>
      <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
    </div>
  </div>
</template>
 
<script>
export default {
  name: "",
  computed: {},
  data() {
    return {
      swiperOptions: {
        initialSlide: 0,
        autoplay: false, //可选选项，自动滑动
        loop: true,
        delay: 5000,
        observeParents: true,
        observer: true,
        observeSlideChildren: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChange: this.slideChange,
        },
      },
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
 
<style lang='less' scoped>
.video-wrap {
  flex: 1;
  width: 0;
  // width: 420px;
  padding: 20px 30px;
  // height: 420px;
}
.slider-item {
  display: flex;
  img {
    height: auto;
    width: 60%;
    margin: auto;
  }
}
.swiper-button-next,
.swiper-button-prev {
  color: rgba(255, 255, 255, 0.6);
}
.swiper-button-prev {
  left: 30px;
}
.swiper-button-next {
  right: 30px;
}
.item-wrap {
  position: relative;
}
.close {
  width: 50px;
  position: absolute;
  right: 30px;
  top: -50px;
  cursor: pointer;
}
</style>