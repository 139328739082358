
/**
 * 计算百分比
 * @param   {number} num   分子
 * @param   {number} total 分母
 * @returns {number} 返回数百分比
 */
export function Percentage(num, total) { 
	if (num == 0 || total == 0){
		return 0
	}
	return total <= 0 ? '0%' : (Math.round(num / total * 100))
}