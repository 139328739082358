import vue from 'vue'
import loading from './loading.vue'

const loadingRelative = 'loading-relative'

const loadingDirective = {
  inserted (el, binding) {
    console.log("🚀 ~ file: index.js ~ line 8 ~ inserted ~  binding", binding)
    // console.log("🚀 ~ file: index.js ~ line 8 ~ inserted ~ el", el)
    const loadingCla = vue.extend(loading) // 在vue 中创建子类
    // eslint-disable-next-line new-cap
    const instance = new loadingCla().$mount()
    el.instance = instance
    const text = binding.arg
    if (typeof text !== 'undefined') {
      instance.setTitle(text)
    }
    if (binding.value) append(el)
  },
  update (el, binding) {
    const text = binding.arg
    if (typeof text !== 'undefined') {
      el.instance.setTitle(text)
    }
    if (binding.value !== binding.oldValue) { // loading 节点
      binding.value ? append(el) : remove(el)
    }
  }
}

const append = (el) => {
  console.log("🚀 ~ file: index.js ~ line 32 ~ append ~ el", el)
  const style = getComputedStyle(el)
  if (!['absolute', 'fixed', 'relative'].includes(style.position)) { // loading组件
    // 使用 position， 判断父级 position
    addClass(el, loadingRelative)
  }
  el.appendChild(el.instance.$el)
}

const remove = (el) => {
  removeClass(el, loadingRelative)
  el.removeChild(el.instance.$el)
}

const addClass = (el, className) => {
  if (!el.classList.contains(className)) {
    el.classList.add(className)
  }
}

const removeClass = (el, className) => {
  el.classList.remove(className)
}

export default loadingDirective