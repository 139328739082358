<template>
  <div class="page-content page-content-4">
    <div class="left">
      <div class="block-1">
        <div class="left-title">三务公开</div>
        <div class="block-wrap">
          <div class="item-wrap">
            <div class="item" v-for="(item,index) in item1" :key="index">
              <span>{{ item.name }}</span>
              <span style="marginLeft:5px;color:rgba(127, 118, 255, 1);fontWeight:bold;fontSize:32px">
                <ICountUp 
                  ref="countUp"
                  :delay="600"
                  :endVal="item.num"
                  :options="{
                    decimalPlaces:item.decimals || 0
                  }"
                />
              </span>
              <span style="color:rgba(127, 118, 255, 1)">{{ item.unit }}</span>
            </div>
          </div>
          <div class="tab-wrap">
            <div class="tab-item">
              <div 
                class="tab-cell"
                @click="onTabChange(item.id)"
                :class="item.id === tab1Active ? 'active' : ''"
                v-for="(item,index) in tab1"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="content">
              <div class="table-title-wrap">
                <div class="table-title-item" :style="{flex:item.flex || 1}" v-for="(item,index) in block1Column" :key="index">
                  {{ item.name }}
                </div>
              </div>
              <div class="table-center-wrap-slider">
                <swiperTable 
                  :blockData="block1Data"
                  :blockColumn="block1Column"
                  :cur="4"
									:total="block1Data.length"
                  @onClick="getarticleDetail"
                  ref="swiperTable"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block-2">
        <div class="left-title">意见反馈</div>
        <div class="block-wrap">
          <div class="wrap-item">
            <div class="item" v-for="(item,index) in item2" :key="index">
              <div>{{ item.name }}</div>
              <div style="marginTop:4px;color:rgba(33, 226, 255, 1);fontSize:30px;fontWeight:bold">
              <ICountUp 
                  ref="countUp"
                  :delay="600"
                  :endVal="item.num"
                  :options="{
                    decimalPlaces:item.decimals || 0
                  }"
                />
              </div>
            </div>
          </div>
          <div class="chart-wrap">
            <swiper class="slider-wrap" ref="mySwiper" :options="swiperOptions" v-if="data2 && data2.length">        
              <swiper-slide class="slider-item" v-for="(row,rindex) in Math.ceil(data2.length / cur2)" :key="rindex">
                <template v-for="(item,index) in data2">
                  <div
                    :key="index"
                    class="item"
                    v-if="index < row * cur2 && index >= (row-1)*cur2"
                  >
                    <div class="item-title">
                      <div :class="'item-title-no item-title-no'+index" v-if="index < 9">NO.0{{ index+1 }}</div>
                      <div :class="'item-title-no item-title-no'+index" v-else>NO.{{ index+1 }}</div>
                      <div style="white-space: nowrap">{{ item.name }}</div>
                    </div>
                    <div class="chart-bar" :id="'myChart42-'+index" :style="{height: '100%'}"></div>
                    <div class="item-num">{{ item.num }}</div>
                  </div>
                </template>
              </swiper-slide>
            </swiper> 
          </div>
        </div>
      </div>
      <div class="block-3">
        <div class="left-title">乡村教育</div>
        <div class="block-wrap">
          <div class="wrap-item">
            <div class="item" v-for="(item,index) in item3" :key="index">
              <div>{{ item.name }}</div>
              <div style="marginTop:4px;color:rgba(95, 255, 245, 1);fontSize:30px;fontWeight:bold">
                <ICountUp 
                  ref="countUp"
                  :delay="600"
                  :endVal="item.num"
                  :options="{
                    decimalPlaces:item.decimals || 0
                  }"
                />
                {{ item.unit }}</div>
            </div>
          </div>
          <div class="chart-wrap">
            <div id="myChart43" :style="{width: '100%', height: '100%'}"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="center" :class="{'center1': centerShow1}">
      <div v-if="centerShow1" class="centerShow1">
        <img class="clsoe" src="../../../../assets/img/close.png" alt="" @click="onCenter1Close">
        <div class="title">{{ center1Content.title }}</div>
        <div v-html="center1Content.content" class="content" v-viewer></div>
      </div>
    </div>
    <div class="right">
      <div class="block-4">
        <div class="right-title">精准扶贫</div>
        <div class="block-wrap">
          <div class="wrap-item">
            <div class="item" v-for="(item,index) in item4" :key="index">
              <div>{{ item.name }}</div>
              <div style="marginTop:4px;color:rgba(255, 179, 53, 1);fontSize:30px;fontWeight:bold;marginLeft:8px;">
                <ICountUp 
                  ref="countUp"
                  :delay="600"
                  :endVal="item.num"
                  :options="{
                    decimalPlaces:item.decimals || 0
                  }"
                />{{ item.unit }}</div>
            </div>
          </div>
          <div class="chart-block">
            <div class="chart-wrap">
              <div id="myChart44" :style="{width: '100%', height: '100%'}"></div>
            </div>
            <div class="chart-wrap">
              <div class="title">
                <div>村组扶贫人数统计</div>
                <div>共<span style="fontSize:26px;color:rgba(255, 179, 53, 1);fontWeight:bold">
                  {{ num1 }}
                </span>人</div>
              </div>
              <div id="myChart45" :style="{width: '100%', flex:1}"></div>
            </div>
            <div class="chart-wrap">
              <div style="margin: 4px 0;">村组脱贫人数统计</div>
              <div v-for="(item,index) in data6" :key="index" class="item">
                <div class="item-title">
                  {{ item.name }}
                </div>
                <div class="chart-bar" :id="'myChart46-'+index" :style="{height: '100%'}"></div>
                <div style="marginLeft:8px;marginRight:8px;fontWeight:bold">{{ Percentage(item.num,2058) }}%</div>
              </div>
            </div>
          </div>    
        </div>
      </div>
      <div class="block-7">
        <div class="right-title">医疗健康</div>
        <div class="block-wrap">
          <div class="wrap-item">
            <div class="item" v-for="(item,index) in item7" :key="index">
              <div>{{ item.name }}</div>
              <div style="marginLeft:4px;color:rgba(95, 255, 245, 1);fontSize:30px;fontWeight:bold">
                <ICountUp 
                  ref="countUp"
                  :delay="600"
                  :endVal="item.num"
                  :options="{
                    decimalPlaces:item.decimals || 0
                  }"
                />{{ item.unit }}</div>
            </div>
          </div>
          <div style="marginTop:4px;marginBottom:4px">老年人类型</div>
          <div class="chart-wrap">
            <div id="myChart47" :style="{width: '100%', height: '100%'}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Percentage} from '@/utils/util'
const axisLineColor = 'rgba(97, 255, 251, .3)'
import swiperTable from '@/components/swiperTable'
import {getarticleList,getarticleDetail,getproposal,getcoursecount,getwatchOnlineRate} from '@/request/api'
export default {
	components: {
		swiperTable
	},
	data(){
		return {
			centerShow1:false,
			center1Content:{},
			num1:383,
			cur:4,
			cur2:5,
			swiperOptions:{
				initialSlide:0,
				direction : 'vertical',
				autoplay:true,
				loop:false,
				observeParents: true,
				observer: true,
			},
			item1:[
				{name:'累计公开数',num:132},
				{name:'累计阅读数',num:17.4,unit:'万次'}
			],
			item2:[
				{name:'月受理',num:0},
				{name:'月办结',num:0},
				{name:'年受理 ',num:0},
				{name:'年办结',num:0}
			],
			item3:[
				{name:'课程数',num:15},
				{name:'学习次数',num:362},
				{name:'课程覆盖率',num:53.6,unit:'%'},
			],
			item4:[
				{name:'贫困户数',num:110},
				{name:'贫困人数',num:380},
			],
			item7:[
				{name:'老人总数',num:244},
				{name:'在线率',num:0,unit:'%'},
				{name:'离线率',num:0,unit:'%'},
			],
			data6:[
				{name:'2015年',num:1475,percent:'80%'},
				{name:'2016年',num:1475,percent:'80%'},
				{name:'2017年',num:1500,percent:'80%'},
				{name:'2018年',num:1662,percent:'80%'},
				{name:'2019年',num:1662,percent:'80%'},
				{name:'2020年',num:1678,percent:'80%'},
			],
			tab1Active:9,
			tab1:[
				{name:'村务公开',id:9},
				{name:'财务公开',id:10},
				{name:'党务公开',id:14},
			],
			block1Column:[
				{name:'信息名称',key:'title',flex:3},
				{name:'发布时间',key:'createtime',flex:2},
				{name:'公开人',key:'author',flex:2}],
			block1Data:[],
			data2:[],
			option2:{
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'none'
					},
					backgroundColor: 'rgba(255, 255, 255, 0.8)',
					position: function (pos, params, el, elRect, size) {
						var obj = { top: 10 }
						obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30
						return obj
					},
					formatter: function(params) {
						console.log(params)
						return "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" + params[0].seriesName + ' : ' + params[0].value + ' <br/>'
					}
				},
				backgroundColor:'transparent',
				grid: {
					top: 0,
					bottom: 0,
					left: '0px',
					right: '0px'
				},
				xAxis: {
					show: false,
					type: 'value',
					boundaryGap: [0, 0]
				},
				yAxis: [
					{
						type: 'category',
						data: [''],
						axisLine: { show: false },
						axisTick: [
							{
								show: false
							}
						]
					},
				],
				series: [
					{
						name: '件数',
						type: 'bar',
						zlevel: 1,
						itemStyle: {
							normal: {
								barBorderRadius: 30,
								color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
									{
										offset: 0,
										color: 'rgba(255, 115, 126, 1)'
									},
									{
										offset: 1,

										color: 'rgba(252, 67, 82, 0)'
									}
								])
							}
						},
						barWidth: 8,
						data:[],
					},
					{
						name: '总数',
						type: 'bar',
						barWidth: 8,
						barGap: '-100%',
						data: [],
						itemStyle: {
							normal: {
								color: 'rgba(28, 128, 213, 0.45)',
								barBorderRadius: 30
							}
						}
					}
				]
			},
			option3:{
				title:{
					text:'农业知识类型',
					align:'left',
					style:{
						color:'#fff'
					}
				},
				colors: ['#8C1D17', '#00B3B0', '#43DEFF', '#47BD91', '#1aadce','#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
				legend:{
					align:'right',
					layout:'vertical',
					itemMarginBottom:10,
					itemStyle:{
						color:'#fff',
					},
					labelFormat:'{name}: {percentage:.1f}%',
					navigation:{
						activeColor:'#fff',
						inactiveColor:'rgba(255,255,255,.8)',
						arrowSize:8,
						style:{
							color:'#fff'
						}
					}
				},
				chart: {
					type: 'pie',
					plotBackgroundColor:null,
					backgroundColor: 'transparent',
					plotBorderWidth: null,
					plotShadow: false,
					options3d: {
						enabled: true,
						alpha: 60,
						beta: 0
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'none'
					},
					backgroundColor:'rgba(255, 255, 255, 0.8)',
					textStyle:{
						color:'#ffffff'
					},
					useHTML: true,
					formatter: function() {                                        
						return `<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${this.point.color}'></span>${this.point.name}:${this.point.y}个</br>占比：${this.point.percentage.toFixed(1)}%`
					}
				},
				exporting: {
					enabled:false
				},
				credits:{
					enabled: false,
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						depth: 20,
						dataLabels: {
							enabled: false,
							format: '{point.name}'
						},
						showInLegend: true,
						// slicedOffset:20
					}
				},
				series: [{
					type: 'pie',
					center: ['50%', '50%'],
					size:'200%',
					colorByPoint: true,
					// name: '浏览器占比',
					data: []
				}]
			},
			option4:{
				title:{
					text:'低保户、特困户统计',
					align:'left',
					verticalAlign:'top',
					style:{
						color:'#fff',
						'fontSize': '16px'
					}
				},
				colors: ['#8C1D17', '#00B3B0', '#43DEFF', '#47BD91', '#1aadce',
					'#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
				legend:{
					align:'right',
					layout:'vertical',
					itemMarginBottom:12,
					itemStyle:{
						color:'#fff',
					},
					labelFormat:'{name} {percentage:.1f}%',
					navigation:{
						activeColor:'#fff',
						inactiveColor:'rgba(255,255,255,.8)',
						arrowSize:8,
						style:{
							color:'#fff'
						}
					}
				},
				chart: {
					type: 'pie',
					plotBackgroundColor:null,
					backgroundColor: 'transparent',
					plotBorderWidth: null,
					plotShadow: false,
					options3d: {
						enabled: true,
						alpha: 60,
						beta: 0
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'none'
					},
					backgroundColor:'rgba(255, 255, 255, 0.8)',
					textStyle:{
						color:'#ffffff'
					},
					useHTML: true,
					formatter: function() {                                        
						return `<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${this.point.color}'></span>${this.point.name}:${this.point.y}户</br>占比：${this.point.percentage.toFixed(1)}%`
					}
				},
				credits:{
					enabled: false,
				},
				exporting: {
					enabled:false
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						depth: 20,
						dataLabels: {
							enabled: false,
							format: '{point.name}'
						},
						showInLegend: true,
						// slicedOffset:20
					}
				},
				series: [{
					type: 'pie',
					center: ['30%', '40%'],
					size:'190%',
					colorByPoint: true,
					// name: '浏览器占比',
					data: [
						['一般贫困户', 54],
						['低保贫困户',49],
						['五保贫困户', 8],
						// ['低边户',6],
					]
				}]
			},
			option5 : {
				title: { //图表的标题，注释掉就没有名称了
					show:false
				},
				// color: ['#FFB335','#14B1FF'],
				tooltip: {
					backgroundColor: 'rgba(255,255,255,.8)',
					trigger: 'axis', //触发类型；轴触发，axis则鼠标hover到一条柱状图显示全部数据，item则鼠标hover到折线点显示相应数据，
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter:function(params){
						var dotHtml = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:rgba(97, 255, 251, 1)"></span>'
						return dotHtml + params[0].name + '：' + params[0].value + '人'
					}
				},
				legend: {
					show:false,
					data: ['人数'], //这里设置柱状图上面的方块，名称跟series里的name保持一致
					align: 'right', //上面方块靠右还是居中的设置.不设置则居中
					right: 10,
					itemHeight:14,
					itemWidth:14,
					textStyle:{
						color:'#fff'
					}
				},
				grid: {
					top:'10%',
					left: '3%', //柱状图距离左边的距离，也可以用像素px
					right: '4%', //柱状图距离右边的距离，也可以用像素px
					bottom: '2%', //网格图（柱状图、折线图、气泡图等）离底部的距离，也可以用像素比如10px
					containLabel: true //grid 区域是否包含坐标轴的刻度标签。false可能溢出，默认为false
				},
				xAxis: [{
					type: 'category',
					data: [],
					axisTick: { //刻度
						show:false
					},
					axisLabel: {
						fontSize:12,
						color:'#fff',
						interval: 0
					},
					axisLine:{
						lineStyle:{
							color:axisLineColor
						}
					},
				}],
				yAxis: [{
					type: 'value',
					axisLabel: {
						fontSize:10,
						color:'#fff'
					},
					splitLine:{
						lineStyle:{
							color:axisLineColor
						}
					}
				}],
				series: [{ //柱状图-柱子1 
					name: '人数', //需要跟legend配置项对应
					type: 'bar',
					itemStyle: {
						color:new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
							offset: 0,
							color: 'rgba(97, 255, 251, 0.1)'
						}, {
							offset: 1,
							color: 'rgba(95, 250, 246, 1)'
						}]),
						barBorderRadius: [10, 10, 0, 0] //控制柱状图的圆角显示弧度，（顺时针左上，右上，右下，左下）
					},
					barGap:'50%',
					barWidth: '14%', //barWidth设置每根柱状图的宽度
					data: [10, 52, 200, 334, 390, 330, 220, 34, 67, 12, 123, 255]
				}]
			},
		}
	},
	created(){
		setTimeout(() => {
			Promise.resolve().then(()=>{
				this.initMyChart2()
				this.initMyChart3()
				this.initMyChart4()
				this.initMyChart5()
				this.initMyChart6()
				this.initMyChart7()
			})
		},600)
		this.getarticleById(this.tab1Active)
		getwatchOnlineRate().then(res =>{
			this.item7[1].num = res.data
			this.item7[2].num = 100 - res.data
		})
	},
	methods:{
		onCenter1Close(){
			this.centerShow1 = false
			this.$refs.swiperTable.autoplayStart()
		},
		Percentage,
		getarticleDetail(item){
			this.centerShow1 = true
			this.center1Content = {}
			getarticleDetail({id:item.id,cid: this.tab1Active}).then(res =>{
				let content = res.data[0].content
				const arr = content.split('src="')
				content = arr.join('style="cursor: pointer;" src="https://village.wingnew.com')
				res.data[0].content = content
				this.center1Content =  res.data[0]
			})
		},
		getarticleById(id){
			this.block1Data = []
			getarticleList(id).then(res =>{
				const data = res.data || []
				data.forEach((item) =>{
					item.createtime = this.$moment(item.createtime*1000).format('YYYY/MM/DD')
					item.onClick = this.getarticleDetail
				})
				this.block1Data = data
			})
		},
		onTabChange(id){
			this.tab1Active = id
			this.getarticleById(id)
		},
		initMyChart2(){
			getproposal({
				starttime:this.$moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
				endtime:this.$moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
			}).then(res =>{
				this.item2[0].num = res.data.total
				this.item2[1].num = res.data.handle
			})
			getproposal({
				starttime:this.$moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
				endtime:this.$moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'),
			}).then(res =>{
				this.item2[2].num = res.data.total
				this.item2[3].num = res.data.handle
				this.option2.series[1].data = [10]
				const data = []
				for (const key in res.data.category) {
					data.push({name:key,num: res.data.category[key]})
				}
				this.data2 = data
				this.$nextTick(()=>{
					data.forEach((item ,index) =>{
						const myChart32= document.getElementById('myChart42-'+index)
						const chart32 = this.$echarts.init(myChart32)
						this.option2.series[0].data = [item.num]
						this.option2.series[0].name = item.name
						const color0  = 'rgba(43, 228, 255, 1)'
						const color1 = 'rgba(97, 255, 251, 0)'
						this.option2.series[0].itemStyle.normal.color = new this.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
							{
								offset: 0,
								color: color0
							},
							{
								offset: 1,
								color: color1
							}
						])
						chart32.setOption(this.option2)
					})
				})
			})
		},
		initMyChart3(){
			getcoursecount().then(res =>{
				this.item3[0].num = parseInt(res.data.total)
				this.item3[1].num = parseInt(res.data.click)
				this.item3[2].num = parseInt(res.data.coverage)
				const myChart3= document.getElementById('myChart43')
				const category = res.data.category || []
				const data= []
				for (const key in category) {
					data.push([key, category[key]])
				}
				this.option3.series[0].data = data
				this.chart = new Highcharts.Chart(myChart3, this.option3)
			})
			
		},
		initMyChart4(){
			const myChart3= document.getElementById('myChart44')
			const option = this.option4
			new Highcharts.Chart(myChart3, option)
		},
		initMyChart5(){
			const myChart1= document.getElementById('myChart45')
			const myChart = this.$echarts.init(myChart1)
			const option = this.option5
			option.xAxis[0].data = ['前山,上屋','大塘','底屋,樟家','坞里','黄墩']
			option.series[0].data = [28,27,21,19,16]
			myChart.setOption(option)
			
		},
		initMyChart6(){
			this.data6.forEach((item ,index) =>{
				const myChart32= document.getElementById('myChart46-'+index)
				const chart32 = this.$echarts.init(myChart32)
				this.option2.series[1].data =  [2058]
				this.option2.series[0].data = [item.num]
				this.option2.series[0].name = item.name
				const color0  = 'rgba(43, 228, 255, 1)'
				const color1 = 'rgba(97, 255, 251, 0)'
				this.option2.series[0].itemStyle.normal.color = new this.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
					{
						offset: 0,
						color: color0
					},
					{
						offset: 1,
						color: color1
					}
				])
				chart32.setOption(this.option2)
			})
			
		},
		initMyChart7(){
			const myChart1= document.getElementById('myChart47')
			const myChart = this.$echarts.init(myChart1)
			const option = this.option5
			option.xAxis[0].data = ['特困户','贫困户','低保户','社会老人','优抚对象','残疾人']
			option.series[0].data = [54,20,30,100,34,5]
			myChart.setOption(option)
		},
	}
}
</script>

<style lang='less' scoped>
@import './page4.less';
</style>