<template>
  <div class="centerShow1">
    <img class="clsoe"
         src="../../../../../assets/img/close.png"
         alt=""
         @click="onClose">
    <div class="title">
      乡村三务公开
    </div>
    <div class="center-tab-wrap">
      <div class="center-tab"
           :class="active === index ? 'active' : ''"
           v-for="(item, index) in tabs"
           :key="index"
           @click="onTab2Change(index)">
        {{ item.name }}
      </div>
    </div>
    <div class="tab-content"
         v-for="(a, i) in tabItem"
         :key="i"
         v-show="active === i">
      <template>
        <swiper class="slider-wrap"
                ref="mySwiper2"
                v-if="active === i"
                :options="swiperOptions">
          <swiper-slide class="slider-item"
                        v-for="(item, index) in tabItem[i].list"
                        :key="index">
            <div class="slider-item-inner">
              <iframe width="100%"
                      height="100%"
                      frameborder="0"
                      scrolling="auto"
                      ref="iframe"
                      :src="'https://view.officeapps.live.com/op/embed.aspx?wdHideHeaders=True&wdHideGridlines=True&src='+encodeURIComponent('https://village.wingnew.com/huatanshan'+item.url)" />
              <!-- :src="'https://view.xdocin.com/view?src=https://village.wingnew.com/huatanshan'+item.url+'&toolbar=false'" -->
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" />
        <div class="swiper-button-next" />
      </template>
    </div>
    <!-- <div class="content">
      <iframe width="100%"
              height="100%"
              src='https://view.xdocin.com/file_85o6jiqavhidp2k2kkicf7klome.htm?p=eyJfcyI6ImNlZG9zeTduNnhsZDV4N20iLCJfdCI6IjE2NTg5NzczODYifQ%3D%3D' />

    </div> -->
  </div>
</template>

<script>
// import incomeM from "./caiwu.js";
// console.log(incomeM);
export default {
  props: {
    show: Boolean,
  },
  components: {},
  computed: {
    incomeM() {
      return this.$store.state.SanwuJsonData;
    },
    tabItem() {
      return this.$store.state.sanwuFile;
    },
  },
  data() {
    return {
      loading: true,
      active: 0,
      file: "",
      tabs: [
        { id: 1, name: "党务" },
        { id: 2, name: "村务" },
        { id: 3, name: "财务" },
      ],
      swiperOptions: {
        initialSlide: 0,
        autoplay: false, //可选选项，自动滑动
        loop: true,
        delay: 5000,
        observeParents: true,
        observer: true,
        observeSlideChildren: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {
    // this.incomeM = incomeM;
  },
  methods: {
    onTab2Change(i) {
      this.active = i;
    },
    onClose() {
      this.$emit("close");
    },
    getFile() {
      console.log(2);
      var xhr = new XMLHttpRequest();
      xhr.open("GET", "/file/dang/党风廉政建设.docx");
      xhr.responseType = "arraybuffer";
      xhr.onload = function (e) {
        let content = xhr.response;
        let blob = new Blob([content], { type: "application/pdf" });

        let reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onload = function (loadEvent) {
          var arrayBuffer = xhr.response; //arrayBuffer

          mammoth
            .convertToHtml({ arrayBuffer: arrayBuffer })
            .then((displayResult) => {
              this.file = displayResult;
            })
            .done();
        };

        function displayResult(result) {
          document.getElementById("output").innerHTML = result.value;
        }
      };
      xhr.send();
    },
  },
};
</script>

<style lang="less" scoped>
.centerShow1 {
  background-image: url("../../../../../assets/img/page2-bg-center.png");
  width: 90%;
  flex-direction: column;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  height: calc(80vh - 150px);
  position: relative;
  display: flex;
  font-size: 14px;
}
.slider-item-inner {
  padding: 0 40px;
  height: calc(80vh - 280px);
  position: relative;
  // flex: 1;
}
.centerShow1 .clsoe {
  position: absolute;
  width: 40px;
  right: 0;
  top: -30px;
  cursor: pointer;
}
.centerShow1 .tab-content {
  text-align: center;
  margin: 20px 6px;
  padding: 0 6px;
  flex: 1;
  height: 0;
  overflow: hidden;
}
.centerShow1 .title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-top: 1.45%;
  margin-left: 10px;
  text-align: center;
}
.center-tab-wrap {
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: 10%;
  margin-top: 20px;
}
.center-tab {
  min-width: 80px;
  cursor: pointer;
}
.center-tab.active {
  color: #61fffb;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 80%;
    height: 6px;
    background: linear-gradient(
      -45deg,
      transparent 15px,
      rgba(6, 239, 228, 0.4) 0
    );
    width: 60%;
    line-height: 1.5;
    padding-left: 8px;
    z-index: 10;
  }
}
.slider-item {
  justify-content: start;
  align-items: start;
  width: 100% !important;
  margin-top: 0;
  min-height: 440px;
}
</style>