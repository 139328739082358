<template>
  <div
    class="wrap" 
    :style="{width:ops.width+'px',height:'auto',top:ops.top,left:ops.left}"
  >
    <div>
      <div style="margin: 0 0 3px 28px;color:#fff;">{{ name }}</div>
      <div class="content">
        {{ content }}
      </div>
    </div>
    <img src="../../assets/img/gb-icon.png" alt="" class="icon-wrap">
  </div>
</template>

<script>
import {getbroadcast} from '@/request/api'
export default {
	props:{
		ops:{
			type:Object,
			default:()=>{}
		}
	},
	data(){
		return{
			content:'',
			name:''
		}
	},
	created(){
		getbroadcast().then(res =>{
			const item = res.data[0]
			const content = []
			this.name = item.cpDevName
			content.push(`广播状态：${item.byCurStateStr}`)
			if(item.musicFullInfo){
				content.push(`正在播放：${item.musicFullInfo}`)
			}
			content.push(`设备类型：${item.byDevTypeName}, 广播音量：${item.byCurVol}`)
			this.content = content.join(', ')
		})
	}
}
</script>
<style lang="less" scoped>
.icon-wrap{
  width:55px;
  height:auto;
  position: absolute;
	top: -20px;
	left:-30px;
}
.wrap{
  position: fixed;
	font-size: 16px;
	color:#fff;
}
.content{
  background: linear-gradient( rgba(24,140,120, 1), rgba(24,140,120,0.6));
  border: 1px solid #00FFCC;  
  padding:10px 16px 10px 25px;
  // position: absolute;
  // top: 40px;
  // left:40px;
  // min-width: 300px;
  // min-height: 18px;
}
</style>